import React, { useState } from "react";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import ModalHeader from "../../../components/modalHeader";
import { ApiCrop } from "../../../store/cropConfigurationStore";
import { darkGray, lightGray } from "../../../util/colors";

const CropModal: React.FC<{
  showModal: boolean;
  closeModal: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onFinish: () => void;
  farmUid?: string;
  crop?: ApiCrop;
}> = ({ showModal, closeModal, onFinish, farmUid, crop }) => {
  const [name, setName] = useState(crop?.name ?? "");
  const validate: () => [RequestBody, string[]] = () => {
    let errors: string[] = [];
    const body = {
      name: name,
      uid: crop?.uid,
    };
    return [body, errors];
  };

  const createOrUpdate = (body: RequestBody) => {
    fetch(`/api/crop/crop?farmUid=${farmUid}`, {
      method: "POST",
      body: JSON.stringify(body),
    }).then((_) => {
      onFinish();
    });
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          userSelect: "none",
        }}
      >
        <ModalHeader title={crop ? crop.name : "New Crop"} />
        <InputLabel title="Name" />
        <TextInput value={name} setValue={setName} placeholder="New crop" />
        <Button
          style={{ marginTop: "40px" }}
          onClick={() => {
            const [body, validationErrors] = validate();
            if (validationErrors.length > 0) {
              alert(validationErrors.join("\n"));
            } else {
              createOrUpdate(body);
            }
          }}
          label={crop ? "Update" : "Create"}
        ></Button>
      </div>
    </Modal>
  );
};

type RequestBody = {
  uid?: string;
  name: string;
};

let InputLabel: React.FC<{ title: string }> = ({ title }) => {
  return (
    <span
      style={{
        fontWeight: 600,
        fontSize: "15px",
        color: darkGray,
        marginTop: "10px",
      }}
    >
      {title}
    </span>
  );
};

let TextInput: React.FC<{
  value: string;
  setValue: (v: string) => void;
  placeholder?: string;
}> = ({ value, setValue, placeholder }) => {
  return (
    <input
      value={value}
      type="text"
      onChange={(e) => setValue(e.currentTarget.value)}
      style={{
        backgroundColor: lightGray,
        textAlign: "center",
        fontSize: "19px",
        fontWeight: 600,
        height: "40px",
        border: "none",
      }}
      placeholder={placeholder}
    ></input>
  );
};

export default CropModal;
