import { ChartData } from "chart.js";
import "chartjs-adapter-date-fns";
import { sv } from "date-fns/locale";
import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { darkBlue } from "../util/colors";

type TimeSeriesPoint = {
  time: number;
  v: number;
};

type ChartProps = {
  id: string;
  timeSeries: TimeSeriesPoint[];
  valueLabel: string;
};

const TimeSeriesChart: React.FC<ChartProps> = ({ timeSeries, valueLabel }) => {
  const data = useMemo(() => {
    const transformData: (ts: TimeSeriesPoint[]) => ChartData<"line"> = (
      ts
    ) => {
      const decimatedIfLarge =
        ts.length > 500 ? ts.filter((e, i) => i % 10 === 0) : ts;
      const labels = decimatedIfLarge.map((p) => p.time);
      const data = decimatedIfLarge.map((p) => p.v);
      return {
        labels: labels,
        datasets: [
          {
            cubicInterpolationMode: "monotone",
            label: valueLabel,
            data: data,
            fill: false,
            backgroundColor: darkBlue,
            borderColor: darkBlue,
            borderWidth: 1,
            pointRadius: 1,
          },
        ],
      };
    };

    return transformData(timeSeries);
  }, [timeSeries, valueLabel]);

  return (
    <div style={{ width: "95%" }}>
      <Line
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 0,
          },

          scales: {
            xAxis: {
              adapters: {
                date: {
                  locale: sv,
                },
              },

              // The axis for this scale is determined from the first letter of the id as `'x'`
              // It is recommended to specify `position` and / or `axis` explicitly.
              type: "timeseries",
              time: {
                tooltipFormat: "dd-MM-yyyy HH:mm", // <- HERE
                displayFormats: {
                  hour: "HH:mm",
                  minute: "H:mm",
                },
              },
            },
            yAxis: {
              type: "linear",
            },
          },
        }}
      ></Line>
    </div>
  );
};

export default TimeSeriesChart;
