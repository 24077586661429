import { DayOfWeek } from "../../../util/stupidWeekday";

export type Plan = {
  planningFrequency: "ONCE" | "WEEKDAYS" | "WEEKLY" | string;
  startTime: number;
  dayOfWeek: DayOfWeek;
  bags: number;
  floats: number;
  cropConfigurationUid: string;
  uid: string;
};

export enum Frequency {
  Once = "ONCE",
  Weekly = "WEEKLY",
  Weekday = "WEEKDAYS",
}

export enum PlannerUnit {
  Bags = "BAGS",
  Crates = "CRATES",
  Floats = "FLOATS",
}
