import { endOfDay } from "date-fns";
import { useEffect, useState } from "react";
import LockableTextInput from "../../components/lockableTextinput";
import { CropConfiguration } from "../../store/cropConfigurationStore";
import deleteIcon from "../../svg/delete.svg";
import saveIcon from "../../svg/save.svg";
import { darkGray, lightGray, transplant } from "../../util/colors";
import FinishTaskButton from "./FinishTaskButtonRow";
import TaskHeader from "./taskHeader";

let TransplantTasks: React.FC<{
  cropConfigurations: Map<string, CropConfiguration>;
  farmUid?: string;
  activeDay: Date;
  setHasLoaded: () => void;
}> = ({ cropConfigurations, activeDay, farmUid, setHasLoaded }) => {
  const [benches, setBenches] = useState<Array<TransplantTaskBench>>([]);

  useEffect(() => {
    fetchTasks(activeDay, farmUid).then((r) => {
      setBenches(r);
      setHasLoaded();
    });
  }, [activeDay, farmUid, setHasLoaded]);

  let saveTaskState = () => {
    const toSave = benches.filter((b) => b.taskUid === undefined);
    if (toSave.length === 0) {
      Promise.all(benches.map((bench) => cancelBench(bench))).then((r) => {
        setBenches(
          benches.map((b) => {
            return { ...b, taskUid: undefined };
          })
        );
      });
    } else {
      Promise.all(toSave.map((bench) => saveBench(bench))).then((r) => {
        fetchTasks(activeDay, farmUid).then((r) => {
          setBenches(r);
        });
      });
    }
  };

  let saveBench = async (bench: TransplantTaskBench) => {
    const body = {
      task: {
        floats: bench.currentFloats,
        plannedFloats: bench.plannedFloats,
        cropConfigurationUid: bench.cropConfigurationUid,
      },
      plannedTaskDate: activeDay
        .toISOString()
        .slice(0, activeDay.toISOString().indexOf("T")),
    };
    return fetch(`/api/tasks/transplant?farmUid=${farmUid}`, {
      method: "post",
      body: JSON.stringify(body),
    })
      .then((r) => r.json())
      .then((r) => r.taskUid);
  };

  let cancelBench = async (bench: TransplantTaskBench) => {
    return fetch(`/api/tasks/transplant?farmUid=${farmUid}`, {
      method: "delete",
      body: JSON.stringify({ taskUid: bench.taskUid }),
    }).then(() => undefined);
  };

  return benches.length === 0 ? (
    <></>
  ) : (
    <>
      <TaskHeader title="Transplant" color={transplant} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        {benches.map((bench, i) => {
          return (
            <TransplantTaskRow
              key={bench.key}
              bench={bench}
              borderTop={i !== 0}
              cropConfiguration={cropConfigurations.get(
                bench.cropConfigurationUid
              )}
              setNewFloatsValue={(floats) => {
                let newBenches = [...benches];
                newBenches[i] = {
                  ...bench,
                  currentFloats: floats,
                };
                setBenches(newBenches);
              }}
              saveBench={async () => {
                const taskUid = bench.taskUid
                  ? await cancelBench(bench)
                  : await saveBench(bench);
                let newBenches = [...benches];
                newBenches[i] = {
                  ...bench,
                  taskUid: taskUid,
                };
                setBenches(newBenches);
              }}
            />
          );
        })}

        <FinishTaskButton
          saved={
            benches.length > 0 &&
            benches.filter((b) => b.taskUid === undefined).length === 0
          }
          saveTaskState={saveTaskState}
        />
      </div>
    </>
  );
};

const TransplantTaskRow: React.FC<{
  bench: TransplantTaskBench;
  setNewFloatsValue: (floats: number) => void;
  borderTop: boolean;
  cropConfiguration?: CropConfiguration;
  saveBench: () => void;
}> = ({
  bench,
  setNewFloatsValue,
  borderTop,
  cropConfiguration,
  saveBench,
}) => {
  const accordingToPlanColor = "#92E690";
  const notToPlanColor = "#FBF5BD";
  const differsFromPlan = bench.plannedFloats !== bench.currentFloats;
  const plannedValue = differsFromPlan ? `(${bench.plannedFloats}) ` : "";
  const locked = bench.taskUid !== undefined;
  const setValue = (floats: string) => {
    let newValue = parseInt(floats);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    setNewFloatsValue(newValue);
  };

  return (
    <div
      style={{
        borderTop: !borderTop ? "none" : `1px solid ${lightGray}`,
        display: "flex",
        flexDirection: "row",
        padding: "9px",
        backgroundColor: locked
          ? differsFromPlan
            ? notToPlanColor
            : accordingToPlanColor
          : "",
        alignItems: "center",
      }}
    >
      <span style={{ marginRight: "auto" }}>{cropConfiguration?.title}</span>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: "15px",
              color: darkGray,
              marginLeft: "5px",
              userSelect: "none",
            }}
          >
            {plannedValue + "FLOATS"}
          </span>
          <LockableTextInput
            value={
              bench.currentFloats === 0 || isNaN(bench.currentFloats)
                ? ""
                : "" + bench.currentFloats
            }
            setValue={setValue}
            locked={locked}
          />
        </div>
      </div>
      <img
        onClick={saveBench}
        src={locked ? deleteIcon : saveIcon}
        style={{
          marginLeft: "15px",
          width: "20px",
          height: "20px",
          cursor: "pointer",
          alignSelf: "center",
        }}
        alt={locked ? "undo" : "save"}
      />
    </div>
  );
};

type TransplantTask = {
  cropConfigurationUid: string;
  benches: number[];
};

type CompletedTransplantTask = {
  uid: string;
  cropConfigurationUid: string;
  floats: number;
  plannedFloats: number;
};

type CompletedTasksResponse = {
  tasks: CompletedTransplantTask[];
};

type TransplantTaskBench = {
  key: string;
  cropConfigurationUid: string;
  taskUid?: string;
  plannedFloats: number;
  completedFloats?: number;
  currentFloats: number;
};

let fetchTasks = async (activeDay: Date, farmUid?: string) => {
  if (!farmUid) {
    return [];
  }
  const [tasks, completedTasks] = await Promise.all([
    fetch(
      `/api/tasks/transplant?farmUid=${farmUid}&from=${endOfDay(
        activeDay
      ).getTime()} `
    )
      .then((r) => r.json())
      .then((r) => r.tasks as TransplantTask[]),
    fetch(
      `/api/tasks/transplant/completed?farmUid=${farmUid}&from=${endOfDay(
        activeDay
      ).getTime()} `
    )
      .then((r) => r.json())
      .then((r) => r as CompletedTasksResponse),
  ]);

  const todoBenches: TransplantTaskBench[] = tasks
    .map(({ cropConfigurationUid, benches }) => {
      // this will filter both benchs if they match
      // it should pop completedTasks bench if a match is found
      const completedStack = [...completedTasks.tasks];
      const uncompletedBenches = benches.filter((to) => {
        const completedMatchIndex = completedStack.findIndex(
          (co) =>
            co.cropConfigurationUid === cropConfigurationUid &&
            co.plannedFloats === to
        );
        if (completedMatchIndex === -1) {
          return true;
        } else {
          completedStack.splice(completedMatchIndex, 1);
          return false;
        }
      });
      return uncompletedBenches.map((floats, i) => {
        return {
          key: cropConfigurationUid + i,
          cropConfigurationUid: cropConfigurationUid,
          taskUid: undefined,
          completedFloats: undefined,
          plannedFloats: floats,
          currentFloats: floats,
        };
      });
    })
    .flat();
  const completedbenches: TransplantTaskBench[] = completedTasks.tasks.map(
    ({ uid, cropConfigurationUid, floats, plannedFloats }) => {
      return {
        key: uid,
        cropConfigurationUid: cropConfigurationUid,
        taskUid: uid,
        completedFloats: floats,
        plannedFloats: plannedFloats,
        currentFloats: floats,
      };
    }
  );

  return todoBenches
    .concat(completedbenches)
    .sort()
    .sort((a, b) => {
      const alphabetical = a.cropConfigurationUid.localeCompare(
        b.cropConfigurationUid
      );
      if (alphabetical !== 0) {
        return alphabetical;
      }
      const finished =
        a.taskUid && !b.taskUid ? 1 : b.taskUid && !a.taskUid ? -1 : 0;
      if (finished !== 0) {
        return finished;
      }
      return b.currentFloats - a.currentFloats;
    });
};

export default TransplantTasks;
