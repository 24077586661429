import CSS from "csstype";
import { useEffect, useState } from "react";
import create from "zustand";
import FromToDatePicker from "../components/fromToDatepicker";
import Switcher from "../components/switcher";
import { TimeInterval } from "../components/timeseries/types";
import TimeSeriesChart from "../components/timeSeriesChart";
import UserStore from "../store/userStore";
import co2Icon from "../svg/co2Icon.svg";
import humidityIcon from "../svg/humidityIcon.svg";
import temperatureIcon from "../svg/temperatureIcon.svg";

type ClimateDataState = {
  co2: { time: number; v: number }[];
  temperature: { time: number; v: number }[];
  humidity: { time: number; v: number }[];
};

const climateDataStore = create<{
  climateData: ClimateDataState;
  setClimateData: (data: ClimateDataState) => void;
}>((set) => ({
  climateData: { co2: [], temperature: [], humidity: [] },
  setClimateData: (data: ClimateDataState) => {
    set({ climateData: data });
  },
}));

const graphStyle: CSS.Properties = {
  height: "200px",
  display: "flex",
  flexDirection: "row",
};

const timeIntervalValues = [
  { label: "Custom", value: TimeInterval.Custom },
  { label: "24 Hours", value: TimeInterval.One_Day },
  { label: "7 Days", value: TimeInterval.One_Week },
  { label: "1 Month", value: TimeInterval.One_Month },
];

const getClimateDataForInterval = async (
  farmUid: string,
  interval: TimeInterval,
  fromDate?: Date,
  toDate?: Date
) => {
  const from = fromDate ? fromDate.getTime() : Date.now() - interval;
  const to = toDate ? `&to=${toDate.getTime()}` : "";
  return fetch(`/api/climate?from=${from}&farmUid=${farmUid}` + to).then((r) =>
    r.json()
  );
};

const IconBlock = (props: {
  data: { time: number; v: number }[];
  icon: string;
  alt: string;
  unitLabel?: string;
}) => {
  const unit = props.unitLabel !== undefined ? props.unitLabel : "";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "30px",
      }}
    >
      <img src={props.icon} alt={props.alt} />
      <span style={{ fontSize: "15px", marginTop: "5px", fontWeight: 600 }}>
        {props.data.length > 0
          ? Math.round(props.data[props.data.length - 1].v) + unit
          : ""}
      </span>
    </div>
  );
};

const Climate: React.FC<{}> = () => {
  const [timeInterval, setTimeInterval] = useState(TimeInterval.One_Week);
  const activeFarm = UserStore((state) => state.activeFarm);
  const setClimateData = climateDataStore((state) => state.setClimateData);
  const climateData = climateDataStore((state) => state.climateData);

  const [customFromDate, setCustomFromDate] = useState<Date>(new Date());
  const changeFromDate = (date: Date | [Date | null, Date | null] | null) => {
    const newDate = date as Date;
    setCustomFromDate(newDate);
  };

  const [customToDate, setCustomToDate] = useState<Date>(new Date());
  const changeToDate = (date: Date | [Date | null, Date | null] | null) => {
    const newDate = date as Date;
    setCustomToDate(newDate);
  };

  useEffect(() => {
    const getData = async () => {
      const [fromDate, toDate] =
        timeInterval === TimeInterval.Custom
          ? [customFromDate, customToDate]
          : [];
      if (activeFarm) {
        const response = await getClimateDataForInterval(
          activeFarm.uid,
          timeInterval,
          fromDate,
          toDate
        );
        if (response) {
          setClimateData(response);
        }
      }
    };
    console.log("getting");

    getData();
  }, [setClimateData, timeInterval, activeFarm, customFromDate, customToDate]);

  return (
    <div>
      <div>
        <Switcher
          wrapperStyle={{ textAlign: "end" }}
          elements={timeIntervalValues}
          selectedValue={timeInterval}
          onChange={(value) => {
            setTimeInterval(value);
          }}
        />
        {timeInterval === TimeInterval.Custom && (
          <div style={{ display: "inline-block" }}>
            <FromToDatePicker
              startDate={customFromDate}
              setStartDate={changeFromDate}
              endDate={customToDate}
              setEndDate={changeToDate}
            />
          </div>
        )}
      </div>
      <div style={graphStyle}>
        <IconBlock
          data={climateData.temperature}
          icon={temperatureIcon}
          alt="temperature"
          unitLabel="°"
        />
        <TimeSeriesChart
          id="temperature"
          valueLabel="Temperature (°C)"
          timeSeries={climateData.temperature}
        />
      </div>
      <div style={graphStyle}>
        <IconBlock
          data={climateData.humidity}
          icon={humidityIcon}
          alt="humidity"
        />
        <TimeSeriesChart
          id="humidity"
          valueLabel="Humidity (RH)"
          timeSeries={climateData.humidity}
        />
      </div>
      <div style={graphStyle}>
        <IconBlock data={climateData.co2} icon={co2Icon} alt="co2" />
        <TimeSeriesChart
          id="co2"
          valueLabel="Co2 (ppm)"
          timeSeries={climateData.co2}
        />
      </div>
    </div>
  );
};

export default Climate;
