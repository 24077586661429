import CSS from "csstype";
import React, { useState } from "react";
import arrowDown from "../svg/arrowDown.svg";
import arrowUp from "../svg/arrowUp.svg";

const Accordion: React.FC<{
  elements: {
    key: string;
    node: React.ReactNode;
    foldedOutTitleNode?: React.ReactNode;
    titleNode: React.ReactNode;
    titleNodeStyle?: CSS.Properties;
  }[];
}> = (props) => {
  const [showThese, setShowThese] = useState(new Map<string, boolean>());
  const foldOutRow = (key: string) => {
    const newValue = !showThese.get(key);
    setShowThese(new Map<string, boolean>(showThese).set(key, newValue));
  };

  return (
    <>
      {props.elements.length === 0 ? <div key="loadingKey"></div> : <></>}
      {props.elements.map((p) => {
        return (
          <div
            key={p.key}
            style={{ display: "flex", flexDirection: "column" }}
            onClick={() => foldOutRow(p.key)}
          >
            <div
              style={{
                display: "flex",
                borderTop: "1px solid #EEEEEE",
                padding: "16px",
                fontSize: "15px",
                fontWeight: 500,
                cursor: "pointer",
                userSelect: "none",
                ...p.titleNodeStyle,
              }}
            >
              {p.foldedOutTitleNode && showThese.get(p.key)
                ? p.foldedOutTitleNode
                : p.titleNode}
              <img
                src={showThese.get(p.key) ? arrowUp : arrowDown}
                style={{
                  marginLeft: "auto",
                  paddingLeft: "11px",
                }}
                alt="arrow"
              />
            </div>

            {showThese.get(p.key) && (
              <div onClick={(e) => e.stopPropagation()}>{p.node}</div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default Accordion;
