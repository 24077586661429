import { useEffect, useState } from "react";
import TextInput from "./textInput";

const CentesimalPriceInput: React.FC<{
  price?: number;
  setPrice: (newPrice?: number) => void;
}> = ({ price, setPrice }) => {
  const [priceStr, setPriceStr] = useState<string | undefined>(
    price ? "" + price / 100 : undefined
  );

  useEffect(() => {
    setPriceStr(price ? "" + price / 100 : undefined);
  }, [price]);

  const updatePrice = (newPrice: string) => {
    let toBeParsed = newPrice;
    if (newPrice.includes(".")) {
      const decimalIndex = toBeParsed.indexOf(".");
      toBeParsed = newPrice.slice(0, decimalIndex + 3);
    }
    if (newPrice.includes(",")) {
      const decimalIndex = toBeParsed.indexOf(",");
      toBeParsed = newPrice.slice(0, decimalIndex + 3).replace(",", ".");
    }
    setPriceStr(toBeParsed);
    const value = parseFloat(toBeParsed);
    if (value !== undefined && !isNaN(value)) {
      setPrice(value * 100);
    }
  };
  return (
    <TextInput
      value={priceStr ?? ""}
      setValue={(a) => updatePrice(a)}
    ></TextInput>
  );
};

export default CentesimalPriceInput;
