const TaskHeader: React.FC<{ title: string; color: string }> = ({
  title,
  color,
}) => {
  return (
    <div
      style={{
        backgroundColor: color,
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
        padding: "9px",
      }}
    >
      {title}
    </div>
  );
};

export default TaskHeader;
