import { RouteComponentProps } from "@reach/router";
import Box from "../components/box";
import Button from "../components/button";
import UserStore from "../store/userStore";
import UserManagement from "./userManagement/userManagement";

const UserDetails = (props: RouteComponentProps) => {
  const user = UserStore((state) => state.user);

  return (
    <div id="production-wrapper">
      <Box style={{ padding: "16px" }}>
        <div>{user?.fullName}</div>
        <Button
          style={{ marginTop: "16px" }}
          label="Logout"
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        ></Button>
      </Box>
      {user?.superAdmin && (
        <Box style={{ width: "50%" }}>
          <UserManagement />
        </Box>
      )}
    </div>
  );
};

export default UserDetails;
