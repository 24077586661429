import create from "zustand";
import { persist } from "zustand/middleware";

export type DefaultPrice = {
  productUid: string;
  priceCentesimal?: number;
};
export type Customer = {
  uid?: string;
  name: string;
  productDefaultPrices: DefaultPrice[];
  deletedAt?: number;
};

type CustomerState = {
  all: Customer[];
  fetch: (farm: string) => void;
};

const CustomerStore = create<CustomerState>(
  persist(
    (set, _) => ({
      fetch: async (farm: string) => {
        const customers: Customer[] = await fetch(
          `api/customer/customer/list?farmUid=${farm}`
        )
          .then((r) => r.json())
          .then((r) => r.customers);
        set({
          all: customers,
        });
      },
      all: [],
    }),
    { name: "customerStore" }
  )
);

export default CustomerStore;
