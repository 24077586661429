import { lightGray } from "../util/colors";

const IntegerInput: React.FC<{
  amount?: number;
  setAmount: (a: number) => void;
  float?: boolean;
  placeholder?: string;
}> = ({ amount, setAmount, placeholder, float }) => {
  const handleAmountChange = (v: string) => {
    const value = float ? parseFloat(v) : parseInt(v);
    setAmount(value);
  };
  return (
    <input
      value={amount === 0 ? 0 : amount || ""}
      onChange={(e) => handleAmountChange(e.currentTarget.value)}
      style={{
        backgroundColor: lightGray,
        textAlign: "center",
        fontSize: "19px",
        fontWeight: 600,
        height: "40px",
        border: "none",
      }}
      placeholder={placeholder}
    ></input>
  );
};

export default IntegerInput;
