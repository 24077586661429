import { endOfDay } from "date-fns";
import { useEffect, useState } from "react";
import Accordion from "../../components/accordion";
import { Crop, CropConfiguration } from "../../store/cropConfigurationStore";
import { propagate } from "../../util/colors";
import FinishTaskButton from "./FinishTaskButtonRow";
import TaskHeader from "./taskHeader";
import TaskRow, { valueFromTaskCompletionValue } from "./taskRow";
import { TaskCompletionValue } from "./types";

let PropagationTasks: React.FC<{
  cropConfigurations: Map<string, CropConfiguration>;
  crops: Crop[];
  farmUid?: string;
  activeDay: Date;
  setHasLoaded: () => void;
}> = ({ cropConfigurations, crops, activeDay, farmUid, setHasLoaded }) => {
  const [values, setValues] = useState<
    Map<string, PropagationTaskCompletionValue>
  >(new Map());
  const [taskUid, setTaskUid] = useState<string>("");
  const [tasks, setTasks] = useState<PropagationTaskPlan[]>([]);

  useEffect(() => {
    fetchTasks(activeDay, farmUid).then((r) => {
      const taskValues = r.tasks.reduce((a, t) => {
        return a.set(t.cropConfigurationUid, {
          todo: t.planned.length,
          done: t.completed === undefined ? undefined : t.completed.length,
          doing:
            t.completed === undefined ? t.planned.length : t.completed.length,
          seededAt:
            t.completed === undefined || t.completed.length < 1
              ? new Date(t.planned[0].seededAt)
              : new Date(t.completed[0].seededAt),
        });
      }, new Map<string, PropagationTaskCompletionValue>());
      setHasLoaded();
      setValues(taskValues);
      setTasks(r.tasks);
      setTaskUid(r.taskUid ?? "");
    });
  }, [activeDay, farmUid, setHasLoaded]);

  let saveTaskState = () => {
    if (taskUid !== "") {
      fetch(`/api/tasks/propagate?farmUid=${farmUid}`, {
        method: "delete",
        body: JSON.stringify({ taskUid: taskUid }),
      }).then(() => setTaskUid(""));
    } else {
      const body = {
        tasks: tasks.map(({ planned, cropConfigurationUid }) => {
          const completionValue = values.get(cropConfigurationUid)!!;
          const updatedvalue = completionValue.doing ?? completionValue.todo;
          const numTraysPropagated = updatedvalue;
          const traysPropagated = planned.slice(0, numTraysPropagated);
          const traysDiscarded = planned.slice(
            numTraysPropagated,
            planned.length
          );

          return {
            cropConfigurationUid: cropConfigurationUid,
            propagated: traysPropagated.map((t) => t.uid),
            discarded: traysDiscarded.map((t) => t.uid),
          };
        }),
        plannedTaskDate: activeDay
          .toISOString()
          .slice(0, activeDay.toISOString().indexOf("T")),
      };
      fetch(`/api/tasks/propagate?farmUid=${farmUid}`, {
        method: "post",
        body: JSON.stringify(body),
      })
        .then((r) => r.json())
        .then((r) => {
          setValues(
            Array.from(values).reduce(
              (a, [cropConfigurationUid, { todo, done, doing, seededAt }]) => {
                return a.set(cropConfigurationUid, {
                  todo: todo,
                  done: doing ?? done,
                  doing: doing ?? done,
                  seededAt: seededAt,
                });
              },
              new Map<string, PropagationTaskCompletionValue>()
            )
          );
          setTaskUid(r.taskUid);
        });
    }
  };

  const tasksByCrop = Array.from(values).reduce(
    (acc, [cropConfigurationUid, taskValue], i) => {
      const cropConfiguration = cropConfigurations.get(cropConfigurationUid);
      if (!cropConfiguration) {
        return acc;
      }
      if (!acc.has(cropConfiguration.cropUid)) {
        acc.set(cropConfiguration.cropUid, []);
      }
      return acc.set(cropConfiguration.cropUid, [
        ...acc.get(cropConfiguration.cropUid)!!,
        [cropConfiguration, taskValue],
      ]);
    },
    new Map<string, [CropConfiguration, PropagationTaskCompletionValue][]>()
  );

  return values.size === 0 ? (
    <></>
  ) : (
    <>
      <TaskHeader title="Move to propagation" color={propagate} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Accordion
          elements={Array.from(tasksByCrop).map(([cropUid, taskValues]) => {
            const locked = taskUid !== "";
            const sumTrays = taskValues
              .map(([_, t]) => valueFromTaskCompletionValue(t, locked) ?? 0)
              .reduce((a, t) => a + t, 0);
            const differs =
              taskValues.find(
                ([_, t]) => t.todo !== valueFromTaskCompletionValue(t, locked)
              ) !== undefined;
            const accordingToPlanColor = "#92E690";
            const notToPlanColor = "#FBF5BD";
            const titleBg = locked
              ? differs
                ? notToPlanColor
                : accordingToPlanColor
              : "";
            return {
              key: cropUid,
              node: taskValues.map(([cropConfiguration, taskValue], i) => {
                return (
                  <TaskRow
                    key={cropConfiguration.uid}
                    onChangeValue={(v?: number) => {
                      setValues(
                        new Map(
                          values.set(cropConfiguration.uid, {
                            ...taskValue,
                            doing: v,
                          })
                        )
                      );
                    }}
                    taskValue={taskValue}
                    borderTop={true}
                    cropConfiguration={cropConfigurations.get(
                      cropConfiguration.uid
                    )}
                    locked={locked}
                    validateNotAboveTodo={false}
                    subTitle={" TRAYS"}
                    titleStyle={{ fontSize: "15px" }}
                  ></TaskRow>
                );
              }),
              titleNodeStyle: { backgroundColor: titleBg },
              titleNode: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    fontSize: "20px",
                    backgroundColor: titleBg,
                  }}
                >
                  <span>{crops.find((c) => c.uid === cropUid)?.name}</span>
                  <span
                    style={{
                      marginLeft: "auto",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >{`${sumTrays} TRAYS`}</span>
                </div>
              ),
            };
          })}
        />
        <FinishTaskButton
          saved={taskUid !== ""}
          saveTaskState={saveTaskState}
        />
      </div>
    </>
  );
};

type CompletedTasksResponse = {
  uid?: string;
  tasks: CompletedPropagationTask[];
};

type PropagationTaskCompletionValue = TaskCompletionValue & { seededAt: Date };

type PropagationTaskPlan = {
  planned: PropagationTray[];
  completed?: PropagationTray[];
  cropConfigurationUid: string;
};

let fetchTasks = async (activeDay: Date, farmUid?: string) => {
  if (!farmUid) {
    return {
      tasks: [],
      taskUid: undefined,
    };
  }
  const [tasks, completedTasks] = await Promise.all([
    fetch(
      `/api/tasks/propagate?farmUid=${farmUid}&from=${endOfDay(
        activeDay
      ).getTime()}`
    )
      .then((r) => r.json())
      .then((r) => r.tasks as PropagationTask[]),
    fetch(
      `/api/tasks/propagate/completed?farmUid=${farmUid}&from=${endOfDay(
        activeDay
      ).getTime()}`
    )
      .then((r) => r.json())
      .then((r) => r as CompletedTasksResponse),
  ]);

  const todo = tasks.reduce((a, t) => {
    return a.set(t.cropConfigurationUid, t.trays);
  }, new Map<string, PropagationTray[]>());

  const completed = completedTasks?.tasks?.reduce((a, t) => {
    return a.set(t.cropConfigurationUid, [t.traysPropagated, t.traysDiscarded]);
  }, new Map<string, [PropagationTray[], PropagationTray[]]>());

  let combinedTasks;
  if (completed.size > 0) {
    combinedTasks = Array.from(completed).map(
      ([cropConfigurationUid, [propagated, discarded]]) => {
        return {
          planned: propagated.concat(discarded),
          completed: propagated,
          cropConfigurationUid: cropConfigurationUid,
        };
      }
    );
  } else {
    combinedTasks = Array.from(todo).map(([cropConfigurationUid, trays]) => {
      return {
        planned: trays,
        completed: completed?.get(cropConfigurationUid)?.[0],
        cropConfigurationUid: cropConfigurationUid,
      };
    });
  }
  return {
    tasks: combinedTasks,
    taskUid: completedTasks.uid,
  };
};

type PropagationTask = {
  trays: PropagationTray[];
  cropConfigurationUid: string;
};

type CompletedPropagationTask = {
  cropConfigurationUid: string;
  traysPropagated: PropagationTray[];
  traysDiscarded: PropagationTray[];
};

type PropagationTray = {
  uid: string;
  plugs: number;
  cropConfigurationUid: string;
  seededAt: number;
};

export default PropagationTasks;
