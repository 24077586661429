import CSS from "csstype";
import format from "date-fns/format";
import React, { useState } from "react";
import Accordion from "../../../components/accordion";
import ConfirmationModal from "../../../components/confirmationModal";
import FrequencyIcon from "../../../components/frequencyIcon";
import LinkButton from "../../../components/linkButton";
import TitledText from "../../../components/titledText";
import { Crop, CropConfiguration } from "../../../store/cropConfigurationStore";
import deleteIcon from "../../../svg/delete.svg";
import { backgroundGray, darkGray } from "../../../util/colors";
import { addDays, dayInMillis } from "../../../util/dates";
import { capitalize } from "../../../util/strings";
import { DayOfWeek, toWeekdayString } from "../../../util/stupidWeekday";
import cropConfigurationSubtitle from "../crops/util";
import { Frequency, Plan, PlannerUnit } from "./types";

const PlansList: React.FC<{
  cropConfigurations: CropConfiguration[];
  crops: Crop[];
  plans: Map<string, Plan[]>;
  unit: PlannerUnit;
  openModal: (cropConfigurationName: string) => void;
  inactivate: (planUid: string) => void;
  reloadList: () => Promise<void>;
}> = ({
  cropConfigurations,
  crops,
  plans,
  openModal,
  inactivate,
  reloadList,
  unit,
}) => {
  const filteredCropConfigurations = cropConfigurations.filter(
    (p) => p.active || plans.get(p.uid)
  );
  const cropsWithConfigs: [Crop, CropConfiguration[]][] = crops.map((crop) => [
    crop,
    filteredCropConfigurations.filter((f) => f.cropUid === crop.uid),
  ]);

  return (
    <Accordion
      elements={cropsWithConfigs
        .sort(([a], [b]) => a.name.localeCompare(b.name))
        .map(([crop, configs]) => {
          return {
            key: crop.uid,
            titleNode: (
              <span key={crop.uid}>
                {crop.name +
                  ` (${configs
                    .map((c) => plans.get(c.uid)?.length ?? 0)
                    .reduce((acc, i) => acc + i, 0)})`}
              </span>
            ),
            node: (
              <Accordion
                elements={configs.map((c) => {
                  return {
                    key: c.uid,
                    node: (
                      <PlansContainer
                        key={crop.uid}
                        unit={unit}
                        plans={plans.get(c.uid)}
                        inactivatePlan={inactivate}
                        reloadList={reloadList}
                        cropConfigurations={cropConfigurations}
                      ></PlansContainer>
                    ),
                    titleNode: (
                      <PlansTitleRow
                        title={c.title + " " + cropConfigurationSubtitle(c)}
                        key={c.uid}
                        onCreateNew={() => openModal(c.uid)}
                        plans={plans.get(c.uid)}
                      ></PlansTitleRow>
                    ),
                  };
                })}
              />
            ),
          };
        })}
    ></Accordion>
  );
};

let PlansTitleRow = (props: {
  title: string;
  plans?: Plan[];
  onCreateNew: () => void;
}) => {
  const amount = props.plans?.length ?? 0;
  return (
    <div
      style={{
        paddingLeft: "32px",
        display: "grid",
        gridTemplateColumns: "auto auto",
        flexGrow: 1,
      }}
    >
      <div style={{ textAlign: "start" }}>
        <span>{`${props.title} (${amount})`}</span>
        <LinkButton
          style={{
            marginLeft: "16px",
          }}
          label="+"
          onClick={(e) => {
            props.onCreateNew();
            e.stopPropagation();
          }}
        ></LinkButton>
      </div>
      <WeekdaysViz
        daysOfWeek={
          props.plans
            ?.filter((p) => p.planningFrequency === Frequency.Weekly)
            .map((p) => p.dayOfWeek) ?? []
        }
        style={{ justifyContent: "end" }}
      />
    </div>
  );
};

let PlansContainer: React.FC<{
  unit: PlannerUnit;
  plans?: Plan[];
  cropConfigurations: CropConfiguration[];
  inactivatePlan: (uid: string) => void;
  reloadList: () => Promise<void>;
}> = ({ unit, plans, cropConfigurations, inactivatePlan, reloadList }) => {
  let planRowStyle: CSS.Properties = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 8fr 1fr auto",
    justifyContent: "space-between",
    textAlign: "start",
    borderTop: "1px solid #EEEEEE",
    backgroundColor: backgroundGray,
    padding: "4px",
    fontSize: "15px",
    fontWeight: 500,
    paddingLeft: "48px",
    paddingRight: "16px",
  };
  let emptyState = plans === undefined || plans?.length === 0;

  return emptyState ? (
    <div style={planRowStyle}>No plans</div>
  ) : (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {plans
        ?.sort((a, b) => a.dayOfWeek - b.dayOfWeek)
        .map((p, i) => (
          <PlanRow
            key={p.uid}
            topRow={i === 0}
            plan={p}
            planRowStyle={planRowStyle}
            unit={unit}
            inactivate={inactivatePlan}
            reloadList={reloadList}
            cropConfiguration={
              cropConfigurations.find((c) => c.uid === p.cropConfigurationUid)!!
            }
          ></PlanRow>
        ))}
    </div>
  );
};

const daysToAdd = (today: number, toWeekDay: number) => {
  if (today === toWeekDay) {
    return 0;
  }

  return today < toWeekDay ? toWeekDay - today : 7 - (today - toWeekDay);
};

const calculateSeedCycleStart = (plan: Plan) => {
  //figure out these strings/dates,
  // start on the next cycle, i.e if seeding is not today, show next seeding datex
  const today = new Date();
  const weekMillis = dayInMillis * 7;
  const startDateFurtherThanAWeek =
    plan.startTime - today.getTime() > weekMillis;

  let cycleStart: Date;
  if (startDateFurtherThanAWeek || plan.planningFrequency === Frequency.Once) {
    // use start date
    cycleStart = new Date(plan.startTime);
  } else if (plan.planningFrequency === Frequency.Weekday) {
    //use today
    cycleStart = today;
  } else {
    //use next weekday
    cycleStart = addDays(today, daysToAdd(today.getDay(), plan.dayOfWeek));
  }
  return cycleStart;
};

const PlanRow: React.FC<{
  plan: Plan;
  planRowStyle: CSS.Properties;
  unit: PlannerUnit;
  inactivate: (planUid: string) => void;
  reloadList: () => Promise<void>;
  cropConfiguration: CropConfiguration;
  topRow: boolean;
}> = ({
  plan,
  planRowStyle,
  unit,
  inactivate,
  reloadList,
  cropConfiguration,
  topRow,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div key={plan.uid}>
      <div style={planRowStyle}>
        <TitledSeedCycleRow
          showTitle={topRow}
          plan={plan}
          cropConfiguration={cropConfiguration}
          unit={unit}
        />

        <div style={{ display: "flex", flexDirection: "row" }}>
          <FrequencyIcon frequency={plan.planningFrequency as Frequency} />
          <WeekdaysViz
            daysOfWeek={
              plan.planningFrequency === Frequency.Weekday
                ? [1, 2, 3, 4, 5]
                : [plan.dayOfWeek]
            }
            style={{ justifyContent: "end" }}
          />
          <img
            onClick={() => setShowModal(true)}
            src={deleteIcon}
            style={{
              paddingLeft: "11px",
              width: "13px",
              height: "10px",
              cursor: "pointer",
              alignSelf: "center",
            }}
            alt="delete"
          />
        </div>
      </div>
      {showModal && (
        <ConfirmationModal
          destructive={true}
          closeModal={(_) => setShowModal(false)}
          showModal={showModal}
          title={"Delete this plan?"}
          onConfirm={async () => {
            await inactivate(plan.uid);
          }}
          confirm={() => reloadList()}
          cancel={() => setShowModal(false)}
          postConfirmMessage="Plan has been deleted"
        ></ConfirmationModal>
      )}
    </div>
  );
};

const TitledSeedCycleRow: React.FC<{
  plan: Plan;
  unit: PlannerUnit;
  cropConfiguration: CropConfiguration;
  showTitle?: boolean;
}> = ({ plan, unit, cropConfiguration, showTitle = true }) => {
  // start on the next cycle, i.e if seeding is not today, show next seeding date
  const germinationDays = cropConfiguration.germinationTime / dayInMillis;
  const propagationDays = cropConfiguration.propagationTime / dayInMillis;
  const maturationDays = cropConfiguration.maturationTime / dayInMillis;
  const seedDate = calculateSeedCycleStart(plan);
  const propagateDate = addDays(seedDate, germinationDays);
  const transplantDate = addDays(propagateDate, propagationDays);
  const harvestDate = addDays(transplantDate, maturationDays);
  const value =
    unit === PlannerUnit.Floats
      ? plan.floats
      : unit === PlannerUnit.Bags
      ? plan.bags
      : plan.bags / 8;
  const valueLabel = capitalize(unit);
  const dateTextStyle = { marginLeft: "20px" };
  return (
    <>
      <TitledText
        text={"" + value}
        title={showTitle ? valueLabel.toUpperCase() : ""}
      />
      <TitledText
        text={format(new Date(plan.startTime), "eee d MMM")}
        title={showTitle ? "FROM" : ""}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          justifyContent: "center",
        }}
      >
        <TitledText
          style={dateTextStyle}
          text={format(seedDate, "eee d MMM")}
          title={showTitle ? "SEED" : ""}
        />
        <TitledText
          style={dateTextStyle}
          text={format(transplantDate, "eee d MMM")}
          title={showTitle ? "TRANSPLANT" : ""}
        />
        <TitledText
          style={dateTextStyle}
          text={format(harvestDate, "eee d MMM")}
          title={showTitle ? "HARVEST" : ""}
        />
      </div>
    </>
  );
};

const WeekdaysViz: React.FC<{
  daysOfWeek: DayOfWeek[];
  style?: CSS.Properties;
}> = ({ daysOfWeek, style }) => {
  const elementStyle = { marginRight: "2px" };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...style,
      }}
    >
      {Array(7)
        .fill(1)
        .map((x, y) => x + y)
        .map((weekday) => {
          const mark = daysOfWeek.find((d) => d === weekday) !== undefined;
          return (
            <div
              key={weekday}
              style={{
                ...elementStyle,
                color: mark ? "#92E790" : darkGray,
              }}
            >
              {toWeekdayString(weekday).slice(0, 2)}
            </div>
          );
        })}
    </div>
  );
};

export default PlansList;
