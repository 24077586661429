import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import Login from "./views/login";

const originalFetch = window.fetch;

window.fetch = function (url: RequestInfo, opts?: RequestInit | undefined) {
  const token = localStorage.getItem("token");
  if (token) {
    const f = originalFetch(url, {
      ...opts,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        ...opts?.headers,
      },
    });
    return f.then((e) => {
      if (e.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      if (e.status === 403) {
        //maybe we log this to bugsnag or similar in production
        console.log(e);
      }
      return e;
    });
  } else {
    return originalFetch(url, {
      ...opts,
      headers: {
        "Content-Type": "application/json",
        ...opts?.headers,
      },
    });
  }
};

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Login />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
