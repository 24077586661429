import LinkButton from "./linkButton";

const BoxHeader: React.FC<{
  title: string;
  linkTitle?: string;
  linkEnabled?: boolean;
  onClick?: () => void;
  linkColor?: string;
}> = ({ title, onClick, linkEnabled, linkTitle, linkColor }) => {
  const disableLink = linkEnabled !== undefined && !linkEnabled;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      <span>{title}</span>
      {linkTitle && onClick && (
        <LinkButton
          inactive={disableLink}
          label={linkTitle}
          style={{ color: linkColor ?? "#3D6DEB" }}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default BoxHeader;
