import React from "react";

type ButtonProps = {
  label: string;
};

const InputButton: React.FC<ButtonProps> = ({ label }) => {
  return (
    <input
      type="submit"
      value={label}
      style={{
        padding: "10px 17px 11px",
        background: "#2656FF",
        borderRadius: "5px",
        color: "white",
        fontSize: "medium",
        border: "none",
      }}
    />
  );
};

export default InputButton;
