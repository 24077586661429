import { RouteComponentProps } from "@reach/router";
import { useEffect } from "react";
import Box from "../../components/box";
import CropConfigurationStore from "../../store/cropConfigurationStore";
import CustomerStore from "../../store/customerStore";
import ProductStore from "../../store/productStore";
import UserStore from "../../store/userStore";
import Customers from "./customers";
import Orders from "./orders";
import Products from "./products";

const Sales = (props: RouteComponentProps) => {
  const activeFarm = UserStore((state) => state.activeFarm);
  const fetchCropsAndCropConfigurations = CropConfigurationStore(
    (state) => state.fetch
  );
  const fetchProducts = ProductStore((state) => state.fetch);
  const fetchCustomers = CustomerStore((state) => state.fetch);
  useEffect(() => {
    if (activeFarm) {
      fetchCropsAndCropConfigurations(activeFarm.uid);
      fetchProducts(activeFarm.uid);
      fetchCustomers(activeFarm.uid);
    }
  }, [
    activeFarm,
    fetchCropsAndCropConfigurations,
    fetchProducts,
    fetchCustomers,
  ]);

  return (
    <div id="production-wrapper" style={{ marginBottom: "100px" }}>
      <Box>
        <Orders />
      </Box>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <div style={{ width: "50%" }}>
          <Box>
            <Products></Products>
          </Box>
        </div>
        <div style={{ width: "50%" }}>
          <Box>
            <Customers />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Sales;
