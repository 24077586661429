import React, { useState } from "react";
import BoxHeader from "../../components/boxHeader";
import Button from "../../components/button";
import InputLabel from "../../components/inputLabel";
import LinkButton from "../../components/linkButton";
import Modal from "../../components/modal";
import Multiselect, { Option } from "../../components/multiselect";
import TextInput from "../../components/textInput";
import CropConfigurationStore from "../../store/cropConfigurationStore";
import ProductStore from "../../store/productStore";
import UserStore from "../../store/userStore";
import { danger, darkGray, lightGray } from "../../util/colors";

export type Product = {
  uid?: string;
  name: string;
  harvestTypes: string[];
  deletedAt?: number;
};

const Products: React.FC<{}> = () => {
  const harvestTypes = CropConfigurationStore((state) => state.harvestTypes);
  const activeFarm = UserStore((state) => state.activeFarm);
  //this should be a "create product" modal
  const [showModal, setShowModal] = useState(false);

  const [showDeleted, setShowDeleted] = useState(false);

  const products = ProductStore((state) => state.all);
  const fetchProducts = ProductStore((state) => state.fetch);

  const createNew = () => {
    setModalProduct(emptyProduct());
    setShowModal(true);
  };

  const reloadList = async () => {
    if (activeFarm) {
      fetchProducts(activeFarm?.uid);
    }
  };

  const upsertProduct = async (product: Product) => {
    if (product.name.length < 1) {
      alert("We're gonna need a name");
      return;
    }
    if (!activeFarm) {
      return;
    }
    await fetch(`api/product/product?farmUid=${activeFarm.uid}`, {
      method: "POST",
      body: JSON.stringify(product),
    });
  };

  const deleteOrRestoreProduct = async (
    productUid: string,
    restore: boolean = false
  ) => {
    if (activeFarm) {
      return fetch(
        `/api/product/product/${productUid}?farmUid=${activeFarm.uid}`,
        {
          method: restore ? "POST" : "DELETE",
        }
      ).then((r) => {
        if (r.ok) {
          return reloadList().then(() => r.ok);
        } else {
          return r.ok;
        }
      });
    } else {
      return false;
    }
  };

  const emptyProduct = () => {
    return {
      name: "",
      harvestTypes: [],
    };
  };

  const [modalProduct, setModalProduct] = useState<Product>(emptyProduct());

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <BoxHeader title="Products" linkTitle="New Product" onClick={createNew} />
      {products
        .filter((p) => showDeleted || !p.deletedAt)
        .map((product) => {
          return (
            <div
              style={{
                fontSize: "15px",
                borderTop: "1px solid #EEEEEE",
                padding: "16px",
                textAlign: "start",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                background: product.deletedAt ? lightGray : "transparent",
              }}
              key={product.uid}
            >
              <span>{product.name}</span>
              <span
                style={{ color: darkGray, cursor: "pointer" }}
                onClick={() => {
                  setModalProduct(product);
                  setShowModal(true);
                }}
              >
                Edit
              </span>
            </div>
          );
        })}

      <div
        style={{
          fontSize: "12px",
          borderTop: "1px solid #EEEEEE",
          padding: "1px",
          paddingLeft: "16px",
          textAlign: "start",
          background: lightGray,
          cursor: "pointer",
        }}
        onClick={() => setShowDeleted(!showDeleted)}
      >
        {showDeleted ? "Hide deleted" : "Show deleted"}
      </div>
      {showModal && (
        <CreateProductModal
          showModal={showModal}
          closeModal={(e) => {
            setShowModal(false);
            if (e) {
              e.stopPropagation();
            }
          }}
          onFinish={async (newProduct) => {
            await upsertProduct(newProduct);
            reloadList();
            setShowModal(false);
            setModalProduct(emptyProduct());
          }}
          product={modalProduct}
          deleteOrRestore={deleteOrRestoreProduct}
          harvestTypes={harvestTypes.map((c) => {
            return {
              value: c.uid,
              label: c.name,
            };
          })}
        />
      )}
    </div>
  );
};

const CreateProductModal: React.FC<{
  showModal: boolean;
  closeModal: (e: React.MouseEvent | React.KeyboardEvent | undefined) => void;
  onFinish: (product: Product) => void;
  product: Product;
  deleteOrRestore: (productUid: string, restore: boolean) => Promise<boolean>;
  harvestTypes: Option[];
}> = ({
  showModal,
  closeModal,
  onFinish,
  product,
  deleteOrRestore,
  harvestTypes,
}) => {
  const [value, setValue] = useState<Product>(product);
  const isNew = product.uid === undefined;
  const isDeleted =
    product.deletedAt !== undefined && product.deletedAt !== null;
  return (
    <Modal
      showModal={showModal}
      closeModal={closeModal}
      style={{ overflowY: "visible" }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{isNew ? "New Product" : `Edit ${product.name}`}</span>
        <InputLabel title="Name" />
        <TextInput
          value={value.name}
          setValue={(s) => setValue({ ...value, name: s })}
        />
        <div style={{ marginTop: "16px" }}>
          <InputLabel title="Harvest types" />
          <Multiselect
            disabled={isDeleted}
            selected={value.harvestTypes
              .map(
                (uid) => harvestTypes.find((conf) => conf.value === uid) ?? []
              )
              .flat()}
            setSelected={(c) =>
              setValue({ ...value, harvestTypes: c.map((o) => o.value) })
            }
            options={harvestTypes}
            placeholder="Choose what to include"
          />
        </div>

        <div style={{ marginTop: "16px" }}>
          {!isNew && (
            <LinkButton
              style={{
                color: product.deletedAt ? "green" : danger,
                marginRight: "32px",
              }}
              label={product.deletedAt ? "Restore" : "Delete"}
              onClick={async () => {
                const done = await deleteOrRestore(product.uid!!, isDeleted);
                if (done) {
                  closeModal(undefined);
                }
              }}
            ></LinkButton>
          )}
          <Button
            label={isNew ? "Create" : "Update"}
            onClick={(e) => onFinish(value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Products;
