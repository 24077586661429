import { lightGray } from "../../util/colors";

const FinishTaskButton: React.FC<{
  saved: boolean;
  saveTaskState: () => void;
}> = ({ saved, saveTaskState }) => {
  return (
    <div
      style={{
        borderTop: `1px solid ${lightGray}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        cursor: "pointer",
        padding: "9px",
        backgroundColor: saved ? "#92E690" : "",
        borderEndEndRadius: "20px",
        borderEndStartRadius: "20px",
      }}
      onClick={() => saveTaskState()}
    >
      Save
    </div>
  );
};

export default FinishTaskButton;
