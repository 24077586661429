import React from "react";
import CSS from "csstype";

type Props = {
  elements: { value: any; label: string }[];
  onChange: (value: any) => void;
  selectedValue: any;
  wrapperStyle?: CSS.Properties;
  contentStyle?: CSS.Properties;
};

const Switcher: React.FC<Props> = ({
  selectedValue,
  elements,
  onChange,
  wrapperStyle,
  contentStyle,
}) => {
  return (
    <div style={wrapperStyle}>
      {elements.map((e, i) => {
        return (
          <span
            style={{
              color: selectedValue === e.value ? "black" : "#A7A7A7",
              marginLeft: i === 0 ? "0px" : "15px",
              fontSize: "15px",
              cursor: "pointer",
              userSelect: "none",
              ...contentStyle,
            }}
            onClick={() => {
              selectedValue = e.value;
              onChange(e.value);
            }}
            key={e.value}
          >
            {e.label}
          </span>
        );
      })}
    </div>
  );
};

export default Switcher;
