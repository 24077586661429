import { Chart, ChartData } from "chart.js";
import "chartjs-adapter-date-fns";
import zoomPlugin from "chartjs-plugin-zoom";
import { sv } from "date-fns/locale";
import React, { useRef } from "react";
import { Line } from "react-chartjs-2";

type TimeSeriesDataPoint = {
  v: number;
  date: number;
  label?: string;
};

var dynamicColor = () => {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  return "rgb(" + r + "," + g + "," + b + ")";
};

const MultipleTimeSeriesChart: React.FC<{
  id: string;
  timeSeries: Map<string, TimeSeriesDataPoint[]>;
  legendTooltips?: Map<string, string>;
  mergedDayTooltip?: boolean;
  valueLabel: string;
}> = ({ timeSeries, valueLabel, mergedDayTooltip = false, legendTooltips }) => {
  Chart.register(zoomPlugin);
  const getData: (
    ts: Map<string, TimeSeriesDataPoint[]>
  ) => [ChartData<"line">, (string | undefined)[]] = (ts) => {
    const timeseriesArray = Array.from(ts);
    const allLabels = timeseriesArray
      .map(([key, value]) => value)
      .flat()
      .map((p) => p.date);
    const allColors = timeseriesArray.map((d) => dynamicColor());
    const tooltips = timeseriesArray.map(([key, value]) => {
      if (legendTooltips?.has(key)) {
        return legendTooltips.get(key);
      } else {
        return undefined;
      }
    });
    return [
      {
        labels: allLabels,
        datasets: timeseriesArray.map(([key, value], index) => {
          return {
            cubicInterpolationMode: "monotone" as "monotone",
            label: key + valueLabel,
            data: value.map((v) => {
              return { y: v.v, x: v.date, label: v.label };
            }),
            fill: false,
            backgroundColor: allColors[index],
            borderColor: allColors[index],
            borderWidth: 1,
            pointRadius: 1,
          };
        }),
      },
      tooltips,
    ];
  };
  /*const tooltips = [
    "Forecast based on actual seeding and average weight per float over last 3 weeks",
    "Total order quantity",
  ];*/

  const [data, legendTooltipData] = getData(timeSeries);

  const tooltipRef = useRef<HTMLDivElement>(null);
  return (
    <div style={{ width: "95%", position: "relative" }}>
      <Line
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 0,
          },
          plugins: {
            tooltip: {
              mode: mergedDayTooltip ? "index" : "nearest",
              intersect: !mergedDayTooltip,
              callbacks: {
                label: function (context) {
                  const label: string =
                    (context as any).raw.label ?? context.dataset.label;
                  return context.parsed.y.toFixed(2) + " " + label;
                },
              },
            },
            legend: {
              onHover: (evt, legendItem) => {
                const tooltipDiv = tooltipRef.current;
                if (!tooltipDiv) {
                  return;
                }
                const text = legendTooltipData[legendItem.datasetIndex];
                if (!text) {
                  return;
                }

                tooltipDiv.innerHTML = text;
                tooltipDiv.style.left = evt.x + "px";
                tooltipDiv.style.top = evt.y + "px";
                tooltipDiv.style.visibility = "visible";
              },
              onLeave: (evt, legendItem) => {
                const tooltipDiv = tooltipRef.current;
                if (!tooltipDiv) {
                  return;
                }
                tooltipDiv.innerHTML = "";
                tooltipDiv.style.visibility = "hidden";
              },
            },
          },

          scales: {
            xAxis: {
              ticks: {
                source: "labels",
              },
              adapters: {
                date: {
                  locale: sv,
                },
              },

              type: "timeseries",
              time: {
                minUnit: "day",
                round: "day",
                tooltipFormat: "dd-MM-yyyy",
                displayFormats: {
                  hour: "HH:mm",
                  minute: "H:mm",
                },
              },
            },
            yAxis: {
              type: "linear",
            },
          },
        }}
      ></Line>
      <div
        ref={tooltipRef}
        style={{
          backgroundColor: "#333333",
          color: "#fff",
          position: "absolute",
          fontSize: "13px",
          borderRadius: "5px",
          padding: "4px 8px 4px 8px",
          visibility: "hidden",
        }}
      ></div>
    </div>
  );
};

export default MultipleTimeSeriesChart;
