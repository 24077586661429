import { RouteComponentProps } from "@reach/router";
import { useEffect } from "react";
import Box from "../../components/box";
import CropConfigurationStore from "../../store/cropConfigurationStore";
import ProductStore from "../../store/productStore";
import UserStore from "../../store/userStore";
import HarvestForecastGraph from "./harvestForecast";
import YieldGraph from "./yieldGraph";

const Economy = (props: RouteComponentProps) => {
  const fetchCropConfigurations = CropConfigurationStore(
    (state) => state.fetch
  );
  const allCropConfigurations = CropConfigurationStore((state) => state.all);
  const allCrops = CropConfigurationStore((state) => state.crops);
  const allProducts = ProductStore((state) => state.all);
  const activeFarm = UserStore((state) => state.activeFarm);

  useEffect(() => {
    if (activeFarm) {
      fetchCropConfigurations(activeFarm.uid);
    }
  }, [activeFarm, fetchCropConfigurations]);

  return (
    <div id="operation-wrapper" style={{ marginBottom: "200px" }}>
      <Box style={{ padding: "16px" }}>
        <YieldGraph
          cropConfigurations={allCropConfigurations}
          crops={allCrops}
          farmUid={activeFarm?.uid}
        />
      </Box>
      <Box style={{ padding: "16px" }}>
        <HarvestForecastGraph
          products={allProducts}
          farmUid={activeFarm?.uid}
        />
      </Box>
    </div>
  );
};

export default Economy;
