import CSS from "csstype";
import { darkGray } from "../util/colors";
import LockableTextInput from "./lockableTextinput";

const LockableTitledInput: React.FC<{
  title: string;
  setValue: (value: string) => void;
  value?: number | string;
  locked: boolean;
  style?: CSS.Properties;
  inputStyle?: CSS.Properties;
  titleStyle?: CSS.Properties;
  editingEnabledByDefault?: boolean;
  row?: boolean;
}> = ({
  title,
  setValue,
  value,
  locked,
  style,
  editingEnabledByDefault,
  inputStyle,
  titleStyle,
  row,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: row ? "row" : "column",
        alignItems: "center",
        ...style,
      }}
    >
      <span
        style={{
          fontWeight: 600,
          fontSize: "15px",
          color: darkGray,
          ...titleStyle,
        }}
      >
        {title}
      </span>
      <LockableTextInput
        value={value ? "" + value : undefined}
        setValue={setValue}
        locked={locked}
        editingEnabledByDefault={editingEnabledByDefault}
        inputStyle={inputStyle}
      />
    </div>
  );
};

export default LockableTitledInput;
