import format from "date-fns/format";
import { useCallback, useEffect, useState } from "react";
import Switcher from "../../components/switcher";
import { Customer } from "../../store/customerStore";
import { Product } from "../../store/productStore";
import { backgroundGray, mediumGray, pack } from "../../util/colors";
import { Order, OrderUnit } from "../sales/orders";

const bagsToUnit = (bags: number, o: OrderUnit) => {
  if (o === OrderUnit.Bags) {
    return bags;
  } else if (o === OrderUnit.Crates) {
    return Math.floor(bags / 8);
  }
};

const PackingTasks: React.FC<{
  products: Product[];
  customers: Customer[];
  farmUid?: string;
  activeDay: Date;
  setHasLoaded: () => void;
}> = ({ products, customers, farmUid, activeDay, setHasLoaded }) => {
  const fetchOrders = async () => {
    if (!farmUid) {
      return [];
    }
    const orders: Order[] = await fetch(
      `api/order/order/list?farmUid=${farmUid}&from=${format(
        activeDay,
        "yyyy-MM-dd"
      )}&to=${format(activeDay, "yyyy-MM-dd")}`
    )
      .then((r) => r.json())
      .then((r) =>
        r.orders.map((o: Order) => {
          return { ...o, date: new Date(o.date) };
        })
      );
    return orders;
  };

  const memoizedFetch = useCallback(fetchOrders, [farmUid, activeDay]);

  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    memoizedFetch().then((orders) => {
      setOrders(orders);
      setHasLoaded();
    });
  }, [activeDay, farmUid, setHasLoaded, memoizedFetch]);

  const [orderUnit, setOrderUnit] = useState(OrderUnit.Crates);
  const bagsOrCratesOptions = [
    { value: OrderUnit.Crates, label: "Crates" },
    { value: OrderUnit.Bags, label: "Bags" },
  ];

  return orders.length === 0 ? (
    <></>
  ) : (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: pack,
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
          padding: "9px",
        }}
      >
        <div>Packing</div>
        <Switcher
          elements={bagsOrCratesOptions}
          selectedValue={orderUnit}
          onChange={(v) => setOrderUnit(v)}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {orders.map((order) => {
          return (
            <OrderRow
              key={order.uid}
              unit={orderUnit}
              order={order}
              customers={customers}
              products={products}
            />
          );
        })}
      </div>
    </>
  );
};

const OrderRow: React.FC<{
  order: Order;
  customers: Customer[];
  products: Product[];
  unit: OrderUnit;
}> = ({ order, customers, products, unit }) => {
  const customerName = (uid?: string) => {
    return customers.find((c) => c.uid === uid)?.name ?? "?";
  };
  const productName = (uid?: string) => {
    return products.find((c) => c.uid === uid)?.name ?? "?";
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "2fr 4fr auto auto",
        padding: "5px",
        paddingLeft: "16px",
        borderTop: "1px solid #EEEEEE",
        textAlign: "start",
        alignItems: "center",
      }}
    >
      <span>{customerName(order.customer)}</span>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {order.lines.map((l) => {
          return (
            <div
              key={order.uid + l.product + l.bags}
              style={{
                display: "grid",
                gridTemplateRows: "1fr 1fr",
                placeItems: "center",
                backgroundColor: backgroundGray,
                fontSize: "20px",
                marginRight: "5px",
                borderRadius: "3px",
                lineHeight: "23px",
                padding: "2px",
              }}
            >
              <span style={{ color: mediumGray }}>
                {productName(l.product)}
              </span>
              <span>{bagsToUnit(l.bags, unit)}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PackingTasks;
