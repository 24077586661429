import CSS from "csstype";
import { darkGray, lightGray, white } from "../util/colors";

const TitledInput: React.FC<{
  title: string;
  setValue: (value: string) => void;
  value: string;
  locked?: boolean;
  style?: CSS.Properties;
  placeholder?: string;
}> = ({ title, setValue, value, locked = false, style, placeholder }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...style,
      }}
    >
      <span
        style={{
          fontWeight: 600,
          fontSize: "15px",
          color: darkGray,
        }}
      >
        {title}
      </span>
      <input
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
        style={{
          backgroundColor: locked ? white : lightGray,
          textAlign: "center",
          fontSize: "19px",
          fontWeight: 600,
          height: "40px",
          border: "none",
        }}
        placeholder={placeholder}
        disabled={locked}
      />
    </div>
  );
};
export default TitledInput;
