import CSS from "csstype";
import React from "react";
import Modal from "react-modal";

type ModalProps = {
  style?: CSS.Properties;
  closeModal: (e: React.MouseEvent | React.KeyboardEvent) => void;
  showModal: boolean;
};

const FarmOSModal: React.FC<ModalProps> = ({
  children,
  style,
  closeModal,
  showModal,
}) => {
  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        content: {
          width: "30%",
          height: "fit-content",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "15px",
          padding: "36px",
          border: "none",
          textAlign: "center",
          overflow: "visible",
          maxHeight: "70%",
          overflowY: "auto",
          ...style,
        },
        overlay: { backgroundColor: "rgba(0,0,0,0.1)" },
      }}
    >
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </Modal>
  );
};

export default FarmOSModal;
