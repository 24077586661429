import CSS from "csstype";
import { useEffect, useState } from "react";
import LockableTextInput from "../../components/lockableTextinput";
import { CropConfiguration } from "../../store/cropConfigurationStore";
import { darkGray, lightGray } from "../../util/colors";
import { TaskCompletionValue } from "./types";

export const valueFromTaskCompletionValue = (
  taskValue: TaskCompletionValue,
  locked: boolean
) => {
  let newValue = locked && taskValue.done ? taskValue.done : taskValue.doing;
  return newValue;
};

const TaskRow: React.FC<{
  taskValue: TaskCompletionValue;
  onChangeValue: (v?: number) => void;
  borderTop: boolean;
  cropConfiguration?: CropConfiguration;
  locked: boolean;
  validateNotAboveTodo: boolean;
  subTitle?: string;
  titleStyle?: CSS.Properties;
}> = ({
  taskValue,
  borderTop,
  cropConfiguration,
  locked,
  onChangeValue,
  validateNotAboveTodo,
  subTitle,
  titleStyle,
}) => {
  const [value, setNewValue] = useState(
    valueFromTaskCompletionValue(taskValue, locked)
  );
  const setValue = (v: string) => {
    let newValue: number | undefined = parseInt(v);
    if (validateNotAboveTodo && newValue > taskValue.todo) {
      return;
    }
    if (isNaN(newValue)) {
      newValue = undefined;
    }
    onChangeValue(newValue);
  };

  useEffect(() => {
    setNewValue(valueFromTaskCompletionValue(taskValue, locked));
  }, [taskValue, locked]);

  const accordingToPlanColor = "#92E690";
  const notToPlanColor = "#FBF5BD";
  const differsFromPlan = taskValue.todo !== value;
  const plannedValue = differsFromPlan ? `(${taskValue.todo})` : "";
  return (
    <div
      key={cropConfiguration?.uid}
      style={{
        borderTop: !borderTop ? "none" : `1px solid ${lightGray}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "9px",
        backgroundColor: locked
          ? differsFromPlan
            ? notToPlanColor
            : accordingToPlanColor
          : "",
      }}
    >
      <span
        style={{
          alignSelf: "center",
          ...titleStyle,
        }}
      >
        {cropConfiguration?.title}
      </span>
      <div>
        <LockableTitledInput
          value={value}
          setValue={setValue}
          locked={locked}
          title={plannedValue + subTitle || ""}
          row={true}
          titleStyle={{ color: "black" }}
        />
      </div>
    </div>
  );
};

const LockableTitledInput: React.FC<{
  title: string;
  setValue: (value: string) => void;
  value?: number;
  locked: boolean;
  style?: CSS.Properties;
  row?: boolean;
  titleStyle?: CSS.Properties;
}> = ({ title, setValue, value, locked, style, row, titleStyle }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: row ? "row" : "column",
        alignItems: "center",
        ...style,
      }}
    >
      <LockableTextInput
        value={value !== undefined ? "" + value : undefined}
        setValue={setValue}
        locked={locked}
      />
      <span
        style={{
          fontWeight: 600,
          fontSize: "15px",
          color: darkGray,
          marginLeft: "5px",
          ...titleStyle,
        }}
      >
        {title}
      </span>
    </div>
  );
};

export default TaskRow;
