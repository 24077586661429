import React, { useState } from "react";
import Accordion from "../../../components/accordion";
import BoxHeader from "../../../components/boxHeader";
import CropConfigurationStore, {
  ApiCrop,
  Crop,
  HarvestType,
} from "../../../store/cropConfigurationStore";
import UserStore from "../../../store/userStore";
import { backgroundGray, darkBlue, lightGray } from "../../../util/colors";
import HarvestTypeModal from "./harvestTypeModal";

const HarvestTypes: React.FC<{}> = () => {
  const crops = CropConfigurationStore((state) => state.crops);
  const fetchCrops = CropConfigurationStore((state) => state.fetch);
  const harvestTypes = CropConfigurationStore((state) => state.harvestTypes);
  const activeFarm = UserStore((state) => state.activeFarm);

  const cropGroups: [ApiCrop, HarvestType[]][] = crops.map((crop) => [
    crop,
    harvestTypes.filter((conf) => conf.cropUid === crop.uid),
  ]);

  const reloadList = async () => {
    console.log("reloading");

    fetchCrops(activeFarm!!.uid);
  };

  const upsertHarvestType = async (h: CreateHarvestType) => {
    if (h.name.length < 1) {
      alert("We're gonna need a name");
      return false;
    }
    if (!activeFarm) {
      return false;
    }
    await fetch(`api/product/harvesttype?farmUid=${activeFarm.uid}`, {
      method: "POST",
      body: JSON.stringify(h),
    });
    return true;
  };

  const deleteOrRestore = async (h: CreateHarvestType) => {
    if (activeFarm && h.uid) {
      return fetch(
        `/api/product/harvesttype/${h.uid}?farmUid=${activeFarm.uid}`,
        {
          method: h.deletedAt ? "POST" : "DELETE",
        }
      ).then((r) => {
        if (r.ok) {
          return reloadList().then(() => r.ok);
        } else {
          return r.ok;
        }
      });
    } else {
      return false;
    }
  };

  const emptyHarvestType = (crop: Crop) => {
    return {
      name: "",
      cropUid: crop.uid,
      crop: crop,
    };
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <BoxHeader title="Harvest types" />
      {activeFarm && (
        <Accordion
          elements={cropGroups
            .sort(([a], [b]) => a.name.localeCompare(b.name))
            .map(([crop, harvestTypes]) => {
              return {
                node: (
                  <>
                    <CreateharvestTypeRow
                      reloadList={reloadList}
                      harvestType={emptyHarvestType(crop)}
                      deleteOrRestore={deleteOrRestore}
                      upsertHarvestType={upsertHarvestType}
                    />
                    {harvestTypes.map((type) => (
                      <HarvestTypeRow
                        reloadList={reloadList}
                        harvestType={type}
                        key={type.uid}
                        deleteOrRestore={deleteOrRestore}
                        upsertHarvestType={upsertHarvestType}
                      />
                    ))}
                  </>
                ),
                titleNode: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{crop.name}</span>
                    </div>
                  </>
                ),
                key: crop.uid,
              };
            })}
        />
      )}
    </div>
  );
};

const CreateharvestTypeRow: React.FC<{
  harvestType: CreateHarvestType;
  reloadList: () => Promise<void>;
  deleteOrRestore: (h: CreateHarvestType) => Promise<boolean>;
  upsertHarvestType: (h: CreateHarvestType) => Promise<boolean>;
}> = ({ harvestType, reloadList, deleteOrRestore, upsertHarvestType }) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px",
          fontSize: "15px",
          fontWeight: 500,
          backgroundColor: backgroundGray,
          cursor: "pointer",
          color: darkBlue,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setShowUpdateModal(true);
        }}
      >
        <span>Create new harvest type...</span>
      </div>
      {showUpdateModal && (
        <HarvestTypeModal
          showModal={showUpdateModal}
          close={async () => setShowUpdateModal(false)}
          confirm={async () => {
            setShowUpdateModal(false);
            reloadList();
          }}
          harvestType={harvestType}
          deleteOrRestore={deleteOrRestore}
          upsertHarvestType={upsertHarvestType}
        />
      )}
    </>
  );
};

const HarvestTypeRow: React.FC<{
  harvestType: CreateHarvestType;
  reloadList: () => Promise<void>;
  deleteOrRestore: (h: CreateHarvestType) => Promise<boolean>;
  upsertHarvestType: (h: CreateHarvestType) => Promise<boolean>;
}> = ({ harvestType, reloadList, deleteOrRestore, upsertHarvestType }) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  return (
    <>
      <div
        key={harvestType.uid}
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: `1px solid ${lightGray}`,
          padding: "16px",
          fontSize: "15px",
          fontWeight: 500,
          backgroundColor: backgroundGray,
        }}
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={(_) => setShowUpdateModal(true)}
        >
          {harvestType.name}
        </span>
      </div>
      {showUpdateModal && (
        <HarvestTypeModal
          showModal={showUpdateModal}
          close={async () => setShowUpdateModal(false)}
          confirm={async () => {
            setShowUpdateModal(false);
            reloadList();
          }}
          harvestType={harvestType}
          deleteOrRestore={deleteOrRestore}
          upsertHarvestType={upsertHarvestType}
        />
      )}
    </>
  );
};

export type CreateHarvestType = {
  uid?: string;
  name: string;
  cropUid: string;
  deletedAt?: number;
  crop: Crop;
};

export default HarvestTypes;
