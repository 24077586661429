import { endOfDay } from "date-fns";
import { useEffect, useState } from "react";
import Accordion from "../../components/accordion";
import { Crop, CropConfiguration } from "../../store/cropConfigurationStore";
import { seed } from "../../util/colors";
import FinishTaskButton from "./FinishTaskButtonRow";
import TaskHeader from "./taskHeader";
import TaskRow, { valueFromTaskCompletionValue } from "./taskRow";
import { TaskCompletionValue } from "./types";

const SeedingTasks: React.FC<{
  cropConfigurations: Map<string, CropConfiguration>;
  crops: Crop[];
  farmUid?: string;
  activeDay: Date;
  setHasLoaded: () => void;
}> = ({ cropConfigurations, crops, farmUid, activeDay, setHasLoaded }) => {
  const [values, setValues] = useState<Map<string, TaskCompletionValue>>(
    new Map()
  );
  const [taskUid, setTaskUid] = useState<string>("");

  useEffect(() => {
    fetchTasks(activeDay, farmUid).then((r) => {
      const taskValues = r.tasks.reduce((a, t) => {
        return a.set(t.cropConfigurationUid, {
          todo: t.planned,
          done: t.completed === undefined ? undefined : t.completed,
          doing: t.completed === undefined ? t.planned : t.completed,
        });
      }, new Map<string, TaskCompletionValue>());
      setValues(taskValues);
      setTaskUid(r.taskUid ?? "");
      setHasLoaded();
    });
  }, [activeDay, farmUid, setHasLoaded]);

  let saveTaskState = () => {
    const body = {
      tasks: Array.from(values).map(
        ([cropConfigurationUid, { todo, doing }]) => {
          const updatedvalue = doing ?? todo;
          return {
            cropConfigurationUid: cropConfigurationUid,
            trays: updatedvalue,
          };
        }
      ),
      plannedTaskDate: activeDay
        .toISOString()
        .slice(0, activeDay.toISOString().indexOf("T")),
    };

    if (taskUid !== "") {
      fetch(`/api/tasks/seed?farmUid=${farmUid}`, {
        method: "delete",
        body: JSON.stringify({ taskUid: taskUid }),
      }).then((r) => setTaskUid(""));
    } else {
      fetch(`/api/tasks/seed?farmUid=${farmUid}`, {
        method: "post",
        body: JSON.stringify(body),
      })
        .then((r) => r.json())
        .then((r) => {
          setValues(
            Array.from(values).reduce(
              (a, [cropConfigurationUid, { todo, done, doing }]) => {
                return a.set(cropConfigurationUid, {
                  todo: todo,
                  done: doing ?? done,
                  doing: doing ?? done,
                });
              },
              new Map<string, TaskCompletionValue>()
            )
          );

          setTaskUid(r.taskUid);
        });
    }
  };

  const tasksByCrop = Array.from(values).reduce(
    (acc, [cropConfigurationUid, taskValue], i) => {
      const cropConfiguration = cropConfigurations.get(cropConfigurationUid);
      if (!cropConfiguration) {
        return acc;
      }
      if (!acc.has(cropConfiguration.cropUid)) {
        acc.set(cropConfiguration.cropUid, []);
      }
      return acc.set(cropConfiguration.cropUid, [
        ...acc.get(cropConfiguration.cropUid)!!,
        [cropConfiguration, taskValue],
      ]);
    },
    new Map<string, [CropConfiguration, TaskCompletionValue][]>()
  );

  return values.size === 0 ? (
    <></>
  ) : (
    <>
      <TaskHeader title="Seeding" color={seed} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Accordion
          elements={Array.from(tasksByCrop).map(([cropUid, taskValues]) => {
            const locked = taskUid !== "";
            const sumTrays = taskValues
              .map(([_, t]) => valueFromTaskCompletionValue(t, locked) ?? 0)
              .reduce((a, t) => a + t, 0);
            const differs =
              taskValues.find(
                ([_, t]) => t.todo !== valueFromTaskCompletionValue(t, locked)
              ) !== undefined;
            const accordingToPlanColor = "#92E690";
            const notToPlanColor = "#FBF5BD";
            const titleBg = locked
              ? differs
                ? notToPlanColor
                : accordingToPlanColor
              : "";
            return {
              key: cropUid,
              node: taskValues.map(([cropConfiguration, taskValue], i) => {
                return (
                  <TaskRow
                    key={cropConfiguration.uid}
                    onChangeValue={(v?: number) => {
                      setValues(
                        new Map(
                          values.set(cropConfiguration.uid, {
                            ...taskValue,
                            doing: v,
                          })
                        )
                      );
                    }}
                    taskValue={taskValue}
                    borderTop={true}
                    cropConfiguration={cropConfigurations.get(
                      cropConfiguration.uid
                    )}
                    locked={locked}
                    validateNotAboveTodo={false}
                    subTitle={" TRAYS"}
                    titleStyle={{ fontSize: "15px" }}
                  ></TaskRow>
                );
              }),
              titleNodeStyle: { backgroundColor: titleBg },
              titleNode: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    fontSize: "20px",
                    backgroundColor: titleBg,
                  }}
                >
                  <span>{crops.find((c) => c.uid === cropUid)?.name}</span>
                  <span
                    style={{
                      marginLeft: "auto",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >{`${sumTrays} TRAYS`}</span>
                </div>
              ),
            };
          })}
        />
      </div>
      <FinishTaskButton saveTaskState={saveTaskState} saved={taskUid !== ""} />
    </>
  );
};

type CompletedTasksResponse = {
  uid?: string;
  tasks: CompletedSeedTask[];
};

let fetchTasks = async (activeDay: Date, farmUid?: string) => {
  if (!farmUid) {
    return {
      tasks: [],
      taskUid: undefined,
    };
  }
  const [tasks, completedTasks] = await Promise.all([
    fetch(
      `/api/tasks/seed?farmUid=${farmUid}&from=${endOfDay(activeDay).getTime()}`
    )
      .then((r) => r.json())
      .then((r) => r.tasks as SeedTask[]),
    fetch(
      `/api/tasks/seed/completed?farmUid=${farmUid}&from=${endOfDay(
        activeDay
      ).getTime()}`
    )
      .then((r) => r.json())
      .then((r) => r as CompletedTasksResponse),
  ]);

  const todo = tasks.reduce((a, t) => {
    return a.set(t.cropConfigurationUid, t.trays);
  }, new Map<string, number>());

  const completed = completedTasks.tasks.reduce((a, t) => {
    return a.set(t.cropConfigurationUid, t.trays);
  }, new Map<string, number>());

  const combinedTasks = Array.from(todo).map(
    ([cropConfigurationUid, trays]) => {
      // if completed is non-empty, then we have saved the whole day here.
      // this means that any crop configuration with zero trays were saved as zero trays
      return {
        planned: trays,
        completed:
          completedTasks.tasks.length > 0
            ? completed.get(cropConfigurationUid) ?? 0
            : undefined,
        cropConfigurationUid: cropConfigurationUid,
      };
    }
  );
  return {
    tasks: combinedTasks,
    taskUid: completedTasks.uid,
  };
};

type SeedTask = {
  cropConfigurationUid: string;
  trays: number;
};

type CompletedSeedTask = {
  cropConfigurationUid: string;
  trays: number;
};

export default SeedingTasks;
