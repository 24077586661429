import CSS from "csstype";
import { danger } from "../util/colors";
import Button from "./button";
import LinkButton from "./linkButton";

const FormActionButtons: React.FC<{
  isDeleted: boolean;
  createNew: boolean;
  confirm: () => void;
  deleteOrRestore: () => void;
  style?: CSS.Properties;
}> = ({ isDeleted, createNew, deleteOrRestore, confirm, style }) => {
  return (
    <div style={{ marginTop: "16px", ...style }}>
      <LinkButton
        style={{ color: isDeleted ? "green" : danger }}
        label={isDeleted ? "Restore" : "Delete"}
        onClick={deleteOrRestore}
      ></LinkButton>
      <Button
        disabled={isDeleted ? true : false}
        style={{ marginLeft: "32px" }}
        label={createNew ? "Create" : "Update"}
        onClick={confirm}
      ></Button>
    </div>
  );
};

export default FormActionButtons;
