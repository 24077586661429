import React, { useState } from "react";
import InputButton from "./inputButton";

type LoginFormProps = {
  onFormSubmit: (values: { username: string; password: string }) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({ onFormSubmit }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event: any) => {
    onFormSubmit({
      username: username,
      password: password,
    });
    event.preventDefault();
  };

  const inputStyle = {
    flex: "0 0 2em",
    marginBottom: "3px",
    fontSize: "medium",
  };
  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          style={inputStyle}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={inputStyle}
        />
        <InputButton label="Login" />
      </div>
    </form>
  );
};

export default LoginForm;
