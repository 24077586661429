import { RouteComponentProps } from "@reach/router";
import { isToday } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import Box from "../../components/box";
import CropConfigurationStore, {
  CropConfiguration,
} from "../../store/cropConfigurationStore";
import CustomerStore from "../../store/customerStore";
import ProductStore from "../../store/productStore";
import UserStore from "../../store/userStore";
import { darkGray } from "../../util/colors";
import DayPicker from "./dayPicker";
import HarvestTasks from "./harvestTasks";
import PackingTasks from "./packingTasks";
import PropagationTasks from "./propagationTasks";
import SeedingTasks from "./seedingTasks";
import TransplantTasks from "./transplantTasks";

const Operation = (props: RouteComponentProps) => {
  const fetchCropConfigurations = CropConfigurationStore(
    (state) => state.fetch
  );

  const allCropConfigurations = CropConfigurationStore((state) =>
    state.all.reduce(
      (acc, p) => acc.set(p.uid, p),
      new Map<string, CropConfiguration>()
    )
  );

  const allCrops = CropConfigurationStore((state) => state.crops);
  const activeFarm = UserStore((state) => state.activeFarm);
  const allProducts = ProductStore((state) => state.all);
  const allCustomers = CustomerStore((state) => state.all);

  const [activeDay, setActiveDay] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);

  const activeDaySetter = (active: Date) => {
    setIsLoading(true);
    setActiveDay(active);
  };

  const setHasLoaded = useCallback(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (activeFarm) {
      fetchCropConfigurations(activeFarm.uid);
    }
  }, [activeFarm, fetchCropConfigurations]);

  let paddingStyle = { padding: "16px" };
  return (
    <div id="operation-wrapper" style={{ marginBottom: "200px" }}>
      <Box style={paddingStyle}>
        <DayPicker activeDay={activeDay} setActiveDay={activeDaySetter} />
        {!isToday(activeDay) && (
          <span
            style={{
              color: darkGray,
              fontSize: "10px",
              marginTop: "10px",
              cursor: "pointer",
            }}
            onClick={() => activeDaySetter(new Date())}
          >
            GO TO TODAY
          </span>
        )}
      </Box>
      {isLoading ? <span>Loading...</span> : <span></span>}
      <Box style={{ padding: "0px" }}>
        <TransplantTasks
          activeDay={activeDay}
          cropConfigurations={allCropConfigurations}
          farmUid={activeFarm?.uid}
          setHasLoaded={setHasLoaded}
        />
      </Box>
      <Box style={{ padding: "0px" }}>
        <HarvestTasks
          cropConfigurations={allCropConfigurations}
          farmUid={activeFarm?.uid}
          activeDay={activeDay}
          setHasLoaded={setHasLoaded}
        />
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "baseline",
        }}
      >
        <Box
          style={{
            width: "50%",
            padding: "0px",
          }}
        >
          <SeedingTasks
            cropConfigurations={allCropConfigurations}
            crops={allCrops}
            farmUid={activeFarm?.uid}
            activeDay={activeDay}
            setHasLoaded={setHasLoaded}
          />
        </Box>
        <Box
          style={{
            width: "50%",
            padding: "0px",
          }}
        >
          <PropagationTasks
            cropConfigurations={allCropConfigurations}
            crops={allCrops}
            farmUid={activeFarm?.uid}
            activeDay={activeDay}
            setHasLoaded={setHasLoaded}
          />
        </Box>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "baseline",
        }}
      >
        <Box style={{ padding: "0px", width: "50%" }}>
          <PackingTasks
            products={allProducts}
            customers={allCustomers}
            farmUid={activeFarm?.uid}
            activeDay={activeDay}
            setHasLoaded={setHasLoaded}
          />
        </Box>
      </div>
    </div>
  );
};

export default Operation;
