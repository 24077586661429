import addDays from "date-fns/addDays";
import format from "date-fns/format";
import { useCallback, useEffect, useState } from "react";
import FromToDatePicker from "../../components/fromToDatepicker";
import Switcher from "../../components/switcher";
import MultipleTimeSeriesChart from "../../components/timeseries/multipleTimeseriesChart";
import { TimeInterval } from "../../components/timeseries/types";
import { Product } from "../../store/productStore";
import { OrderUnit } from "../sales/orders";

export type HarvestForecastPoint = {
  productUid: string;
  total: number;
  ordered: number;
  available: number;
};

export type HarvestForecastForDate = {
  date: Date;
  products: HarvestForecastPoint[];
};

export type HarvestForecast = {
  timeSeries: HarvestForecastForDate[];
};

const timeIntervalValues = [
  { label: "Custom", value: TimeInterval.Custom },
  { label: "7 Days", value: TimeInterval.One_Week },
  { label: "3 weeks", value: TimeInterval.Three_Weeks },
];

const breakdownValues = [
  { label: "Total", value: false },
  { label: "Product specific", value: true },
];

const sortDataset = (
  d: Map<string, { v: number; date: number; label?: string }[]>
) => {
  return new Map(
    Array.from(d).map(([key, value]) => {
      return [key, value.sort((a, b) => a.date - b.date)];
    })
  );
};

const bagsToUnit = (bags: number, o: OrderUnit) => {
  if (o === OrderUnit.Bags) {
    return bags;
  } else {
    return Math.floor(bags / 8);
  }
};

const HarvestForecastGraph: React.FC<{
  products: Product[];
  farmUid?: string;
}> = ({ products, farmUid }) => {
  const [[from, to], setNewDates] = useState<Date[]>([
    new Date(),
    addDays(new Date(), 7),
  ]);

  const changeToDate = (date: Date | [Date | null, Date | null] | null) => {
    const newDate = date as Date;
    setNewDates([from, newDate]);
  };

  const [timeInterval, setTimeInterval] = useState(TimeInterval.One_Week);
  const changeFromDate = (date: Date | [Date | null, Date | null] | null) => {
    const newDate = date as Date;
    setNewDates([newDate, to]);
  };

  const productsMap = products.reduce((acc, p) => {
    acc.set(p.uid!!, p);
    return acc;
  }, new Map<string, Product>());

  const productName = (uid: string) => productsMap.get(uid)?.name ?? "?";

  const fetchHarvestForecast: () => Promise<HarvestForecast> = async () => {
    if (!farmUid) {
      return { timeSeries: [] };
    }
    const harvestForecast: HarvestForecast = await fetch(
      `api/forecast/harvest/?farmUid=${farmUid}&from=${format(
        from,
        "yyyy-MM-dd"
      )}&to=${format(to, "yyyy-MM-dd")}`
    )
      .then((r) => r.json())
      .then((f) => {
        return {
          timeSeries: f.timeSeries.map((t: HarvestForecastForDate) => {
            return { ...t, date: new Date(t.date) };
          }),
        };
      });
    return harvestForecast;
  };

  const memoizedFetchForecast = useCallback(fetchHarvestForecast, [
    farmUid,
    from,
    to,
  ]);

  const [forecast, setForecast] = useState<HarvestForecast>({ timeSeries: [] });
  useEffect(() => {
    memoizedFetchForecast().then((f) => setForecast(f));
  }, [farmUid, from, to, memoizedFetchForecast]);

  const useTimeInterval = (interval: TimeInterval) => {
    setTimeInterval(interval);
    //we set from to dates here
    if (interval === TimeInterval.One_Week) {
      setNewDates([new Date(), addDays(new Date(), 7)]);
    }
    if (interval === TimeInterval.Three_Weeks) {
      setNewDates([new Date(), addDays(new Date(), 21)]);
    }
  };

  const [breakdown, setBreakdown] = useState(false);

  const [orderUnit, setOrderUnit] = useState(OrderUnit.Crates);
  const bagsOrCratesOptions = [
    { value: OrderUnit.Crates, label: "Crates" },
    { value: OrderUnit.Bags, label: "Bags" },
  ];

  const tooltips = new Map<string, string>(
    Object.entries({
      Ordered: "Total order quantity",
      Harvest:
        "Forecast based on actual seeding and average weight per float over last 3 weeks",
    })
  );

  const dataBySold = forecast.timeSeries.reduce<
    Map<string, { v: number; date: number; label?: string }[]>
  >((acc, { date, products }) => {
    const soldOnDay = bagsToUnit(
      products.map((p) => p.ordered).reduce((a, e) => a + e, 0),
      orderUnit
    );
    const availableOnDay = bagsToUnit(
      products.map((p) => p.available).reduce((a, e) => a + e, 0),
      orderUnit
    );
    const soldPoints = acc.has("Ordered") ? acc.get("Ordered")!! : [];
    soldPoints.push({ v: soldOnDay, date: date.getTime() });
    acc.set("Ordered", soldPoints);

    const availablePoints = acc.has("Harvest") ? acc.get("Harvest")!! : [];
    availablePoints.push({ v: availableOnDay, date: date.getTime() });
    acc.set("Harvest", availablePoints);
    return acc;
  }, new Map<string, { v: number; date: number; label?: string }[]>());

  const dataByProduct = forecast.timeSeries.reduce<
    Map<string, { v: number; date: number; label?: string }[]>
  >((acc, { date, products }) => {
    products.forEach((p) => {
      const soldKey = productName(p.productUid) + " - Ordered";
      tooltips.set(soldKey, "Total order quantity");
      const productSoldPoints = acc.has(soldKey) ? acc.get(soldKey)!! : [];
      productSoldPoints.push({
        v: bagsToUnit(p.ordered, orderUnit),
        date: date.getTime(),
      });
      acc.set(soldKey, productSoldPoints);

      const harvestKey = productName(p.productUid) + " - Harvest";
      tooltips.set(
        harvestKey,
        "Forecast based on actual seeding and average weight per float over last 3 weeks"
      );
      const productHarvestPoints = acc.has(harvestKey)
        ? acc.get(harvestKey)!!
        : [];
      productHarvestPoints.push({
        v: bagsToUnit(p.available, orderUnit),
        date: date.getTime(),
      });
      acc.set(harvestKey, productHarvestPoints);
    });

    return acc;
  }, new Map<string, { v: number; date: number; label?: string }[]>());

  return (
    <div>
      <Switcher
        wrapperStyle={{ textAlign: "end" }}
        elements={bagsOrCratesOptions}
        selectedValue={orderUnit}
        onChange={(v) => setOrderUnit(v)}
      />
      <Switcher
        wrapperStyle={{ textAlign: "end" }}
        elements={breakdownValues}
        selectedValue={breakdown}
        onChange={setBreakdown}
      />
      <Switcher
        wrapperStyle={{ textAlign: "end" }}
        elements={timeIntervalValues}
        selectedValue={timeInterval}
        onChange={useTimeInterval}
      />
      {timeInterval === TimeInterval.Custom && (
        <div style={{ display: "inline-block" }}>
          <FromToDatePicker
            startDate={from}
            setStartDate={changeFromDate}
            endDate={to}
            setEndDate={changeToDate}
          />
        </div>
      )}
      <div style={{ height: "200px", display: "flex", flexDirection: "row" }}>
        <MultipleTimeSeriesChart
          id="yieldHa"
          valueLabel=""
          timeSeries={
            breakdown ? sortDataset(dataByProduct) : sortDataset(dataBySold)
          }
          legendTooltips={new Map<string, string>(tooltips)}
        />
      </div>
    </div>
  );
};

export default HarvestForecastGraph;
