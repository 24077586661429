const addDays: (date: Date, days: number) => Date = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
const dayInMillis = 24 * 60 * 60 * 1000;

const roundDays = (time: number | undefined, def: number) => {
  return time ? Math.round(time / dayInMillis) : def;
};

export { addDays, dayInMillis, roundDays };
