import CSS from "csstype";
import React, { useState } from "react";
import Modal from "react-modal";
import { danger, darkBlue, darkGray, lightBlue } from "../util/colors";
import Button from "./button";

type ModalProps = {
  style?: CSS.Properties;
  closeModal: (e: React.MouseEvent | React.KeyboardEvent) => void;
  showModal: boolean;
  title?: string;
  question?: string;
  confirm: () => Promise<void>;
  cancel: () => void;
  destructive?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  postConfirmMessage: string;
  onConfirm: () => Promise<void>;
};

const ConfirmationModal: React.FC<ModalProps> = ({
  style,
  closeModal,
  showModal,
  confirm,
  cancel,
  title,
  question,
  destructive,
  confirmLabel,
  cancelLabel,
  postConfirmMessage,
  onConfirm,
}) => {
  const confirmStyle = destructive ? { backgroundColor: danger } : {};
  const [hasConfirmed, setHasConfirmed] = useState(false);
  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        content: {
          width: "30%",
          height: "fit-content",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "15px",
          padding: "36px",
          border: "none",
          textAlign: "center",
          overflow: "visible",
          ...style,
        },
        overlay: { backgroundColor: "rgba(0,0,0,0.1)" },
      }}
    >
      {hasConfirmed ? (
        <div>
          <div>{postConfirmMessage}</div>
          <div
            style={{
              marginTop: "36px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              label={"Ok"}
              onClick={async (e) => {
                await confirm();
                setHasConfirmed(false);
                closeModal(e);
              }}
              style={{
                color: darkBlue,
                backgroundColor: lightBlue,
              }}
            ></Button>
          </div>
        </div>
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          {title && <div style={{ fontWeight: 600 }}>{title}</div>}
          {question && <div>{question}</div>}
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              label={confirmLabel ?? "Confirm"}
              onClick={async () => {
                await onConfirm();
                setHasConfirmed(true);
              }}
              style={confirmStyle}
            ></Button>
            <Button
              label={cancelLabel ?? "Cancel"}
              onClick={cancel}
              style={{
                backgroundColor: "transparent",
                color: darkGray,
                marginTop: "13px",
                width: "fit-content",
                alignSelf: "center",
              }}
            ></Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
