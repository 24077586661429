import React, { useEffect, useState } from "react";
import Switcher from "../../../components/switcher";
import CropConfigurationStore from "../../../store/cropConfigurationStore";
import UserStore from "../../../store/userStore";
import { darkGray } from "../../../util/colors";
import CreateNewPlanModal from "./createNewPlanModal";
import PlansList from "./plansList";
import { Plan, PlannerUnit } from "./types";

let HarvestPlanner: React.FC<{}> = () => {
  const allCropConfigurations = CropConfigurationStore((state) => state.all);
  const allCrops = CropConfigurationStore((state) => state.crops);

  const activeFarm = UserStore((state) => state.activeFarm);
  const [plans, setPlans] = useState(new Map<string, Plan[]>());

  const fetchPlans = async (farm: string) => {
    const response = await fetch(
      `/api/planning/plan/list?farmUid=${farm}`
    ).then((r) => r.json());
    setPlans(new Map<string, Plan[]>(Object.entries(response.plans)));
  };

  useEffect(() => {
    if (activeFarm) {
      fetchPlans(activeFarm.uid);
    }
  }, [activeFarm]);

  const [unit, setUnit] = useState(PlannerUnit.Floats);
  const [modalIsOpen, setModalIsOpen] = useState(new Map<string, boolean>());

  const closeModal = (e: React.MouseEvent | React.KeyboardEvent) => {
    setModalIsOpen(new Map<string, boolean>());
    e.stopPropagation();
  };

  const openModal = (cropConfiguration: string) => {
    let map = new Map<string, boolean>();
    map.set(cropConfiguration, true);
    setModalIsOpen(map);
  };

  const onCreateNew = () => {
    if (activeFarm) {
      return fetchPlans(activeFarm.uid).then((p) =>
        setModalIsOpen(new Map<string, boolean>())
      );
    }
  };

  const inactivatePlan = async (planUid: string) => {
    if (activeFarm) {
      await fetch(`/api/planning/plan/${planUid}`, {
        method: "DELETE",
      });
    }
  };

  const reloadList = async () => {
    if (activeFarm) {
      await fetchPlans(activeFarm!!.uid);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px",
        }}
      >
        <span>
          <span>Planner</span>
          <span
            style={{ fontSize: "15px", color: darkGray, marginLeft: "12px" }}
          >
            Seed / Harvest
          </span>
        </span>

        <Switcher
          elements={[
            { value: PlannerUnit.Crates, label: "Crates" },
            { value: PlannerUnit.Bags, label: "Bags" },
            { value: PlannerUnit.Floats, label: "Floats" },
          ]}
          selectedValue={unit}
          onChange={(e) => setUnit(e)}
        />
      </div>
      <PlansList
        crops={allCrops}
        cropConfigurations={allCropConfigurations}
        plans={plans}
        unit={unit}
        openModal={openModal}
        inactivate={inactivatePlan}
        reloadList={reloadList}
      />
      {allCropConfigurations.map((p) => {
        let showModal = modalIsOpen.get(p.uid) ?? false;
        return showModal ? (
          <CreateNewPlanModal
            unit={unit}
            showModal={showModal}
            closeModal={closeModal}
            cropConfiguration={p}
            onCreateNew={onCreateNew}
            farmUid={activeFarm?.uid}
            key={p.uid}
          />
        ) : (
          <React.Fragment key={p.uid}></React.Fragment>
        );
      })}
    </div>
  );
};
export default HarvestPlanner;
