import CSS from "csstype";
import React from "react";

type ButtonProps = {
  label: string;
  onClick: (e: any) => void;
  inactive?: boolean;
  style?: CSS.Properties;
};

const LinkButton: React.FC<ButtonProps> = ({
  label,
  onClick,
  inactive,
  style,
}) => {
  return (
    <span
      style={{
        color: inactive ? "#A7A7A7" : "#3D6DEB",
        fontSize: "17px",
        fontWeight: 500,
        cursor: inactive ? "auto" : "pointer",
        ...style,
      }}
      onClick={onClick}
    >
      {label}
    </span>
  );
};

export default LinkButton;
