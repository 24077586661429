import { format } from "date-fns";
import React, { useState } from "react";
import Button from "../../../components/button";
import FromToDatePicker from "../../../components/fromToDatepicker";
import Modal from "../../../components/modal";
import Switcher from "../../../components/switcher";
import { CropConfiguration } from "../../../store/cropConfigurationStore";
import { darkGray, lightGray } from "../../../util/colors";
import { addDays } from "../../../util/dates";
import cropConfigurationSubtitle from "../crops/util";
import { Frequency, PlannerUnit } from "./types";

const CreateNewPlanModal: React.FC<{
  showModal: boolean;
  closeModal: (e: React.MouseEvent | React.KeyboardEvent) => void;
  cropConfiguration: CropConfiguration;
  onCreateNew: () => void;
  farmUid?: string;
  unit: PlannerUnit;
}> = ({
  showModal,
  closeModal,
  cropConfiguration,
  onCreateNew,
  farmUid,
  unit,
}) => {
  let handleCratesAmountChange = (crates: string) => {
    let value = parseInt(crates);
    setCrateAmount(value);
  };
  let handleFloatsAmountChange = (floats: string) => {
    let value = parseInt(floats);
    setFloatsAmount(value);
  };

  let seedToHarvestMillis =
    cropConfiguration.germinationTime +
    cropConfiguration.propagationTime +
    cropConfiguration.maturationTime;
  let seedToTransplantMillis =
    cropConfiguration.germinationTime + cropConfiguration.propagationTime;
  const dayMillis = 1000 * 60 * 60 * 24;
  let daysBetween = seedToHarvestMillis / dayMillis;
  let daysToTransplant = seedToTransplantMillis / dayMillis;

  const [crateAmount, setCrateAmount] = useState(0);
  const [floatsAmount, setFloatsAmount] = useState(0);
  const [frequency, setFrequency] = useState(Frequency.Once);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), daysBetween));

  const createNew = (
    harvestTime: Date,
    frequency: Frequency,
    cropConfigurationName: string,
    crates: number,
    floats: number
  ) => {
    if (!crates && !floats) {
      alert("Need to fill in either floats or crates");
      return;
    }
    let body = {
      harvestTime: harvestTime.valueOf(),
      frequency: frequency,
      cropConfigurationName: cropConfigurationName,
      bags: unit === PlannerUnit.Floats ? undefined : crates * 8,
      floats: unit === PlannerUnit.Floats ? floats : undefined,
    };
    fetch(`/api/planning/plan?farmUid=${farmUid}`, {
      method: "POST",
      body: JSON.stringify(body),
    }).then((_) => {
      onCreateNew();
    });
  };

  const floatsInput = unit === PlannerUnit.Floats;

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          userSelect: "none",
        }}
      >
        <span style={{ fontWeight: 600, fontSize: "20px" }}>New Plan</span>
        <span style={{ fontWeight: 500, fontSize: "15px" }}>
          {cropConfiguration.title}
        </span>
        <span style={{ fontWeight: 500, fontSize: "15px", color: darkGray }}>
          {cropConfiguration.crop.name +
            " " +
            cropConfigurationSubtitle(cropConfiguration)}
        </span>

        <div style={{ marginTop: "43px" }}>
          <FromToDatePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            startTitle="SEEDING"
            endTitle="HARVEST"
            daysBetween={daysBetween}
            middleChildren={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: darkGray,
                    fontSize: "15px",
                    fontWeight: 600,
                  }}
                >
                  TRANSPLANTING
                </span>
                <span
                  style={{
                    border: "none",
                    textAlign: "center",
                    color: darkGray,
                    fontWeight: 500,
                    fontSize: 17,
                    width: 100,
                  }}
                >
                  {format(addDays(startDate, daysToTransplant), "eee d MMM")}
                </span>
              </div>
            }
          ></FromToDatePicker>
        </div>
        {floatsInput ? (
          <InputField
            title="FLOATS TO HARVEST"
            amount={floatsAmount}
            handleAmountChange={handleFloatsAmountChange}
          />
        ) : (
          <InputField
            title="CRATES TO HARVEST"
            amount={crateAmount}
            handleAmountChange={handleCratesAmountChange}
          />
        )}
        <Switcher
          elements={[
            { value: Frequency.Once, label: "Once" },
            { value: Frequency.Weekly, label: "Weekly" },
            { value: Frequency.Weekday, label: "Weekdays" },
          ]}
          selectedValue={frequency}
          onChange={(e) => setFrequency(e)}
          wrapperStyle={{ height: "40px", marginTop: "20px" }}
          contentStyle={{ fontSize: "17px" }}
        />
        <Button
          style={{ marginTop: "40px" }}
          onClick={() => {
            createNew(
              endDate,
              frequency,
              cropConfiguration.uid,
              crateAmount,
              floatsAmount
            );
          }}
          label="Create"
        ></Button>
      </div>
    </Modal>
  );
};

const InputField: React.FC<{
  amount: number;
  title: string;
  handleAmountChange: (value: string) => void;
}> = ({ amount, title, handleAmountChange }) => {
  return (
    <>
      <span
        style={{
          fontWeight: 600,
          fontSize: "15px",
          color: darkGray,
          marginTop: "30px",
        }}
      >
        {title}
      </span>
      <input
        value={amount || ""}
        type="number"
        onChange={(e) => handleAmountChange(e.currentTarget.value)}
        style={{
          backgroundColor: lightGray,
          textAlign: "center",
          fontSize: "19px",
          fontWeight: 600,
          height: "40px",
          border: "none",
        }}
      ></input>
    </>
  );
};

export default CreateNewPlanModal;
