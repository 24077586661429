import create, { GetState, SetState } from "zustand";
import { persist } from "zustand/middleware";

export type Farm = {
  uid: string;
  title: string;
};
export type UserInformation = {
  fullName: string;
  symbol: string;
  superAdmin: boolean;
  uid: string;
};

type UserState = {
  user?: UserInformation;
  farms: Farm[];
  activeFarm?: Farm;
  set: SetState<UserState>;
  get: GetState<UserState>;
  setUser: (user: UserInformation) => void;
  setFarms: (farms: Farm[]) => void;
  setActiveFarm: (farm: Farm) => void;
};

const UserStore = create<UserState>(
  persist(
    (set, get) => ({
      set: set,
      get: get,
      user: undefined,
      setUser: (user) => set((_) => ({ user: user })),
      farms: [],
      setFarms: (farms) => set((_) => ({ farms: farms })),
      activeFarm: undefined,
      setActiveFarm: (farm) => set((_) => ({ activeFarm: farm })),
    }),
    { name: "userStore" }
  )
);

export default UserStore;
