import Select from "react-select";
import makeAnimated from "react-select/animated";

export type Option = {
  value: string;
  label: string;
};

export const selectorStyles = {
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: "16px",
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    fontSize: "16px",
    cursor: "pointer",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "16px",
    padding: "2px",
    cursor: "pointer",
  }),
};

const Multiselect: React.FC<{
  options: Option[];
  disabled: boolean;
  setSelected: (value: readonly Option[]) => void;
  selected: readonly Option[];
  placeholder?: String;
}> = ({ options, disabled, setSelected, selected, placeholder }) => {
  const animatedComponents = makeAnimated();

  const onChange = (option: readonly Option[]) => {
    setSelected(option);
  };

  return (
    <Select
      isDisabled={disabled}
      styles={selectorStyles}
      options={options}
      components={animatedComponents}
      value={selected}
      onChange={(v, a) => onChange(v as Option[])}
      isMulti
      placeholder={placeholder}
    />
  );
};

export default Multiselect;
