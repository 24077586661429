import CSS from "csstype";
import React, { MouseEvent } from "react";
import { lightGray } from "../util/colors";

type ButtonProps = {
  label: string;
  onClick: (e: MouseEvent) => void;
  style?: CSS.Properties;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({ label, onClick, style, disabled }) => {
  return (
    <button
      disabled={disabled}
      style={{
        padding: "10px 17px 11px",
        background: disabled ? lightGray : "#2656FF",
        borderRadius: "5px",
        color: "white",
        fontSize: "medium",
        cursor: disabled ? "auto" : "pointer",
        border: "none",
        ...style,
      }}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
