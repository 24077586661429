import { darkGray } from "../util/colors";

const InputLabel: React.FC<{ title: string }> = ({ title }) => {
  return (
    <span
      style={{
        fontWeight: 600,
        fontSize: "15px",
        color: darkGray,
        marginTop: "10px",
      }}
    >
      {title}
    </span>
  );
};
export default InputLabel;
