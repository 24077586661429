import CSS from "csstype";
import { useState } from "react";
import { lightGray, white } from "../util/colors";

const LockableTextInput: React.FC<{
  value?: string;
  setValue: (s: string) => void;
  locked: boolean;
  editingEnabledByDefault?: boolean;
  inputStyle?: CSS.Properties;
}> = ({ value, setValue, locked, editingEnabledByDefault, inputStyle }) => {
  const [editing, setEditing] = useState(editingEnabledByDefault ?? false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  return !editing ? (
    <span
      onClick={() => {
        if (!locked) toggleEditing();
      }}
    >
      <input
        style={{
          backgroundColor: !editing || locked ? "transparent" : lightGray,
          textAlign: "center",
          fontSize: "19px",
          fontWeight: 600,
          height: "40px",
          border: "none",
          cursor: locked ? "default" : "pointer",
          ...inputStyle,
        }}
        value={value || ""}
        disabled={true}
      ></input>
    </span>
  ) : (
    <input
      value={value || ""}
      onChange={(e) => setValue(e.target.value)}
      style={{
        backgroundColor: locked ? "transparent" : !editing ? white : lightGray,
        textAlign: "center",
        fontSize: "19px",
        fontWeight: 600,
        height: "40px",
        border: "none",
        ...inputStyle,
      }}
      disabled={locked || !editing}
    />
  );
};

export default LockableTextInput;
