import { useCallback, useEffect, useState } from "react";
import BoxHeader from "../../components/boxHeader";
import FormActionButtons from "../../components/formActionButtons";
import Modal from "../../components/modal";
import ModalHeader from "../../components/modalHeader";
import TitledInput from "../../components/titledInput";
import UserStore, { Farm } from "../../store/userStore";
import { darkGray, lightGray } from "../../util/colors";
import FarmSelector from "./farmSelector";

let UserManagement: React.FC<{}> = () => {
  let setUser = UserStore().set;
  // At some point I should learn how to memoize the user value here properly
  let getUser = UserStore().get;
  let farms = UserStore((state) => state.farms);
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState<Array<UserManagementUser>>([]);
  const [modalUser, setModalUser] = useState<UserManagementUser>(emptyUser());

  const loadUsers = useCallback(() => {
    fetch("api/usermanagement/list")
      .then((r) => r.json())
      .then((response) => {
        const responseUsers: UserManagementUser[] = response.users;
        setUsers(responseUsers);
        const activeUser = responseUsers.filter(
          (u) => u.uid === getUser().user?.uid
        );
        if (activeUser.length > 0 && getUser().user) {
          const current = activeUser[0];
          setUser((userState) => ({
            user: {
              ...userState.user!!,
              fullName: current.fullName,
              symbol: current.symbol,
            },
          }));
        }
      });
  }, [setUser, getUser]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const validateBody: (
    user: UserManagementUser
  ) => CreateUserRequestBody | UpdateUserRequestBody = (
    user: UserManagementUser
  ) => {
    const update = user.uid !== undefined;
    const errors = [];
    if (user.fullName.length < 1) {
      errors.push("Must specify full name");
    }
    if (user.username.length < 1) {
      errors.push("Must specify username");
    }
    if (!update && !user.password) {
      errors.push("Must specify password");
    }
    if (!user.symbol) {
      errors.push("Must specify symbol");
    }
    if (user.password && user.password?.length < 6) {
      errors.push("Password must be longer than 6 characters");
    }
    if (errors.length > 0) {
      throw Error(errors.join("\n"));
    }

    const body = update
      ? {
          ...user,
          farmUids: user.farms.map((f) => f.uid),
          password: user.password === "" ? undefined : user.password,
        }
      : {
          ...user,
          farmUids: user.farms.map((f) => f.uid),
          password: user.password!!,
        };
    return body;
  };

  const update: (user: UserManagementUser) => Promise<boolean> = async (
    user
  ) => {
    try {
      const body = validateBody(user);
      await fetch(`api/usermanagement/user/${user.uid}`, {
        method: "POST",
        body: JSON.stringify(body),
      }).then((r) => loadUsers());
      return true;
    } catch (e) {
      alert(e);
      return new Promise(() => false);
    }
  };

  const create: (user: UserManagementUser) => Promise<boolean> = async (
    user
  ) => {
    try {
      const body = validateBody(user);
      await fetch("api/usermanagement/user", {
        method: "POST",
        body: JSON.stringify(body),
      }).then((r) => loadUsers());
      return true;
    } catch (e) {
      alert(e);
      return new Promise(() => false);
    }
  };

  const deleteOrRestoreUser: (user: UserManagementUser) => Promise<boolean> =
    async (user) => {
      if (user.deletedAt) {
        await fetch(`api/usermanagement/user/restore/${user.uid}`, {
          method: "POST",
        }).then((r) => loadUsers());
        return true;
      }
      await fetch(`api/usermanagement/user/${user.uid}`, {
        method: "DELETE",
      }).then((r) => loadUsers());
      return true;
    };

  return (
    <>
      <BoxHeader
        title="User management"
        linkTitle="Create new user"
        onClick={() => {
          setModalUser(emptyUser());
          setShowModal(true);
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          borderTop: `1px solid ${lightGray}`,
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 4fr 1fr",
            textAlign: "start",
            fontWeight: 600,
            fontSize: "15px",
            color: darkGray,
          }}
        >
          <span>USERNAME</span>
          <span>FULL NAME</span>
          <span style={{ textAlign: "end" }}>EDIT</span>
        </div>
        {users.map((user) => (
          <div
            key={user.uid}
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 4fr 1fr",
              textAlign: "start",
              background: user.deletedAt ? lightGray : "transparent",
            }}
          >
            <span>{user.username}</span>
            <span>{`${user.fullName} - ${user.symbol}`}</span>
            <span
              onClick={() => {
                setModalUser(user);
                setShowModal(true);
              }}
              style={{ cursor: "pointer", textAlign: "end" }}
            >
              ✏️
            </span>
          </div>
        ))}
        <CreateUserModal
          user={modalUser}
          farms={farms}
          setModalUser={setModalUser}
          showModal={showModal}
          setShowModal={setShowModal}
          confirm={() => {
            return modalUser.uid ? update(modalUser) : create(modalUser);
          }}
          deleteOrRestore={deleteOrRestoreUser}
        />
      </div>
    </>
  );
};

const CreateUserModal: React.FC<{
  showModal: boolean;
  setShowModal: (s: boolean) => void;
  user: UserManagementUser;
  setModalUser: (user: UserManagementUser) => void;
  farms: UserManagementFarm[];
  confirm: (u: UserManagementUser) => Promise<boolean>;
  deleteOrRestore: (u: UserManagementUser) => Promise<boolean>;
}> = ({
  showModal,
  setShowModal,
  user,
  setModalUser,
  farms,
  confirm,
  deleteOrRestore,
}) => {
  const locked = user.deletedAt !== undefined && user.deletedAt !== null;
  return (
    <Modal showModal={showModal} closeModal={() => setShowModal(false)}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          userSelect: "none",
        }}
      >
        <div>
          <ModalHeader title={user.uid ? "Edit user" : "New user"} />
        </div>
        <TitledInput
          title="Username"
          locked={user?.uid !== undefined}
          value={user?.username ?? ""}
          setValue={(v) => setModalUser({ ...user, username: v })}
        />
        <TitledInput
          title="Password"
          locked={locked}
          value={user.password ?? ""}
          placeholder={user.uid !== undefined ? "****" : ""}
          setValue={(v) => setModalUser({ ...user, password: v })}
        />
        <TitledInput
          title="Full Name"
          locked={locked}
          value={user?.fullName ?? ""}
          setValue={(v) => setModalUser({ ...user, fullName: v })}
        />
        <TitledInput
          title="Symbol"
          locked={locked}
          value={user?.symbol ?? ""}
          setValue={(v) => {
            if (v.length < 3) setModalUser({ ...user, symbol: v });
          }}
        />
        <FarmSelector
          disabled={locked}
          farms={farms.map((f) => {
            return { value: f.uid, label: f.title };
          })}
          selected={user.farms.map((f) => {
            return { value: f.uid, label: f.title };
          })}
          setSelected={(farmOptions) =>
            setModalUser({
              ...user,
              farms: farmOptions.map((f) => {
                return { uid: f.value, title: f.label };
              }),
            })
          }
        />
        <FormActionButtons
          isDeleted={user.deletedAt ? true : false}
          createNew={user.uid ? false : true}
          deleteOrRestore={async () => {
            const validationOk = await deleteOrRestore(user);
            if (validationOk) {
              setShowModal(false);
            }
          }}
          confirm={async () => {
            const validationOk = await confirm(user);
            if (validationOk) {
              setShowModal(false);
            }
          }}
        />
      </div>
    </Modal>
  );
};

type UserManagementFarm = Farm;

type UserManagementUser = {
  username: string;
  fullName: string;
  symbol: string;
  uid?: string;
  deletedAt?: number;
  password?: string;
  farms: UserManagementFarm[];
};

type CreateUserRequestBody = UpdateUserRequestBody & {
  password: string;
};

type UpdateUserRequestBody = {
  username: string;
  fullName: string;
  symbol: string;
  farmUids: string[];
};

const emptyUser = () => {
  return {
    username: "",
    fullName: "",
    symbol: "",
    password: "",
    farms: [],
  };
};

export default UserManagement;
