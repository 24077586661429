import React, { useState } from "react";
import Accordion from "../../../components/accordion";
import BoxHeader from "../../../components/boxHeader";
import ConfirmationModal from "../../../components/confirmationModal";
import LinkButton from "../../../components/linkButton";
import CropConfigurationStore, {
  ApiCrop,
  CropConfiguration,
  HarvestType,
} from "../../../store/cropConfigurationStore";
import UserStore from "../../../store/userStore";
import {
  backgroundGray,
  danger,
  darkBlue,
  lightGray,
} from "../../../util/colors";
import CropConfigurationModal from "./cropConfigurationModal";
import CropModal from "./cropModal";
import cropConfigurationSubtitle from "./util";

const Crops = () => {
  const fetchCrops = CropConfigurationStore((state) => state.fetch);
  const crops = CropConfigurationStore((state) => state.crops);
  const cropConfigs = CropConfigurationStore((state) => state.active);
  const harvestTypes = CropConfigurationStore((state) => state.harvestTypes);
  const activeFarm = UserStore((state) => state.activeFarm);
  const [showModal, setShowModal] = useState(false);

  const cropConfigurationGroups: [ApiCrop, CropConfiguration[]][] = crops.map(
    (crop) => [crop, cropConfigs.filter((conf) => conf.cropUid === crop.uid)]
  );

  const createNew = () => {
    setShowModal(true);
  };

  const onCreateNew = () => {
    fetchCrops(activeFarm!!.uid);
    setShowModal(false);
  };

  const reloadList = async () => {
    fetchCrops(activeFarm!!.uid);
  };

  const inactivateConfiguration = async (cropConfigurationUid: string) => {
    if (activeFarm) {
      await fetch(
        `/api/crop/configuration/${cropConfigurationUid}?farmUid=${activeFarm.uid}`,
        {
          method: "DELETE",
        }
      );
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <BoxHeader title="Crops" linkTitle="New Crop" onClick={createNew} />
      {activeFarm && (
        <Accordion
          elements={cropConfigurationGroups
            .sort(([a], [b]) => a.name.localeCompare(b.name))
            .map(([crop, configs]) => {
              const cropHarvestTypes = harvestTypes.filter(
                (h) => h.cropUid === crop.uid
              );
              return {
                node: (
                  <>
                    <CreateConfigurationRow
                      crop={crop}
                      reloadList={reloadList}
                      farmUid={activeFarm.uid}
                      harvestTypes={cropHarvestTypes}
                    />
                    {configs.map((config) => (
                      <CropConfigurationRow
                        cropConfiguration={config}
                        key={config.uid}
                        inactivate={inactivateConfiguration}
                        reloadList={reloadList}
                        farmUid={activeFarm.uid}
                        harvestTypes={cropHarvestTypes}
                      />
                    ))}
                  </>
                ),
                titleNode: (
                  <>
                    <CropTitleRow
                      crop={crop}
                      reloadList={reloadList}
                      farmUid={activeFarm?.uid}
                      harvestTypes={cropHarvestTypes}
                    >
                      {crop.name}
                    </CropTitleRow>
                  </>
                ),
                key: crop.uid,
              };
            })}
        />
      )}
      <CropModal
        showModal={showModal}
        closeModal={(e) => setShowModal(false)}
        onFinish={onCreateNew}
        farmUid={activeFarm?.uid}
      />
    </div>
  );
};

const CreateConfigurationRow: React.FC<{
  crop: ApiCrop;
  reloadList: () => Promise<void>;
  farmUid?: string;
  harvestTypes: HarvestType[];
}> = ({ crop, reloadList, farmUid, harvestTypes }) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px",
          fontSize: "15px",
          fontWeight: 500,
          backgroundColor: backgroundGray,
          cursor: "pointer",
          color: darkBlue,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setShowUpdateModal(true);
        }}
      >
        <span>Create new configuration...</span>
      </div>
      {showUpdateModal && (
        <CropConfigurationModal
          showModal={showUpdateModal}
          closeModal={(e) => setShowUpdateModal(false)}
          onFinish={() => {
            setShowUpdateModal(false);
            reloadList();
          }}
          farmUid={farmUid}
          crop={crop}
          harvestTypes={harvestTypes}
        />
      )}
    </>
  );
};

const CropTitleRow: React.FC<{
  crop: ApiCrop;
  reloadList: () => Promise<void>;
  farmUid?: string;
  harvestTypes: HarvestType[];
}> = ({ crop, reloadList, farmUid, harvestTypes }) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  return (
    <>
      <div
        key={crop.uid}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>{crop.name}</span>
      </div>
      {showUpdateModal && (
        <CropConfigurationModal
          showModal={showUpdateModal}
          closeModal={(e) => setShowUpdateModal(false)}
          onFinish={() => {
            setShowUpdateModal(false);
            reloadList();
          }}
          farmUid={farmUid}
          crop={crop}
          harvestTypes={harvestTypes}
        />
      )}
    </>
  );
};

const CropConfigurationRow: React.FC<{
  cropConfiguration: CropConfiguration;
  inactivate: (cropConfigurationUid: string) => Promise<void>;
  reloadList: () => Promise<void>;
  farmUid?: string;
  harvestTypes: HarvestType[];
}> = ({ cropConfiguration, inactivate, reloadList, farmUid, harvestTypes }) => {
  const [showDeprecateModal, setShowDeprecateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  return (
    <>
      <div
        key={cropConfiguration.uid}
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: `1px solid ${lightGray}`,
          padding: "16px",
          fontSize: "15px",
          fontWeight: 500,
          backgroundColor: backgroundGray,
        }}
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={(_) => setShowUpdateModal(true)}
        >
          {cropConfiguration.title +
            " " +
            cropConfigurationSubtitle(cropConfiguration)}
        </span>
        <LinkButton
          label="x"
          style={{ color: danger }}
          onClick={() => setShowDeprecateModal(true)}
        />
      </div>
      {showUpdateModal && (
        <CropConfigurationModal
          showModal={showUpdateModal}
          closeModal={(e) => setShowUpdateModal(false)}
          onFinish={() => {
            setShowUpdateModal(false);
            reloadList();
          }}
          farmUid={farmUid}
          cropConfiguration={cropConfiguration}
          crop={cropConfiguration.crop}
          harvestTypes={harvestTypes}
        />
      )}
      {showDeprecateModal && (
        <ConfirmationModal
          destructive={true}
          closeModal={(e) => setShowDeprecateModal(false)}
          showModal={showDeprecateModal}
          title={`Deprecate ${cropConfiguration.title} ?`}
          question={`Existing plans will keep this crop configuration but no new plans can be created with it.`}
          confirm={async () => {
            await reloadList();
          }}
          onConfirm={async () => {
            await inactivate(cropConfiguration.uid);
          }}
          cancel={() => setShowDeprecateModal(false)}
          postConfirmMessage="Crop configuration has been deprecated"
        ></ConfirmationModal>
      )}
    </>
  );
};

export default Crops;
