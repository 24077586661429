import create from "zustand";
import { persist } from "zustand/middleware";

export type Product = {
  uid?: string;
  name: string;
  harvestTypes: string[];
  deletedAt?: number;
};

type ProductState = {
  all: Product[];
  fetch: (farm: string) => void;
};

const ProductStore = create<ProductState>(
  persist(
    (set, _) => ({
      fetch: async (farm: string) => {
        const products = await fetch(`api/product/product/list?farmUid=${farm}`)
          .then((r) => r.json())
          .then((r) => r.products);
        set({
          all: products,
        });
      },
      all: [],
    }),
    { name: "productStore" }
  )
);

export default ProductStore;
