import onceIcon from "../svg/once.svg";
import repeatIcon from "../svg/repeat.svg";
import { Frequency } from "../views/production/harvestplanner/types";

const FrequencyIcon: React.FC<{ frequency: Frequency }> = ({ frequency }) => {
  return frequency === Frequency.Once ? (
    <img
      style={{
        width: "15px",
        height: "15px",
        alignSelf: "center",
        marginRight: "5px",
      }}
      src={onceIcon}
      alt={frequency}
    />
  ) : (
    <img
      style={{
        width: "15px",
        height: "15px",
        alignSelf: "center",
        marginRight: "5px",
      }}
      src={repeatIcon}
      alt={frequency}
    />
  );
};

export default FrequencyIcon;
