import CSS from "csstype";
import React from "react";

type BoxProps = {
  style?: CSS.Properties;
};

const Box: React.FC<BoxProps> = ({ children, style }) => {
  return (
    <div
      style={{
        padding: "1px",
        background: "#FFFFFF",
        borderRadius: "20px",
        margin: "30px 16px 0 16px",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Box;
