import { useState } from "react";
import FormActionButtons from "../../../components/formActionButtons";
import FarmOSModal from "../../../components/modal";
import ModalHeader from "../../../components/modalHeader";
import TitledInput from "../../../components/titledInput";
import { CreateHarvestType } from "./harvestTypes";

const HarvestTypeModal: React.FC<{
  harvestType: CreateHarvestType;
  showModal: boolean;
  close: () => Promise<void>;
  confirm: () => Promise<void>;
  deleteOrRestore: (h: CreateHarvestType) => Promise<boolean>;
  upsertHarvestType: (h: CreateHarvestType) => Promise<boolean>;
}> = ({
  harvestType,
  showModal,
  close,
  confirm,
  deleteOrRestore,
  upsertHarvestType,
}) => {
  const [value, setValue] = useState<CreateHarvestType>(harvestType);
  return (
    <FarmOSModal showModal={showModal} closeModal={close}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          userSelect: "none",
        }}
      >
        <ModalHeader
          title={
            harvestType.uid
              ? harvestType.name
              : `New harvest type for ${harvestType.crop.name}`
          }
        />
        <TitledInput
          title="Name"
          setValue={(v) => setValue({ ...value, name: v })}
          value={value.name}
        ></TitledInput>
        <FormActionButtons
          isDeleted={value.deletedAt ? true : false}
          createNew={value.uid ? false : true}
          deleteOrRestore={async () => {
            if (await deleteOrRestore(value)) confirm();
          }}
          confirm={async () => {
            if (await upsertHarvestType(value)) confirm();
          }}
        />
      </div>
    </FarmOSModal>
  );
};

export default HarvestTypeModal;
