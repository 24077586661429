import { RouteComponentProps } from "@reach/router";
import { useEffect } from "react";
import Box from "../components/box";
import CropConfigurationStore from "../store/cropConfigurationStore";
import UserStore from "../store/userStore";
import Crops from "./production/crops/crops";
import FarmBenches from "./production/farmstate/farmBenches";
import FarmTrays from "./production/farmstate/farmTrays";
import HarvestPlanner from "./production/harvestplanner/harvestplanner";
import HarvestTypes from "./production/harvestTypes/harvestTypes";

const Production = (props: RouteComponentProps) => {
  const activeFarm = UserStore((state) => state.activeFarm);
  const fetchCropConfigurations = CropConfigurationStore(
    (state) => state.fetch
  );
  useEffect(() => {
    if (activeFarm) {
      fetchCropConfigurations(activeFarm.uid);
    }
  }, [activeFarm, fetchCropConfigurations]);
  return (
    <div id="production-wrapper" style={{ marginBottom: "100px" }}>
      <Box>
        <HarvestPlanner></HarvestPlanner>
      </Box>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <Box
          style={{
            width: "50%",
          }}
        >
          <Crops />
        </Box>
        <Box
          style={{
            width: "50%",
          }}
        >
          <HarvestTypes />{" "}
        </Box>
      </div>
      <Box>
        <FarmTrays farmUid={activeFarm?.uid}></FarmTrays>
      </Box>
      <Box>
        <FarmBenches farmUid={activeFarm?.uid}></FarmBenches>
      </Box>
    </div>
  );
};

export default Production;
