import { CropConfiguration } from "../../../store/cropConfigurationStore";
import { roundDays } from "../../../util/dates";

const cropConfigurationSubtitle = (c: CropConfiguration) =>
  `{${roundDays(c.germinationTime, 0)}, ${roundDays(
    c.propagationTime,
    0
  )}, ${roundDays(c.maturationTime, 0)}}`;

export default cropConfigurationSubtitle;
