import CSS from "csstype";
import { darkGray } from "../util/colors";

const TitledText: React.FC<{
  text: string;
  style?: CSS.Properties;
  titleStyle?: CSS.Properties;
  title: string;
}> = ({ text, style, title, titleStyle }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...style,
      }}
    >
      <span
        style={{
          fontWeight: 600,
          fontSize: "15px",
          color: darkGray,
          ...titleStyle,
        }}
      >
        {title}
      </span>
      <span
        style={{
          height: "40px",
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default TitledText;
