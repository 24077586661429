import { navigate, RouteComponentProps, Router } from "@reach/router";
import { useEffect } from "react";
import Box from "../components/box";
import Climate from "./climate";
import Economy from "./economy/economy";
import Header from "./header";
import Operation from "./operations/operation";
import Production from "./production";
import Sales from "./sales/sales";
import UserDetails from "./userdetails";

const Home = (props: RouteComponentProps) => (
  <div id="home-wrapper" style={{ marginBottom: "100px" }}>
    <Box style={{ padding: "16px" }}>
      <Climate></Climate>
    </Box>
  </div>
);
const Logout = (props: RouteComponentProps) => {
  useEffect(() => {
    localStorage.clear();
  });
  navigate("/");
  return <></>;
};

const Dashboard: React.FC<{}> = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        maxWidth: "1200px",
        margin: "auto auto auto auto",
      }}
    >
      <Header userFullName="Jens Arvidsson" />
      <Router primary={false}>
        <Home path="/" />
        <Production path="production" />
        <Sales path="sales" />
        <Economy path="economy" />
        <UserDetails path="user"></UserDetails>
        <Operation path="operation"></Operation>
        <Logout path="logout"></Logout>
      </Router>
    </div>
  );
};

export default Dashboard;
