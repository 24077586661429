import { subDays } from "date-fns";
import addDays from "date-fns/addDays";
import ReactDatePicker from "react-datepicker";
import arrowLeft from "../../svg/arrowLeft.svg";
import arrowRight from "../../svg/arrowRight.svg";

let DayPicker: React.FC<{
  activeDay: Date;
  setActiveDay: (date: Date) => void;
}> = ({ activeDay, setActiveDay }) => {
  const changeDate = (date: Date | [Date | null, Date | null] | null) => {
    const newDate = date as Date;
    setActiveDay(newDate);
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        alignItems: "center",
      }}
    >
      <img
        style={{ cursor: "pointer" }}
        src={arrowLeft}
        alt="arrow left"
        onClick={(e) => setActiveDay(subDays(activeDay, 1))}
      ></img>
      <div>
        <ReactDatePicker
          selected={activeDay}
          onChange={changeDate}
          popperPlacement="auto"
          showPopperArrow={true}
          className="uo-date-picker"
          dateFormat="eee d MMM"
        />
      </div>
      <img
        style={{ cursor: "pointer" }}
        src={arrowRight}
        alt="arrow right"
        onClick={(e) => setActiveDay(addDays(activeDay, 1))}
      ></img>
    </div>
  );
};

export default DayPicker;
