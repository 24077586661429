import CSS from "csstype";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Accordion from "../../../components/accordion";
import LinkButton from "../../../components/linkButton";
import TitledText from "../../../components/titledText";
import CropConfigurationStore, {
  CropConfiguration,
} from "../../../store/cropConfigurationStore";
import {
  backgroundGray,
  danger,
  darkGray,
  lightGray,
  propagate,
  transplant,
  white,
} from "../../../util/colors";

type FarmStateTray = {
  uid: string;
  cropConfigurationUid: string;
  seedingDate: number;
  propagationDate?: number;
  discarded: boolean;
};

const FarmTrays: React.FC<{
  farmUid?: string;
}> = ({ farmUid }) => {
  const cropConfigurations = CropConfigurationStore((state) =>
    state.all.reduce(
      (acc, p) => acc.set(p.uid, p),
      new Map<string, CropConfiguration>()
    )
  );
  const [trays, setTrays] = useState<Map<string, FarmStateTray[]>>(new Map());
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!farmUid) {
      return;
    }
    fetch(`api/farmstate/trays?farmUid=${farmUid}&from=${Date.now()}`)
      .then((r) => r.json())
      .then((r) => {
        setTrays(new Map(Object.entries(r.trays)));
        setLoading(false);
      });
  }, [farmUid]);

  const rowStyle: CSS.Properties = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr auto",
    justifyContent: "space-between",
    textAlign: "start",
    borderTop: "1px solid #EEEEEE",
    backgroundColor: backgroundGray,
    padding: "16px",
    fontSize: "15px",
    fontWeight: 500,
  };

  const deleteTray = async (tray: FarmStateTray) => {
    const response = await fetch(
      `/api/farmstate/floats/${tray.uid}?farmUid=${farmUid}`,
      {
        method: tray.discarded ? "post" : "delete",
      }
    );
    if (response.ok) {
      setTrays(
        new Map(
          trays.set(
            tray.cropConfigurationUid,
            trays.get(tray.cropConfigurationUid)!!.map((t) => {
              return t.uid !== tray.uid
                ? t
                : { ...t, discarded: !tray.discarded };
            })
          )
        )
      );
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px",
        }}
      >
        <span>Trays</span>
      </div>
      {loading && (
        <div
          key=""
          style={{
            borderTop: `1px solid ${lightGray}`,
            padding: "16px",
            fontSize: "15px",
            fontWeight: 500,
          }}
        >
          Loading...
        </div>
      )}
      <Accordion
        elements={Array.from(trays).map(([cropConfigurationUid, trays]) => {
          return {
            key: cropConfigurationUid,
            titleNode: (
              <span>{`${cropConfigurations.get(cropConfigurationUid)?.title} (${
                trays.length
              })`}</span>
            ),
            node: (
              <>
                {trays.map((tray) => (
                  <TrayRow
                    key={tray.uid}
                    tray={tray}
                    cropConfiguration={
                      cropConfigurations.get(cropConfigurationUid)!!
                    }
                    style={rowStyle}
                    deleteTray={deleteTray}
                  />
                ))}
              </>
            ),
          };
        })}
      />
      <div style={{ ...rowStyle, backgroundColor: "transparent" }}>
        <span>{`Total: ${Array.from(trays).reduce(
          (a, [_, t]) => a + t.length,
          0
        )} trays`}</span>
      </div>
    </div>
  );
};

let TrayRow: React.FC<{
  tray: FarmStateTray;
  cropConfiguration: CropConfiguration;
  style: CSS.Properties;
  deleteTray: (tray: FarmStateTray) => void;
}> = ({ tray, cropConfiguration, style, deleteTray }) => {
  const transplantedDate = tray.propagationDate
    ? tray.propagationDate + cropConfiguration.propagationTime
    : tray.seedingDate +
      cropConfiguration.germinationTime +
      cropConfiguration.propagationTime;
  const titleStyle = tray.discarded ? { color: white } : {};
  return (
    <>
      <div
        style={{
          ...style,
          backgroundColor: tray.discarded ? darkGray : style.backgroundColor,
        }}
      >
        <TitledText
          title="SEEDED"
          titleStyle={titleStyle}
          text={format(new Date(tray.seedingDate), "eee d MMM")}
        />
        <span
          style={{ textAlign: "center", color: propagate, alignSelf: "center" }}
        >
          {"————>"}
        </span>
        {
          <TitledText
            title="PROPAGATED"
            titleStyle={titleStyle}
            text={
              tray.propagationDate
                ? format(new Date(tray.propagationDate), "eee d MMM")
                : ""
            }
          />
        }
        <span
          style={{
            textAlign: "center",
            color: transplant,
            alignSelf: "center",
          }}
        >
          {"————>"}
        </span>
        <TitledText
          title="TRANSPLANT"
          titleStyle={titleStyle}
          text={format(new Date(transplantedDate), "eee d MMM")}
        />
        <LinkButton
          label={tray.discarded ? "?" : "x"}
          style={{
            color: tray.discarded ? white : danger,
            alignSelf: "center",
          }}
          onClick={() => deleteTray(tray)}
        />
      </div>
    </>
  );
};
export default FarmTrays;
