import CSS from "csstype";
import { useCallback, useEffect } from "react";
import create from "zustand";
import { persist } from "zustand/middleware";
import LoginForm from "../components/loginform";
import UserStore from "../store/userStore";
import logo from "../svg/logo.svg";
import Dashboard from "./dashboard";

type AuthenticatedState = {
  authenticated: boolean;
  setAuthenticated: () => void;
  logout: () => void;
};

type AuthFailureState = {
  setFailed: () => void;
  authFailed: boolean;
};

const containerStyle: CSS.Properties = {
  marginTop: "10vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const authFailureStore = create<AuthFailureState>((set) => ({
  authFailed: false,
  setFailed: () => set((state) => ({ authFailed: true })),
}));

const authenticationStore = create<AuthenticatedState>(
  persist(
    (set, get) => ({
      authenticated: false,
      setAuthenticated: () => set((state) => ({ authenticated: true })),
      logout: () => set({ authenticated: false }),
    }),
    { name: "authenticationStore" }
  )
);

function Login() {
  const isAuthenticated = authenticationStore((state) => state.authenticated);
  const setAuthenticated = authenticationStore(
    (state) => state.setAuthenticated
  );
  const authFailed = authFailureStore((state) => state.authFailed);
  const setFailed = authFailureStore((state) => state.setFailed);
  const setUser = UserStore((state) => state.setUser);
  const setFarms = UserStore((state) => state.setFarms);
  const setActiveFarm = UserStore((state) => state.setActiveFarm);

  const getFarmData = useCallback(async () => {
    fetch("/api/farmstate/farm/list")
      .then((r) => r.json())
      .then((response) => {
        setFarms(response.farms);

        if (response.farms?.length > 0) {
          const active = response.farms[0];
          setActiveFarm(active);
        }
      });
  }, [setActiveFarm, setFarms]);

  useEffect(() => {
    if (isAuthenticated) {
      getFarmData();
    }
  }, [isAuthenticated, getFarmData]);

  const authenticate = (values: { username: string; password: string }) => {
    fetch("/api/authenticate", {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("failed to authenticate");
        }
      })
      .then(
        (res: {
          token: string;
          fullName: string;
          symbol: string;
          uid: string;
          superAdmin: boolean;
        }) => {
          localStorage.setItem("token", res.token);
          setAuthenticated();
          setUser(res);
          return getFarmData();
        }
      )
      .catch(() => {
        setFailed();
      });
  };

  const LoginSwitch: React.FC<{ authenticated: boolean }> = ({
    authenticated,
  }) => {
    if (authenticated) {
      return <Dashboard />;
    }
    return (
      <div className="App" style={containerStyle}>
        <img src={logo} className="logo" alt="logo" />
        {authFailed && <div>Failed login!</div>}
        <div
          style={{
            width: "350px",
            display: "flex",
            flexDirection: "column",
            marginTop: "30px",
          }}
        >
          <LoginForm onFormSubmit={(values) => authenticate(values)} />
        </div>
      </div>
    );
  };

  return <LoginSwitch authenticated={isAuthenticated} />;
}

export default Login;
