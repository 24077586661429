import Select from "react-select";
import makeAnimated from "react-select/animated";
import { selectorStyles } from "../../components/multiselect";
import { darkGray } from "../../util/colors";

type UserManagementFarmOption = {
  value: string;
  label: string;
};

const FarmSelector: React.FC<{
  farms: readonly UserManagementFarmOption[];
  selected: readonly UserManagementFarmOption[];
  setSelected: (value: readonly UserManagementFarmOption[]) => void;
  disabled?: boolean;
}> = ({ farms, selected, setSelected, disabled }) => {
  const animatedComponents = makeAnimated();

  const onChange = (option: readonly UserManagementFarmOption[]) => {
    setSelected(option);
  };

  return (
    <div style={{ marginTop: "16px", marginBottom: "16px" }}>
      <span
        style={{
          fontWeight: 600,
          fontSize: "15px",
          color: darkGray,
        }}
      >
        Farms
      </span>
      <Select
        isDisabled={disabled}
        styles={selectorStyles}
        options={farms}
        components={animatedComponents}
        value={selected}
        onChange={(v, a) => onChange(v as UserManagementFarmOption[])}
        isMulti
        placeholder={"Choose farms..."}
      />
    </div>
  );
};

export default FarmSelector;
