const danger = "#FF7C7C";
const lightGray = "#EEEEEE";
const darkGray = "#A7A7A7";
const backgroundGray = "#F8F8F8";
const mediumGray = "#999999";
const white = "#FFFFFF";
const darkBlue = "#2656FF";
const lightBlue = "rgba(38, 86, 255, 0.1)";
const harvest = "#97EDB9";
const propagate = "#FFCDBD";
const seed = "#FCD5EC";
const transplant = "#D1F8CE";
const pack = "#CCDDFF";

export {
  danger,
  lightGray,
  mediumGray,
  darkGray,
  backgroundGray,
  white,
  darkBlue,
  lightBlue,
  harvest,
  propagate,
  seed,
  transplant,
  pack,
};
