import CSS from "csstype";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import BoxHeader from "../../components/boxHeader";
import Button from "../../components/button";
import CentesimalPriceInput from "../../components/centesimalPriceInput";
import ConfirmationModal from "../../components/confirmationModal";
import InputLabel from "../../components/inputLabel";
import LinkButton from "../../components/linkButton";
import { Option } from "../../components/multiselect";
import IntegerInput from "../../components/numberInput";
import Switcher from "../../components/switcher";
import CustomerStore, { Customer } from "../../store/customerStore";
import ProductStore, { Product } from "../../store/productStore";
import UserStore, { Farm } from "../../store/userStore";
import {
  backgroundGray,
  danger,
  darkGray,
  mediumGray,
} from "../../util/colors";
import { addDays } from "../../util/dates";
import { generateUUID } from "../../util/strings";
import {
  HarvestForecast,
  HarvestForecastForDate,
} from "../economy/harvestForecast";
import DayPicker from "../operations/dayPicker";

type OrderLine = {
  createdAt: number;
  product: string; //uuid
  bags: number;
  unitPriceCentesimal: number;
};

export type Order = {
  createdAt: number;
  deletedAt?: number;
  uid?: string;
  customer: string; //uuid
  date: Date; //this is a day
  lines: OrderLine[];
};

type UnfinishedOrderLine = {
  key: string;
  createdAt: Date;
  product?: string;
  bags?: number;
  unitPriceCentesimal?: number;
};

type UnfinishedOrder = {
  uid?: string;
  customer?: string;
  date?: Date;
  lines?: UnfinishedOrderLine[];
};

export enum OrderUnit {
  Bags = "BAGS",
  Crates = "CRATES",
}

const bagsToUnit = (bags: number, o: OrderUnit) => {
  if (o === OrderUnit.Bags) {
    return bags;
  } else if (o === OrderUnit.Crates) {
    return Math.floor(bags / 8);
  }
};

const unitToBags = (value: number, o: OrderUnit) => {
  if (o === OrderUnit.Bags) {
    return value;
  } else if (o === OrderUnit.Crates) {
    return value * 8;
  }
};

const Orders: React.FC<{}> = () => {
  const activeFarm = UserStore((state) => state.activeFarm);
  const [orders, setOrders] = useState<Order[]>([]);
  const [unfinishedOrder, setUnfinishedOrder] = useState<
    UnfinishedOrder | undefined
  >();
  const [editingOrder, setEditingOrder] = useState<
    [Order, UnfinishedOrder] | undefined
  >();
  const allProducts = ProductStore((state) => state.all);
  const activeProducts = allProducts.filter((p) => !p.deletedAt);
  const customers = CustomerStore((state) =>
    state.all.filter((s) => !s.deletedAt)
  );

  const [ordersListFromDate, setOrdersListFromDate] = useState(new Date());
  const [ordersListToDate, setOrdersListToDate] = useState(
    addDays(new Date(), 14)
  );

  const fetchOrders = async () => {
    if (!activeFarm?.uid) {
      return [];
    }
    const orders: Order[] = await fetch(
      `api/order/order/list?farmUid=${activeFarm?.uid}&from=${format(
        ordersListFromDate,
        "yyyy-MM-dd"
      )}&to=${format(ordersListToDate, "yyyy-MM-dd")}`
    )
      .then((r) => r.json())
      .then((r) =>
        r.orders.map((o: Order) => {
          return { ...o, date: new Date(o.date) };
        })
      );
    return orders;
  };

  const memoizedFetch = useCallback(fetchOrders, [
    activeFarm,
    ordersListFromDate,
    ordersListToDate,
  ]);

  useEffect(() => {
    memoizedFetch().then((fetchedOrders) => {
      if (fetchedOrders) {
        setOrders(fetchedOrders);
      }
    });
  }, [memoizedFetch, setOrders]);

  const reloadList = async () => {
    const reloaded = await fetchOrders();
    if (reloaded) {
      setOrders(reloaded);
    }
  };

  const upsertOrder = async (order: Order) => {
    if (!activeFarm) {
      return;
    }
    if (!order.customer) {
      alert("Need a customer");
      return;
    }
    if (!order.date) {
      alert("Need a date");
      return;
    }
    if (order.lines.length < 1) {
      alert("Need at least one line item");
      return;
    }

    await fetch(`api/order/order?farmUid=${activeFarm.uid}`, {
      method: "POST",
      body: JSON.stringify({
        ...order,
        createdAt: undefined,
        date: format(order.date, "yyyy-MM-dd"),
      }),
    });
  };

  const deleteOrRestoreOrder = async (
    orderUid: string,
    restore: boolean = false
  ) => {
    if (activeFarm) {
      return fetch(`/api/order/order/${orderUid}?farmUid=${activeFarm.uid}`, {
        method: restore ? "POST" : "DELETE",
      }).then((r) => {
        if (r.ok) {
          return reloadList().then(() => r.ok);
        } else {
          return r.ok;
        }
      });
    } else {
      return false;
    }
  };

  const [orderUnit, setOrderUnit] = useState(OrderUnit.Crates);
  const bagsOrCratesOptions = [
    { value: OrderUnit.Crates, label: "Crates" },
    { value: OrderUnit.Bags, label: "Bags" },
  ];

  const [removalModalOrder, setRemovalModalOrder] = useState<
    Order | undefined
  >();
  const notEditing = !unfinishedOrder && !editingOrder;
  return (
    <>
      <BoxHeader
        title="Orders"
        linkTitle={notEditing ? "Create new" : "Cancel"}
        linkColor={notEditing ? undefined : danger}
        onClick={() => {
          if (notEditing) {
            setUnfinishedOrder({
              lines: activeProducts.map((p, i) => {
                return {
                  key: generateUUID(),
                  createdAt: new Date(new Date().getTime() + i),
                  product: p.uid,
                };
              }),
            });
          } else if (unfinishedOrder) {
            setUnfinishedOrder(undefined);
          } else if (editingOrder) {
            setEditingOrder(undefined);
          }
        }}
      />
      <div
        style={{ display: "flex", flexDirection: "row", paddingLeft: "16px" }}
      >
        <div>From:</div>
        <div style={{ width: "fit-content", marginLeft: "10px" }}>
          <DayPicker
            activeDay={ordersListFromDate}
            setActiveDay={setOrdersListFromDate}
          />
        </div>
        <div style={{ marginRight: "10px", marginLeft: "10px" }}>-</div>
        <div style={{ width: "fit-content" }}>
          <DayPicker
            activeDay={ordersListToDate}
            setActiveDay={setOrdersListToDate}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingLeft: "16px",
          marginBottom: "16px",
        }}
      >
        <div style={{ marginRight: "10px" }}>Display: </div>
        <Switcher
          elements={bagsOrCratesOptions}
          selectedValue={orderUnit}
          onChange={(v) => setOrderUnit(v)}
        />
      </div>
      {unfinishedOrder && !editingOrder && (
        <CreateOrder
          order={unfinishedOrder}
          customers={customers}
          cancel={() => setUnfinishedOrder(undefined)}
          products={allProducts}
          remove={() => {}}
          create={async (o: Order) => {
            await upsertOrder(o);
            setUnfinishedOrder(undefined);
            reloadList();
          }}
          unit={orderUnit}
          activeFarm={activeFarm}
        />
      )}
      {orders
        .filter((o) => !o.deletedAt)
        .sort((a, b) => a.createdAt - b.createdAt)
        .map((o) => {
          return editingOrder && editingOrder[0].uid === o.uid ? (
            <CreateOrder
              key={o.uid ?? generateUUID()}
              products={allProducts}
              customers={customers}
              order={editingOrder[1]}
              cancel={() => {
                setEditingOrder(undefined);
              }}
              remove={() => {
                setRemovalModalOrder(o);
              }}
              create={async (o: Order) => {
                await upsertOrder(o);
                setEditingOrder(undefined);
                reloadList();
              }}
              unit={orderUnit}
              activeFarm={activeFarm}
            />
          ) : (
            <FinishedOrder
              key={o.uid}
              products={allProducts}
              customers={customers}
              order={o}
              edit={() => {
                setEditingOrder([
                  o,
                  {
                    ...o,
                    lines: o.lines.map((l) => {
                      return {
                        ...l,
                        createdAt: new Date(l.createdAt),
                        key: generateUUID(),
                      };
                    }),
                  },
                ]);
              }}
              unit={orderUnit}
            />
          );
        })}
      <ConfirmationModal
        showModal={removalModalOrder !== undefined}
        closeModal={() => setRemovalModalOrder(undefined)}
        cancel={() => setRemovalModalOrder(undefined)}
        title="Remove order?"
        confirm={async () => {
          const uid = removalModalOrder?.uid;
          if (uid) await deleteOrRestoreOrder(uid, false);
          setEditingOrder(undefined);
        }}
        postConfirmMessage="Order removed"
        onConfirm={() => reloadList()}
      />
    </>
  );
};

const FinishedOrder: React.FC<{
  order: Order;
  edit: () => void;
  products: Product[];
  customers: Customer[];
  unit: OrderUnit;
}> = ({ order, edit, products, customers, unit }) => {
  const customerName = (uid?: string) => {
    return customers.find((c) => c.uid === uid)?.name ?? "?";
  };
  const productName = (uid?: string) => {
    return products.find((c) => c.uid === uid)?.name ?? "?";
  };
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr 4fr auto auto",
        padding: "5px",
        paddingLeft: "16px",
        borderTop: "1px solid #EEEEEE",
        textAlign: "start",
      }}
    >
      <span>{customerName(order.customer)}</span>
      <span>{format(order.date, "d MMM")}</span>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {order.lines.map((l) => {
          return (
            <div
              key={l.product + l.bags}
              style={{
                display: "grid",
                gridTemplateRows: "1fr 1fr",
                placeItems: "center",
                backgroundColor: backgroundGray,
                fontSize: "13px",
                marginRight: "2px",
                borderRadius: "3px",
                lineHeight: "15px",
                padding: "2px",
              }}
            >
              <span style={{ color: mediumGray }}>
                {productName(l.product)}
              </span>
              <span>{bagsToUnit(l.bags, unit)}</span>
            </div>
          );
        })}
      </div>
      <LinkButton
        style={{ color: darkGray }}
        label="Edit"
        onClick={() => edit()}
      />
    </div>
  );
};

const CreateOrder: React.FC<{
  order: UnfinishedOrder;
  cancel: () => void;
  create: (o: Order) => void;
  remove: () => void;
  products: Product[];
  customers: Customer[];
  activeFarm?: Farm;
  unit: OrderUnit;
}> = ({
  order,
  cancel,
  create,
  remove,
  products,
  customers,
  activeFarm,
  unit,
}) => {
  const activeProducts = products.filter((p) => !p.deletedAt);

  const options: Option[] = customers.map((c) => {
    return { value: c.uid!!, label: c.name };
  });

  const customerName = (uid?: string) => {
    return customers.find((c) => c.uid === uid)?.name ?? "?";
  };

  const customerByUid = (uid?: string) => {
    return customers.find((c) => c.uid === uid);
  };

  const [customer, setCustomer] = useState<Option | undefined | null>(
    order.customer
      ? { label: customerName(order.customer), value: order.customer }
      : undefined
  );
  const [orderDate, setOrderDate] = useState<Date>(order.date ?? new Date());

  const [orderLines, setOrderLines] = useState<UnfinishedOrderLine[]>(
    order.lines ?? []
  );

  const [harvestForecast, setHarvestForecast] = useState<
    HarvestForecastForDate | undefined
  >();

  useEffect(() => {
    if (!activeFarm?.uid) {
      return;
    }

    fetch(
      `api/forecast/harvest/?farmUid=${activeFarm?.uid}&from=${format(
        orderDate,
        "yyyy-MM-dd"
      )}&to=${format(orderDate, "yyyy-MM-dd")}`
    )
      .then((r) => r.json())
      .then((f: HarvestForecast) => {
        const forDate = f.timeSeries.find(
          (f) => new Date(f.date).toDateString() === orderDate.toDateString()
        );
        setHarvestForecast(
          forDate ? { ...forDate, date: new Date(forDate.date) } : undefined
        );
      });
  }, [orderDate, setHarvestForecast, activeFarm]);

  const onChangeDate = (d: Date) => {
    setOrderDate(d);
  };

  const onChangeCustomer = (option: Option | undefined | null) => {
    const cust = customerByUid(option?.value);
    setCustomer(option);
    if (cust) {
      const defaultPrices = generateDefaultPricesMap(cust);
      console.log(defaultPrices);

      setOrderLines(
        activeProducts.map((p, i) => {
          return {
            key: p.uid ?? generateUUID(),
            createdAt: new Date(new Date().getTime() + i),
            product: p.uid,
            unitPriceCentesimal: p.uid ? defaultPrices.get(p.uid) : undefined,
          };
        })
      );
    }
  };

  const sectionStyle: CSS.Properties = {
    display: "grid",
    gridTemplateColumns: "1fr 3fr auto",
    borderTop: "1px solid #EEEEEE",
    padding: "10px",
    paddingLeft: "16px",
    textAlign: "start",
  };

  const validateOrderLineIsComplete = (u: UnfinishedOrderLine) => {
    const isComplete =
      u.bags !== undefined &&
      u.product !== undefined &&
      u.unitPriceCentesimal !== undefined;

    return isComplete;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={sectionStyle}>
        <InputLabel title="Customer: " />
        <div style={{ width: "40%" }}>
          <Select<Option>
            isDisabled={false}
            options={options}
            value={customer}
            onChange={onChangeCustomer}
            placeholder={"Select customer..."}
          />
        </div>
        <div />
      </div>
      <div style={sectionStyle}>
        <InputLabel title="Date" />
        <div style={{ width: "fit-content" }}>
          <DayPicker activeDay={orderDate} setActiveDay={onChangeDate} />
        </div>
      </div>
      <div
        style={{
          borderTop: "1px solid #EEEEEE",
          padding: "10px",
          paddingLeft: "16px",
          textAlign: "start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 2fr 2fr 1fr auto",
            marginTop: "5px",
            textAlign: "center",
            fontWeight: 600,
            fontSize: "15px",
            color: darkGray,
          }}
        >
          <span>PRODUCT</span>
          <span>UNIT PRICE</span>
          <span>{unit}</span>
        </div>
        {orderLines
          .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
          .map((l, i) => {
            return (
              <CreateProductLine
                key={l.key}
                harvestForecast={harvestForecast}
                line={l}
                products={products}
                customer={customerByUid(customer?.value)}
                setLine={(u) => {
                  setOrderLines([
                    ...orderLines.filter((o) => o.key !== l.key),
                    u,
                  ]);
                }}
                remove={() => {
                  setOrderLines([...orderLines.filter((o) => o.key !== l.key)]);
                }}
                unit={unit}
              />
            );
          })}
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          padding: "10px",
          textAlign: "start",
        }}
      >
        <Button
          style={{ width: "200px" }}
          label="Add product"
          onClick={(e) => {
            setOrderLines([
              ...orderLines,
              { key: generateUUID(), createdAt: new Date() },
            ]);
          }}
        ></Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginBottom: "16px",
          marginRight: "16px",
        }}
      >
        <div style={{ marginTop: "16px" }}>
          <LinkButton
            style={{ color: danger }}
            label="Cancel"
            onClick={cancel}
          ></LinkButton>

          {order.uid && (
            <Button
              label="Delete"
              style={{ backgroundColor: danger, marginLeft: "32px" }}
              onClick={() => remove()}
            />
          )}
          <Button
            disabled={false}
            style={{ marginLeft: "32px" }}
            label={order.uid ? "Update" : "Create"}
            onClick={() => {
              if (!customer) {
                alert("No customer set");
                return;
              }
              if (!orderDate) {
                alert("no date set");
                return;
              }
              const newOrder = {
                uid: order.uid,
                createdAt: new Date().getTime(),
                customer: customer.value,
                date: orderDate,
                lines: orderLines
                  .filter(validateOrderLineIsComplete)
                  .map((l, i) => {
                    return {
                      product: l.product!!,
                      bags: l.bags!!,
                      unitPriceCentesimal: l.unitPriceCentesimal!!,
                      createdAt: new Date().getTime() + i,
                    };
                  }),
              };
              create(newOrder);
            }}
          ></Button>
        </div>
      </div>
    </div>
  );
};

const CreateProductLine: React.FC<{
  line: UnfinishedOrderLine;
  setLine: (u: UnfinishedOrderLine) => void;
  products: Product[];
  remove: () => void;
  customer?: Customer;
  harvestForecast?: HarvestForecastForDate;
  unit: OrderUnit;
}> = ({ line, setLine, products, remove, customer, harvestForecast, unit }) => {
  const style: CSS.Properties = {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 2fr 1fr auto",
    marginTop: "5px",
    textAlign: "center",
  };

  const options = products
    .filter((p) => !p.deletedAt)
    .map((p) => {
      return { value: p.uid!!, label: p.name };
    });

  const changeProduct = (p: Option | undefined | null) => {
    if (
      p &&
      customer?.productDefaultPrices?.find((d) => d.productUid === p.value)
    ) {
      setLine({
        ...line,
        unitPriceCentesimal: customer?.productDefaultPrices?.find(
          (d) => d.productUid === p.value
        )?.priceCentesimal,
        product: p?.value,
      });
    } else {
      setLine({ ...line, product: p?.value });
    }
  };

  return (
    <div style={style}>
      <div style={{ width: "90%" }}>
        <Select<Option | undefined>
          isDisabled={false}
          options={options}
          value={
            line.product
              ? {
                  value: line.product,
                  label: products.find((p) => p.uid === line.product)!!.name,
                }
              : undefined
          }
          onChange={(v, a) => changeProduct(v)}
          placeholder={"Select product..."}
        />
      </div>
      <div style={{ marginLeft: "5px" }}>
        <CentesimalPriceInput
          price={line.unitPriceCentesimal}
          setPrice={(newPrice) => {
            setLine({ ...line, unitPriceCentesimal: newPrice });
          }}
        />
      </div>
      <div style={{ marginLeft: "5px" }}>
        <IntegerInput
          amount={line.bags ? bagsToUnit(line.bags, unit) : undefined}
          setAmount={(b) => setLine({ ...line, bags: unitToBags(b, unit) })}
        />
      </div>
      <div style={{ marginLeft: "5px", display: "flex", alignItems: "center" }}>
        <span>
          {(line.product &&
          harvestForecast &&
          harvestForecast.products.find((p) => p.productUid === line.product)
            ? "/ " +
              bagsToUnit(
                harvestForecast.products.find(
                  (p) => p.productUid === line.product
                )!!.available,
                unit
              )
            : "/ 0") + " available"}
        </span>
      </div>
      <LinkButton
        style={{ color: danger }}
        label="X"
        onClick={() => remove()}
      />
    </div>
  );
};

const generateDefaultPricesMap = (customer?: Customer) => {
  return customer
    ? new Map<string, number>(
        customer.productDefaultPrices.map((p) => {
          return [p.productUid, p.priceCentesimal!!];
        })
      )
    : new Map<string, number>();
};

export default Orders;
