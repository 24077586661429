import { endOfDay } from "date-fns";
import format from "date-fns/format";
import { useEffect, useState } from "react";
import Button from "../../components/button";
import LockableTitledInput from "../../components/lockableTitledInput";
import Modal from "../../components/modal";
import TitledText from "../../components/titledText";
import { CropConfiguration } from "../../store/cropConfigurationStore";
import deleteIcon from "../../svg/delete.svg";
import saveIcon from "../../svg/save.svg";
import speechBubble from "../../svg/speechbubble.svg";
import filledSpeechBubble from "../../svg/speechbubble_filled.svg";
import smallPlantSpeechBubble from "../../svg/speechbubble_filled_small.svg";
import { darkGray, harvest, lightGray } from "../../util/colors";
import TaskHeader from "./taskHeader";

const HarvestTasks: React.FC<{
  cropConfigurations: Map<string, CropConfiguration>;
  farmUid?: string;
  activeDay: Date;
  setHasLoaded: () => void;
}> = ({ cropConfigurations, farmUid, activeDay, setHasLoaded }) => {
  const [benches, setBenches] = useState<Array<HarvestTaskBench>>([]);
  useEffect(() => {
    fetchTasks(activeDay, farmUid).then((t) => {
      setBenches(t);
      setHasLoaded();
    });
  }, [activeDay, farmUid, setHasLoaded]);

  const saveBench = async (bench: HarvestTaskBench, index: number) => {
    if (bench.currentFloatsAmount > bench.plannedFloatsAmount) {
      alert("Can't harvest more floats than we have.");
      return;
    }
    if (bench.floatsWeighed === 0) {
      bench.floatsWeighed = undefined;
    }
    const body = {
      task: {
        floats: bench.floats.slice(0, bench.currentFloatsAmount),
        benchUid: bench.benchUid,
        floatsWeighed: bench.floatsWeighed,
        weight: bench.weight,
        constantWeighingFactor: bench.weighingConstantFactor,
        comment: bench.comment,
      },
      plannedTaskDate: activeDay
        .toISOString()
        .slice(0, activeDay.toISOString().indexOf("T")),
    };
    const newTaskUid = await fetch(`/api/tasks/harvest?farmUid=${farmUid}`, {
      method: "post",
      body: JSON.stringify(body),
    })
      .then((r) => r.json())
      .then((r) => r.taskUid as string);
    let newBenches = [...benches];
    newBenches[index] = {
      ...bench,
      taskUid: newTaskUid,
      completedFloatsAmount: bench.currentFloatsAmount,
    };
    setBenches(newBenches);
  };

  const cancelbench = async (bench: HarvestTaskBench, index: number) => {
    const newTaskUid = await fetch(`/api/tasks/harvest?farmUid=${farmUid}`, {
      method: "delete",
      body: JSON.stringify({ taskUid: bench.taskUid }),
    }).then(() => undefined);
    let newBenches = [...benches];
    newBenches[index] = {
      ...bench,
      taskUid: newTaskUid,
      completedFloatsAmount: 0,
    };
    setBenches(newBenches);
  };
  return benches.length === 0 ? (
    <></>
  ) : (
    <>
      <TaskHeader title="Harvesting" color={harvest} />
      <div style={{ display: "flex", flexDirection: "column" }}></div>
      {benches.map((bench, i) => {
        return (
          <HarvestTaskRow
            key={bench.benchUid}
            bench={bench}
            borderTop={i !== 0}
            cropConfigurations={bench.cropConfigurationUids.map(
              (puid) => cropConfigurations.get(puid)!!
            )}
            setNewValue={(b) => {
              let newBenches = [...benches];
              newBenches[i] = b;
              setBenches(newBenches);
            }}
            saveBench={() => {
              bench.taskUid ? cancelbench(bench, i) : saveBench(bench, i);
            }}
          />
        );
      })}
    </>
  );
};

const HarvestTaskRow: React.FC<{
  bench: HarvestTaskBench;
  setNewValue: (bench: HarvestTaskBench) => void;
  borderTop: boolean;
  cropConfigurations?: CropConfiguration[];
  saveBench: () => void;
}> = ({ bench, setNewValue, borderTop, cropConfigurations, saveBench }) => {
  const accordingToPlanColor = "#92E690";
  const notToPlanColor = "#FBF5BD";
  const differsFromPlan =
    bench.plannedFloatsAmount !== bench.currentFloatsAmount;
  const plannedValue = differsFromPlan ? `(${bench.plannedFloatsAmount})` : "";
  const locked = bench.taskUid !== undefined;

  const [commentModalOpen, setCommentModalOpen] = useState(false);

  const setFloatsValue = (floats: string) => {
    const newValue = parseInt(floats);
    setNewValue({ ...bench, currentFloatsAmount: newValue });
  };
  const setWeightValue = (weight: string) => {
    let newValue = parseInt(weight);
    if (newValue !== undefined) {
      newValue = Math.abs(newValue);
    }
    setNewValue({ ...bench, weight: newValue });
  };
  const setFloatsWeighedValue = (floats: string) => {
    let newValue = parseInt(floats);
    if (newValue !== undefined) {
      newValue = Math.abs(newValue);
    }
    setNewValue({ ...bench, floatsWeighed: newValue });
  };
  const setConstantWeightFactorValue = (factor: string) => {
    let newValue = parseInt(factor);
    if (newValue !== undefined) {
      newValue = Math.abs(newValue);
    }
    setNewValue({ ...bench, weighingConstantFactor: newValue });
  };

  const setNewCommentValue = (comment: string | undefined) => {
    setNewValue({
      ...bench,
      comment: comment && comment.length < 1 ? undefined : comment,
    });
  };

  const [comment, setComment] = useState<string | undefined>(bench.comment);
  const showCommentBubble =
    bench.weight || bench.comment || bench.floatsWeighed ? true : false;

  const floatAvgWeight =
    bench.weight && bench.floatsWeighed
      ? "" +
        Math.round(
          (bench.weight - bench.weighingConstantFactor) / bench.floatsWeighed
        )
      : "";

  const benchTitle = cropConfigurations?.map((p) => p.title).join(" & ");
  const formattedTransplantedDate = format(
    new Date(bench.transplantedAt),
    "yyyy/MM/dd"
  );
  return (
    <div
      style={{
        borderTop: !borderTop ? "none" : `1px solid ${lightGray}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "9px",
        backgroundColor: locked
          ? differsFromPlan
            ? notToPlanColor
            : accordingToPlanColor
          : "",
      }}
    >
      <span>{benchTitle}</span>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <CommentBubble
          visible={showCommentBubble}
          hasComment={bench.comment ? true : false}
          isLocked={locked}
          onClick={() => setCommentModalOpen(true)}
        />
        <TitledText
          text={formattedTransplantedDate}
          title="TRANSPLANTED"
          style={{
            marginLeft: "15px",
          }}
        />
        <LockableTitledInput
          value={
            bench.currentFloatsAmount === 0
              ? undefined
              : bench.currentFloatsAmount
          }
          setValue={setFloatsValue}
          locked={locked}
          title={plannedValue + "FLOATS"}
          inputStyle={{ width: "150px" }}
        />
        <LockableTitledInput
          title="WEIGHT (G)"
          value={bench.weight}
          locked={locked}
          editingEnabledByDefault={true}
          setValue={setWeightValue}
          style={{ marginLeft: "15px" }}
          inputStyle={{ width: "150px" }}
        />
        <LockableTitledInput
          title="FLOATS WEIGHED"
          value={bench.floatsWeighed}
          locked={locked}
          editingEnabledByDefault={true}
          setValue={setFloatsWeighedValue}
          style={{ marginLeft: "15px" }}
          inputStyle={{ width: "150px" }}
        />
        <LockableTitledInput
          value={bench.weighingConstantFactor}
          setValue={setConstantWeightFactorValue}
          locked={locked}
          title={"BOX (G)"}
          style={{ marginLeft: "15px" }}
          inputStyle={{ width: "150px" }}
        />
        <TitledText
          text={floatAvgWeight}
          title="AVG. (g)"
          style={{
            marginLeft: "15px",
          }}
        />
        <img
          onClick={saveBench}
          src={locked ? deleteIcon : saveIcon}
          style={{
            marginLeft: "15px",
            width: "20px",
            height: "20px",
            cursor: "pointer",
            alignSelf: "center",
          }}
          alt={locked ? "undo" : "save"}
        />
      </div>
      <Modal
        closeModal={() => {
          setComment(bench.comment);
          setCommentModalOpen(false);
        }}
        showModal={commentModalOpen}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>{`Comment`}</span>
          <span
            style={{ color: darkGray }}
          >{`${benchTitle} transplanted on ${formattedTransplantedDate}`}</span>
          <textarea
            rows={5}
            value={comment}
            onChange={(v) => setComment(v.currentTarget.value)}
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "17px",
              marginTop: "16px",
            }}
            disabled={locked}
          ></textarea>
          <Button
            label={locked ? "Close" : "Set comment"}
            style={{ marginTop: "16px" }}
            onClick={() => {
              if (!locked) {
                setNewCommentValue(comment);
              }
              setCommentModalOpen(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

const CommentBubble: React.FC<{
  hasComment: boolean;
  isLocked: boolean;
  onClick: () => void;
  visible: boolean;
}> = ({ hasComment, isLocked, onClick, visible }) => {
  const allowOpeningCommentModal = !isLocked || hasComment;
  return (
    <img
      onClick={() => {
        if (allowOpeningCommentModal) onClick();
      }}
      src={
        hasComment
          ? isLocked
            ? filledSpeechBubble
            : smallPlantSpeechBubble
          : speechBubble
      }
      style={{
        visibility: visible ? "inherit" : "hidden",
        cursor: allowOpeningCommentModal ? "pointer" : "default",
        width: "40px",
        height: "40px",
        alignSelf: "center",
      }}
      alt={"comment"}
    />
  );
};

const fetchTasks = async (activeDay: Date, farmUid?: string) => {
  if (!farmUid) {
    return [];
  }
  const [tasks, completedTasks] = await Promise.all([
    fetch(
      `/api/tasks/harvest?farmUid=${farmUid}&from=${endOfDay(
        activeDay
      ).getTime()} `
    )
      .then((r) => r.json())
      .then((r) => r.tasks as HarvestTask[]),
    fetch(
      `/api/tasks/harvest/completed?farmUid=${farmUid}&from=${endOfDay(
        activeDay
      ).getTime()} `
    )
      .then((r) => r.json())
      .then((r) => r as CompletedTasksResponse),
  ]);

  const todo = tasks.reduce((a, t) => {
    return a.set(t.benchUid, t);
  }, new Map<string, HarvestTask>());

  const completed = completedTasks.tasks.reduce((a, t) => {
    return a.set(t.benchUid, t);
  }, new Map<string, CompletedHarvestTask>());

  const combinedTaskBenches: HarvestTaskBench[] = Array.from(todo).map(
    ([benchUid, harvestTask]) => {
      const doneTask = completed.get(benchUid);
      const cropConfigurations = Array.from(
        new Set(harvestTask.floats.map((t) => t.cropConfigurationUid))
      );
      return {
        cropConfigurationUids: cropConfigurations,
        taskUid: doneTask?.uid,
        benchUid: benchUid,
        completedFloatsAmount: doneTask?.floats.length,
        plannedFloatsAmount:
          harvestTask.floats.length + (doneTask?.floats.length ?? 0),
        currentFloatsAmount:
          doneTask?.floats.length ?? harvestTask.floats.length,
        weight: doneTask?.weight,
        floatsWeighed: doneTask?.floatsWeighed,
        floats: harvestTask.floats.concat(doneTask?.floats ?? []),
        weighingConstantFactor: doneTask?.weighingConstantFactor ?? 1326, // hard code this value?? probably get it from farm later
        transplantedAt: harvestTask.transplantedAt,
        comment: doneTask?.comment,
      };
    }
  );

  const totallyCompleted = new Map(
    Array.from(completed).filter(([benchUid]) =>
      Array.from(todo).every(([todoBenchUid]) => todoBenchUid !== benchUid)
    )
  );

  const totallyCompletedBenches = Array.from(totallyCompleted).map(
    ([benchUid, harvestTask]) => {
      const cropConfigurations = Array.from(
        new Set(harvestTask.floats.map((t) => t.cropConfigurationUid))
      );

      return {
        cropConfigurationUids: cropConfigurations,
        taskUid: harvestTask.uid,
        benchUid: benchUid,
        completedFloatsAmount: harvestTask.floats.length,
        plannedFloatsAmount: harvestTask.floats.length,
        currentFloatsAmount: harvestTask.floats.length,
        weight: harvestTask?.weight,
        floatsWeighed: harvestTask?.floatsWeighed,
        floats: harvestTask.floats,
        weighingConstantFactor: harvestTask?.weighingConstantFactor ?? 1326, // hard code this value?? probably get it from farm later
        transplantedAt: harvestTask.transplantedAt,
        comment: harvestTask.comment,
      };
    }
  );
  return combinedTaskBenches
    .concat(totallyCompletedBenches)
    .sort((a, b) =>
      a.cropConfigurationUids[0].localeCompare(b.cropConfigurationUids[0])
    );
};

type HarvestTaskBench = {
  cropConfigurationUids: string[];
  benchUid: string;
  taskUid?: string;
  floats: HarvestTaskFloat[];
  plannedFloatsAmount: number;
  completedFloatsAmount?: number;
  currentFloatsAmount: number;
  weight?: number;
  floatsWeighed?: number;
  weighingConstantFactor: number;
  transplantedAt: number;
  comment?: string;
};

type CompletedHarvestTask = {
  uid: string;
  floats: HarvestTaskFloat[];
  benchUid: string;
  weight?: number;
  floatsWeighed?: number;
  weighingConstantFactor?: number;
  transplantedAt: number;
  comment?: string;
};

type CompletedTasksResponse = {
  tasks: CompletedHarvestTask[];
};

type HarvestTaskFloat = {
  uid: string;
  cropConfigurationUid: string;
};

type HarvestTask = {
  benchUid: string;
  floats: HarvestTaskFloat[];
  transplantedAt: number;
};

export default HarvestTasks;
