const toWeekdayString: (day: DayOfWeek) => string = (day) => {
  return [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ][day - 1];
};

export { toWeekdayString };

export type DayOfWeek = 1 | 2 | 3 | 4 | 5 | 6 | 7 | -1;
