import create from "zustand";
import { persist } from "zustand/middleware";

export type CropConfiguration = {
  title: string;
  uid: string;
  floatDensity: number;
  germinationTime: number;
  germinationRate: number;
  propagationTime: number;
  maturationTime: number;
  floatYieldGrams: number;
  bagWeight: number;
  active: boolean;
  cropUid: string;
  harvestTypeUid: string;
  crop: Crop;
};

export type Crop = {
  uid: string;
  name: string;
};

export type ApiCrop = Crop & {
  deletedAt: number;
  configurationUids: string[];
};

type ApiHarvestType = {
  uid: string;
  name: string;
  cropUid: string;
  deletedAt?: number;
};

export type HarvestType = ApiHarvestType & {
  crop: Crop;
};

type CropConfigurationState = {
  all: CropConfiguration[];
  fetch: (farm: string) => void;
  active: CropConfiguration[];
  crops: ApiCrop[];
  harvestTypes: HarvestType[];
};

const CropConfigurationStore = create<CropConfigurationState>(
  persist(
    (set, get) => ({
      fetch: async (farm: string) => {
        const [cropConfigs, crops, harvestTypes] = await Promise.all<
          CropConfiguration[],
          ApiCrop[],
          ApiHarvestType[]
        >([
          fetch(`/api/crop/configuration/list?farmUid=${farm}`)
            .then((r) => r.json())
            .then((r) => r.cropConfigurations),
          fetch(`/api/crop/crop/list?farmUid=${farm}`)
            .then((r) => r.json())
            .then((r) => r.crops),
          fetch(`/api/product/harvesttype/list?farmUid=${farm}`)
            .then((r) => r.json())
            .then((r) => r.harvestTypes),
        ]);

        const cropUidsToCrop = crops.reduce(
          (acc, c) => acc.set(c.uid, { name: c.name, uid: c.uid }),
          new Map<string, Crop>()
        );
        const configsWithCrops = cropConfigs.map((c) => {
          return {
            ...c,
            crop: cropUidsToCrop.get(c.cropUid) ?? { uid: "", name: "unknown" },
          };
        });
        const harvestTypesWithCrops = harvestTypes.map((c) => {
          return {
            ...c,
            crop: cropUidsToCrop.get(c.cropUid) ?? {
              uid: "",
              name: "unknown",
            },
          };
        });
        set({
          crops: crops,
          all: configsWithCrops,
          active: configsWithCrops.filter((p) => p.active),
          harvestTypes: harvestTypesWithCrops,
        });
      },
      active: [],
      all: [],
      crops: [],
      harvestTypes: [],
    }),
    { name: "cropConfigurationStore" }
  )
);

export default CropConfigurationStore;
