import DatePicker from "react-datepicker";
import React, { ReactElement, useState } from "react";
import line from "../svg/line.svg";
import "react-datepicker/dist/react-datepicker.css";
import "./fromToDatePicker.css";
import { addDays } from "../util/dates";
import { darkGray } from "../util/colors";

const FromToDatePicker: React.FC<{
  startDate: Date;
  setStartDate: (startDate: Date) => void;
  endDate: Date;
  setEndDate: (startDate: Date) => void;
  daysBetween?: number;
  startTitle?: string;
  endTitle?: string;
  middleChildren?: ReactElement;
}> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  daysBetween,
  startTitle,
  endTitle,
  middleChildren,
}) => {
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);

  const enterStartDate = (e: Date) => {
    setIsStartDateOpen(!isStartDateOpen);
    setStartDate(e);
    if (daysBetween !== undefined) {
      setEndDate(addDays(e, daysBetween));
    }
  };

  const enterEndDate = (e: Date) => {
    setIsEndDateOpen(!isEndDateOpen);
    setEndDate(e);
    if (daysBetween !== undefined) {
      setStartDate(addDays(e, -daysBetween));
    }
  };
  const titleStyle = {
    color: darkGray,
    fontSize: "15px",
    fontWeight: 600,
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={titleStyle}>{startTitle ?? "FROM"}</span>
        <DatePicker
          selected={startDate}
          onChange={enterStartDate}
          popperPlacement="bottom-end"
          showPopperArrow={true}
          className="uo-date-picker"
          dateFormat="eee d MMM"
        />
      </div>
      {middleChildren ? middleChildren : <img src={line} alt="line" />}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={titleStyle}>{endTitle ?? "TO"}</span>
        <DatePicker
          selected={endDate}
          onChange={enterEndDate}
          popperPlacement="bottom-end"
          showPopperArrow={true}
          className="uo-date-picker"
          dateFormat="eee d MMM"
        />
      </div>
    </div>
  );
};

export default FromToDatePicker;
