import { Link } from "@reach/router";
import CSS from "csstype";
import React, { useState } from "react";
import UserStore from "../store/userStore";
import arrowDown from "../svg/arrowDown.svg";
import logo from "../svg/logo.svg";
import { white } from "../util/colors";

type HeaderProps = {
  userFullName: string;
};

const NavLink = (props: any) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          color: isCurrent ? "black" : "#A7A7A7",
          marginLeft: "30px",
          textDecoration: "none",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 17,
        },
      };
    }}
  />
);

const Header: React.FC<HeaderProps> = ({ userFullName }) => {
  const activeFarm = UserStore((state) => state.activeFarm);
  const setActiveFarm = UserStore((state) => state.setActiveFarm);
  const user = UserStore((state) => state.user);
  const farms = UserStore((state) => state.farms);
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const hideMenu = () => {
    setMenuOpen(false);
  };

  const menuItemStyle: CSS.Properties = {
    background: "#f8f8f8",
    padding: "5px",
    backgroundColor: white,
    cursor: "pointer",
    fontSize: "15px",
    userSelect: "none",
    borderTop: "1px solid #A7A7A7",
  };
  return (
    <header
      style={{
        width: "100%",
        margin: "30px auto 60px auto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 17,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span>
          <img
            src={logo}
            className="logo"
            alt="logo"
            style={{ verticalAlign: "middle" }}
          />
          <span style={{ marginLeft: "37px" }}>
            {activeFarm ? activeFarm.title : "Loading.."}
          </span>
          <span
            tabIndex={0}
            style={{
              position: "relative",
            }}
            onBlur={hideMenu}
          >
            <img
              onClick={toggleMenu}
              src={arrowDown}
              style={{
                marginLeft: "11px",
                cursor: "pointer",
              }}
              alt="down-arrow"
              onBlur={hideMenu}
            />
            {menuOpen && (
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "10px",
                  width: "max-content",
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #A7A7A7",
                }}
              >
                {farms.map((farm) => {
                  return (
                    <span
                      style={menuItemStyle}
                      key={farm.uid}
                      onClick={(e) => setActiveFarm(farm)}
                    >
                      {farm.title}
                    </span>
                  );
                })}
              </div>
            )}
          </span>
        </span>
        <nav style={{ marginLeft: "auto" }}>
          <NavLink to="/">Home</NavLink>
          <NavLink to="production">Production</NavLink>
          <NavLink to="operation">Operation</NavLink>
          <NavLink to="sales">Sales</NavLink>
          <NavLink to="economy">Economy</NavLink>
        </nav>
        <Link to="user" style={{ textDecoration: "none" }}>
          <div
            style={{
              height: "42px",
              width: "42px",
              borderRadius: "42px",
              marginLeft: "30px",
              marginRight: "16px",
              background: white,
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 15,
                position: "relative",
                left: 0,
                top: 6,
                userSelect: "none",
                color: "black",
              }}
            >
              {user?.symbol ?? "😁"}
            </span>
          </div>
        </Link>
      </div>
    </header>
  );
};

export default Header;
