import { useEffect, useState } from "react";
import Switcher from "../../components/switcher";
import MultipleTimeSeriesChart from "../../components/timeseries/multipleTimeseriesChart";
import { TimeInterval } from "../../components/timeseries/types";
import { ApiCrop, CropConfiguration } from "../../store/cropConfigurationStore";
import { dayInMillis } from "../../util/dates";

type YieldDataPoint = {
  grams: number;
  timestamp: number;
  comment?: string;
};
enum YieldUnit {
  Kg_Per_m2_per_year,
  Grams,
}

const YieldGraph: React.FC<{
  cropConfigurations: CropConfiguration[];
  crops: ApiCrop[];
  farmUid?: string;
}> = ({ cropConfigurations, crops, farmUid }) => {
  const timeIntervalValues = [
    { label: "7 Days", value: TimeInterval.One_Week },
    { label: "1 Month", value: TimeInterval.One_Month },
    { label: "1 Year", value: TimeInterval.One_Year },
  ];

  const unitValues = [
    { label: "kg/m2/year", value: YieldUnit.Kg_Per_m2_per_year },
    { label: "grams", value: YieldUnit.Grams },
  ];

  const [timeInterval, setTimeInterval] = useState(TimeInterval.One_Week);
  const [yieldData, setYieldData] = useState<Map<string, YieldDataPoint[]>>(
    new Map()
  );

  const [unit, setUnit] = useState(YieldUnit.Kg_Per_m2_per_year);
  const [mergeCrops, setMergeCrops] = useState(true);

  useEffect(() => {
    const getData = async () => {
      if (farmUid) {
        const response = await getYieldDataForInterval(
          farmUid,
          cropConfigurations.map((c) => c.uid),
          timeInterval
        );
        if (response) {
          setYieldData(new Map(Object.entries(response)));
        }
      }
    };
    getData();
  }, [setYieldData, timeInterval, farmUid, cropConfigurations]);

  const dataByCropConfiguration: Map<string, { v: number; date: number }[]> =
    Array.from(yieldData).reduce((acc, [uuid, points]) => {
      const cropConfiguration = cropConfigurations.find(
        (c) => c.uid === uuid
      )!!;
      const daysInMaturation = cropConfiguration.maturationTime / dayInMillis;
      return acc.set(
        cropConfiguration.title,
        points.map((p) => {
          return {
            v:
              unit === YieldUnit.Grams
                ? p.grams
                : (p.grams / 1000 / daysInMaturation / 0.48) * 365,
            date: p.timestamp,
            label: p.comment,
          };
        })
      );
    }, new Map<string, { v: number; date: number }[]>());

  const dataByCropUid: Map<string, { v: number; date: number }[]> = Array.from(
    yieldData
  ).reduce((acc, [uuid, points]) => {
    const cropConfiguration = cropConfigurations.find((c) => c.uid === uuid)!!;
    const cropUid = cropConfiguration.cropUid;
    const oldValue = acc.get(cropUid) ?? [];
    const daysInMaturation = cropConfiguration.maturationTime / dayInMillis;
    return acc.set(cropUid, [
      ...oldValue,
      ...points.map((p) => {
        return {
          v:
            unit === YieldUnit.Grams
              ? p.grams
              : (p.grams / 1000 / daysInMaturation / 0.48) * 365,
          date: p.timestamp,
          label: p.comment,
        };
      }),
    ]);
  }, new Map<string, { v: number; date: number }[]>());

  const dataByCrop = Array.from(dataByCropUid).reduce(
    (acc, [cropUid, points]) => {
      return acc.set(
        crops.find((c) => c.uid === cropUid)!!.name,
        points.sort((a, b) => a.date - b.date)
      );
    },
    new Map<string, { v: number; date: number; label?: string }[]>()
  );

  return (
    <>
      <Switcher
        wrapperStyle={{ textAlign: "end" }}
        elements={timeIntervalValues}
        selectedValue={timeInterval}
        onChange={(value) => {
          setTimeInterval(value);
        }}
      />
      <Switcher
        wrapperStyle={{ textAlign: "end" }}
        elements={[
          { value: true, label: "Crops" },
          { value: false, label: "Configurations" },
        ]}
        selectedValue={mergeCrops}
        onChange={(value) => {
          setMergeCrops(value);
        }}
      />
      <Switcher
        wrapperStyle={{ textAlign: "end" }}
        elements={unitValues}
        selectedValue={unit}
        onChange={(value) => {
          setUnit(value);
        }}
      />
      <div style={{ height: "200px", display: "flex", flexDirection: "row" }}>
        <MultipleTimeSeriesChart
          id="yieldHa"
          valueLabel=""
          timeSeries={mergeCrops ? dataByCrop : dataByCropConfiguration}
          mergedDayTooltip={true}
        />
      </div>
    </>
  );
};

const getYieldDataForInterval = async (
  farmUid: string,
  cropConfigurationUids: string[],
  interval: TimeInterval
) => {
  const from = Date.now() - interval;
  return fetch(
    `/api/yield?from=${from}&cropConfigurations=${cropConfigurationUids.join(
      ","
    )}&farmUid=${farmUid}`
  )
    .then((r) => r.json())
    .then((r) => r.yields);
};

export default YieldGraph;
