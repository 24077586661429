import { lightGray } from "../util/colors";

const TextInput: React.FC<{
  value: string;
  setValue: (v: string) => void;
  placeholder?: string;
}> = ({ value, setValue, placeholder }) => {
  return (
    <input
      value={value}
      type="text"
      onChange={(e) => setValue(e.currentTarget.value)}
      style={{
        backgroundColor: lightGray,
        textAlign: "center",
        fontSize: "19px",
        fontWeight: 600,
        height: "40px",
        border: "none",
      }}
      placeholder={placeholder}
    ></input>
  );
};

export default TextInput;
