import CSS from "csstype";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Accordion from "../../../components/accordion";
import LinkButton from "../../../components/linkButton";
import TitledText from "../../../components/titledText";
import CropConfigurationStore, {
  CropConfiguration,
} from "../../../store/cropConfigurationStore";
import {
  backgroundGray,
  danger,
  darkGray,
  harvest,
  lightGray,
  white,
} from "../../../util/colors";

type FarmStateFloat = {
  uid: string;
  cropConfigurationUid: string;
  transplantDate: number;
  discarded: boolean;
};

type FarmStateBench = {
  uid: string;
  transplantDate: number;
  floats: FarmStateFloat[];
};

const FarmFloats: React.FC<{
  farmUid?: string;
}> = ({ farmUid }) => {
  const cropConfigurations = CropConfigurationStore((state) =>
    state.all.reduce(
      (acc, p) => acc.set(p.uid, p),
      new Map<string, CropConfiguration>()
    )
  );
  const [benches, setBenches] = useState<Array<FarmStateBench>>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!farmUid) {
      return;
    }
    fetch(`api/farmstate/benches?farmUid=${farmUid}&from=${Date.now()}`)
      .then((r) => r.json())
      .then((r) => {
        setBenches(r.benches);
        setLoading(false);
      });
  }, [farmUid]);

  const deleteFloat = async (uid: string, restore: boolean) => {
    const response = await fetch(
      `/api/farmstate/floats/${uid}?farmUid=${farmUid}`,
      {
        method: restore ? "post" : "delete",
      }
    );
    if (response.ok) {
      setBenches(
        benches.map((b) => {
          return {
            ...b,
            floats: b.floats.map((f) =>
              f.uid !== uid ? f : { ...f, discarded: !restore }
            ),
          };
        })
      );
    }
  };

  const rowStyle: CSS.Properties = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr auto",
    justifyContent: "space-between",
    textAlign: "start",
    borderTop: "1px solid #EEEEEE",
    backgroundColor: backgroundGray,
    padding: "16px",
    fontSize: "15px",
    fontWeight: 500,
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px",
        }}
      >
        <span>Benches</span>
      </div>
      {loading && (
        <div
          key=""
          style={{
            borderTop: `1px solid ${lightGray}`,
            padding: "16px",
            fontSize: "15px",
            fontWeight: 500,
          }}
        >
          Loading...
        </div>
      )}
      <Accordion
        elements={benches.map(({ uid, transplantDate, floats }) => {
          const formattedDate = format(new Date(transplantDate), "eee d MMM");
          return {
            key: uid,
            titleNode: (
              <span>{`${
                cropConfigurations.get(floats[0].cropConfigurationUid)?.title
              } - Transplanted on ${formattedDate}`}</span>
            ),
            node: (
              <>
                {floats.map((float) => (
                  <FloatRow
                    key={float.uid}
                    float={float}
                    cropConfiguration={
                      cropConfigurations.get(float.cropConfigurationUid)!!
                    }
                    style={rowStyle}
                    deleteFloat={deleteFloat}
                  />
                ))}
              </>
            ),
          };
        })}
      />
      <div style={{ ...rowStyle, backgroundColor: "transparent" }}>
        <span>{`Total: ${benches.length} benches`}</span>
      </div>
    </div>
  );
};

let FloatRow: React.FC<{
  float: FarmStateFloat;
  cropConfiguration: CropConfiguration;
  style: CSS.Properties;
  deleteFloat: (uid: string, restore: boolean) => void;
}> = ({ float, cropConfiguration, style, deleteFloat }) => {
  const harvestDate = float.transplantDate + cropConfiguration.maturationTime;

  return (
    <>
      <div
        style={{
          ...style,
          backgroundColor: float.discarded ? darkGray : style.backgroundColor,
        }}
      >
        <TitledText
          title="TRANSPLANTED"
          text={format(new Date(float.transplantDate), "eee d MMM")}
          titleStyle={float.discarded ? { color: white } : {}}
        />
        <span
          style={{ textAlign: "center", color: harvest, alignSelf: "center" }}
        >
          {"————>"}
        </span>
        {
          <TitledText
            title="HARVEST"
            text={format(new Date(harvestDate), "eee d MMM")}
            titleStyle={float.discarded ? { color: white } : {}}
          />
        }
        <LinkButton
          label={float.discarded ? "?" : "x"}
          style={{
            color: float.discarded ? white : danger,
            alignSelf: "center",
          }}
          onClick={() => deleteFloat(float.uid, float.discarded)}
        />
      </div>
    </>
  );
};
export default FarmFloats;
