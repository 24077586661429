import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Button from "../../../components/button";
import InputLabel from "../../../components/inputLabel";
import Modal from "../../../components/modal";
import ModalHeader from "../../../components/modalHeader";
import { selectorStyles } from "../../../components/multiselect";
import IntegerInput from "../../../components/numberInput";
import Switcher from "../../../components/switcher";
import TextInput from "../../../components/textInput";
import {
  Crop,
  CropConfiguration,
  HarvestType,
} from "../../../store/cropConfigurationStore";
import { dayInMillis, roundDays } from "../../../util/dates";
import cropConfigurationSubtitle from "./util";

const CropConfigurationModal: React.FC<{
  showModal: boolean;
  closeModal: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onFinish: () => void;
  farmUid?: string;
  cropConfiguration?: CropConfiguration;
  crop: Crop;
  harvestTypes: HarvestType[];
}> = ({
  showModal,
  closeModal,
  onFinish,
  farmUid,
  cropConfiguration,
  crop,
  harvestTypes,
}) => {
  const [title, setTitle] = useState(cropConfiguration?.title ?? "");
  const [floatDensity, setFloatDensity] = useState(
    cropConfiguration?.floatDensity ?? 70
  );
  const [floatYieldGrams, setfloatYieldGrams] = useState(
    cropConfiguration?.floatYieldGrams ?? 1400
  );
  const [bagWeight, setbagWeight] = useState(
    cropConfiguration?.bagWeight ?? 70
  );
  const [germinationRate, setGerminationRate] = useState(
    cropConfiguration ? cropConfiguration.germinationRate * 100 : 98
  );

  const [germinationTimeDays, setGerminationTime] = useState(
    roundDays(cropConfiguration?.germinationTime, 2)
  );
  const [propagationTimeDays, setPropagationTime] = useState(
    roundDays(cropConfiguration?.propagationTime, 7)
  );
  const [maturationTimeDays, setMaturationTime] = useState(
    roundDays(cropConfiguration?.maturationTime, 21)
  );

  const [harvestTypeUid, setHarvestTypeUid] = useState(
    cropConfiguration?.harvestTypeUid ?? harvestTypes[0]
  );

  const validate: () => [RequestBody, string[]] = () => {
    const errors: string[] = [];
    if (germinationRate <= 0.0 || germinationRate > 100) {
      errors.push("Germination rate must be between 0.01 and 100 ");
    }
    if (harvestTypes.length < 1) {
      errors.push("Must have at least one harvest type for this crop");
    }
    const body = {
      title: title,
      floatDensity: floatDensity,
      floatYieldGrams: floatYieldGrams,
      bagWeight: bagWeight,
      germinationRate: germinationRate / 100,
      germinationTime: germinationTimeDays * dayInMillis,
      propagationTime: propagationTimeDays * dayInMillis,
      maturationTime: maturationTimeDays * dayInMillis,
      uid: cropConfiguration?.uid,
      cropUid: crop.uid,
      harvestTypeUid: harvestTypes[0].uid,
    };
    return [body, errors];
  };

  const createOrUpdate = (body: RequestBody) => {
    fetch(`/api/crop/configuration?farmUid=${farmUid}`, {
      method: "POST",
      body: JSON.stringify(body),
    }).then((_) => {
      onFinish();
    });
  };

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          userSelect: "none",
        }}
      >
        <ModalHeader
          title={
            cropConfiguration
              ? cropConfiguration.title
              : `New ${crop.name} configuration`
          }
        />
        {cropConfiguration && (
          <span>
            {crop.name + " " + cropConfigurationSubtitle(cropConfiguration)}
          </span>
        )}
        <InputLabel title="Title" />
        <TextInput
          value={title}
          setValue={setTitle}
          placeholder={`${crop.name}`}
        />
        <TitledNumberInput
          title="Float yield (g)"
          amount={floatYieldGrams}
          setAmount={setfloatYieldGrams}
        />
        <TitledNumberInput
          title="Bag weight"
          amount={bagWeight}
          setAmount={setbagWeight}
        />
        <TitledNumberInput
          title="Germination rate (%)"
          amount={germinationRate}
          setAmount={setGerminationRate}
          float={true}
        />
        <TitledNumberInput
          title="Germination time (days)"
          amount={germinationTimeDays}
          setAmount={setGerminationTime}
        />
        <TitledNumberInput
          title="Propagation time (days)"
          amount={propagationTimeDays}
          setAmount={setPropagationTime}
        />
        <TitledNumberInput
          title="Maturation time (days)"
          amount={maturationTimeDays}
          setAmount={setMaturationTime}
        />
        <InputLabel title="Float type" />
        <Switcher
          elements={[
            { value: 96, label: "6x16" },
            { value: 70, label: "5x14" },
            { value: 192, label: "Tray (192 plugs)" },
          ]}
          selectedValue={floatDensity}
          onChange={(e) => setFloatDensity(e)}
          wrapperStyle={{ height: "40px" }}
          contentStyle={{ fontSize: "17px" }}
        />
        {harvestTypes.length > 1 && (
          <>
            <InputLabel title="Harvest type" />
            <HarvestTypeSelector
              setSelected={(o) => setHarvestTypeUid(o.value)}
              selected={harvestTypes
                .map((h) => {
                  return { label: h.name, value: h.uid };
                })
                .find((t) => t.value === harvestTypeUid)}
              options={harvestTypes.map((h) => {
                return { value: h.uid, label: h.name };
              })}
            />
          </>
        )}
        <Button
          style={{ marginTop: "40px" }}
          onClick={() => {
            const [body, validationErrors] = validate();
            if (validationErrors.length > 0) {
              alert(validationErrors.join("\n"));
            } else {
              createOrUpdate(body);
            }
          }}
          label={cropConfiguration ? "Update" : "Create"}
        ></Button>
      </div>
    </Modal>
  );
};

type HarvestTypeOption = {
  value: string;
  label: string;
};

const HarvestTypeSelector: React.FC<{
  setSelected: (h: HarvestTypeOption) => void;
  options: HarvestTypeOption[];
  selected?: HarvestTypeOption;
}> = ({ setSelected, options, selected }) => {
  const animatedComponents = makeAnimated();

  const onChange = (option: HarvestTypeOption) => {
    setSelected(option);
  };
  return (
    <Select
      isDisabled={false}
      styles={selectorStyles}
      options={options}
      components={animatedComponents}
      value={selected}
      onChange={(v) => onChange(v as HarvestTypeOption)}
      placeholder={"Choose harvest type..."}
    />
  );
};

type RequestBody = {
  uid?: string;
  title: string;
  floatDensity: number;
  floatYieldGrams: number;
  bagWeight: number;
  germinationRate: number;
  germinationTime: number;
  propagationTime: number;
  maturationTime: number;
  cropUid: string;
  harvestTypeUid: string;
};

const TitledNumberInput: React.FC<{
  title: string;
  amount: number;
  setAmount: (a: number) => void;
  float?: boolean;
  placeholder?: string;
}> = ({ title, amount, setAmount, placeholder, float }) => {
  return (
    <>
      <InputLabel title={title} />
      <IntegerInput
        amount={amount}
        setAmount={setAmount}
        placeholder={placeholder}
        float={float}
      />
    </>
  );
};

export default CropConfigurationModal;
