import React, { useState } from "react";
import BoxHeader from "../../components/boxHeader";
import Button from "../../components/button";
import CentesimalPriceInput from "../../components/centesimalPriceInput";
import InputLabel from "../../components/inputLabel";
import LinkButton from "../../components/linkButton";
import Modal from "../../components/modal";
import TextInput from "../../components/textInput";
import CustomerStore from "../../store/customerStore";
import ProductStore, { Product } from "../../store/productStore";
import UserStore from "../../store/userStore";
import { danger, darkGray, lightGray } from "../../util/colors";

export type DefaultPrice = {
  productUid: string;
  priceCentesimal?: number;
};
type Customer = {
  uid?: string;
  name: string;
  productDefaultPrices: DefaultPrice[];
  deletedAt?: number;
};

const Customers: React.FC<{}> = () => {
  const activeFarm = UserStore((state) => state.activeFarm);
  const [showModal, setShowModal] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const products = ProductStore((state) =>
    state.all.filter((p) => !p.deletedAt)
  );
  const customers = CustomerStore((state) => state.all);
  const fetchCustomers = CustomerStore((state) => state.fetch);

  const reloadList = async () => {
    if (activeFarm) {
      fetchCustomers(activeFarm?.uid);
    }
  };

  const createNew = () => {
    setModalCustomer(emptyCustomer());
    setShowModal(true);
  };

  const upsertCustomer = async (customer: Customer) => {
    if (customer.name.length < 1) {
      alert("We're gonna need a name");
      return false;
    }
    if (!activeFarm) {
      return false;
    }
    await fetch(`api/customer/customer?farmUid=${activeFarm.uid}`, {
      method: "POST",
      body: JSON.stringify(customer),
    });
    return true;
  };

  const deleteOrRestoreCustomer = async (
    customerUid: string,
    restore: boolean = false
  ) => {
    if (activeFarm) {
      return fetch(
        `/api/customer/customer/${customerUid}?farmUid=${activeFarm.uid}`,
        {
          method: restore ? "POST" : "DELETE",
        }
      ).then((r) => {
        if (r.ok) {
          return reloadList().then(() => r.ok);
        } else {
          return r.ok;
        }
      });
    } else {
      return false;
    }
  };

  const emptyCustomer = () => {
    return {
      name: "",
      productDefaultPrices: [],
    };
  };

  const [modalCustomer, setModalCustomer] = useState<Customer>(emptyCustomer());

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <BoxHeader
        title="Customers"
        linkTitle="New Customer"
        onClick={createNew}
      />
      {customers
        .filter((p) => showDeleted || !p.deletedAt)
        .map((customer) => {
          return (
            <div
              style={{
                fontSize: "15px",
                borderTop: "1px solid #EEEEEE",
                padding: "16px",
                textAlign: "start",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                background: customer.deletedAt ? lightGray : "transparent",
              }}
              key={customer.uid}
            >
              <span>{customer.name}</span>
              <span
                style={{ color: darkGray, cursor: "pointer" }}
                onClick={() => {
                  setModalCustomer(customer);
                  setShowModal(true);
                }}
              >
                Edit
              </span>
            </div>
          );
        })}

      <div
        style={{
          fontSize: "12px",
          borderTop: "1px solid #EEEEEE",
          padding: "1px",
          paddingLeft: "16px",
          textAlign: "start",
          background: lightGray,
          cursor: "pointer",
        }}
        onClick={() => setShowDeleted(!showDeleted)}
      >
        {showDeleted ? "Hide deleted" : "Show deleted"}
      </div>
      {showModal && (
        <CreateCustomerModal
          showModal={showModal}
          closeModal={(e) => {
            setShowModal(false);
            if (e) {
              e.stopPropagation();
            }
          }}
          onFinish={async (newCustomer) => {
            const success = await upsertCustomer(newCustomer);
            if (success) {
              reloadList();
              setShowModal(false);
              setModalCustomer(emptyCustomer());
            }
          }}
          customer={modalCustomer}
          deleteOrRestore={deleteOrRestoreCustomer}
          products={products}
        />
      )}
    </div>
  );
};

const CreateCustomerModal: React.FC<{
  showModal: boolean;
  closeModal: (e: React.MouseEvent | React.KeyboardEvent | undefined) => void;
  onFinish: (customer: Customer) => Promise<void>;
  customer: Customer;
  deleteOrRestore: (customerUid: string, restore: boolean) => Promise<boolean>;
  products: Product[];
}> = ({
  showModal,
  closeModal,
  onFinish,
  customer,
  deleteOrRestore,
  products,
}) => {
  const [value, setValue] = useState<Customer>(customer);
  const isNew = customer.uid === undefined;
  const isDeleted =
    customer.deletedAt !== undefined && customer.deletedAt !== null;

  const productName = (uid?: string) => {
    if (!uid) {
      return "";
    }
    return products.find((p) => p.uid === uid)?.name;
  };

  const priceSort = (a: DefaultPrice, b: DefaultPrice) =>
    productName(a.productUid)?.localeCompare(
      productName(b?.productUid) ?? ""
    ) ?? 0;
  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{isNew ? "New Customer" : `Edit ${customer.name}`}</span>
        <InputLabel title="Name" />
        <TextInput
          value={value.name}
          setValue={(s) => setValue({ ...value, name: s })}
        />
        <InputLabel title="Default product prices" />
        {products.map((product) => {
          const uid = product.uid!!;
          return (
            <PriceRow
              label={products.find((p) => p.uid === uid)?.name ?? "?"}
              key={uid}
              price={
                value.productDefaultPrices.find((p) => p.productUid === uid)
                  ?.priceCentesimal
              }
              setPrice={(newPrice) => {
                const existingIndex = value.productDefaultPrices.findIndex(
                  (p) => p.productUid === uid
                );
                const oldPrices = value.productDefaultPrices;
                if (existingIndex > -1) {
                  oldPrices.splice(existingIndex, 1);
                }
                const newItem =
                  newPrice === undefined || isNaN(newPrice)
                    ? oldPrices
                    : [
                        ...oldPrices,
                        { productUid: uid, priceCentesimal: newPrice },
                      ].sort(priceSort);
                setValue({
                  ...value,
                  productDefaultPrices: newItem,
                });
              }}
            />
          );
        })}

        <div style={{ marginTop: "16px" }}>
          {!isNew && (
            <LinkButton
              style={{
                color: customer.deletedAt ? "green" : danger,
                marginRight: "32px",
              }}
              label={customer.deletedAt ? "Restore" : "Delete"}
              onClick={async () => {
                const done = await deleteOrRestore(customer.uid!!, isDeleted);
                if (done) {
                  closeModal(undefined);
                }
              }}
            ></LinkButton>
          )}
          <Button
            label={isNew ? "Create" : "Update"}
            onClick={async (e) => {
              await onFinish(value);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

const PriceRow: React.FC<{
  label: string;
  price?: number;
  setPrice: (newPrice?: number) => void;
}> = ({ label, price, setPrice }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <InputLabel title={label} />
      <CentesimalPriceInput price={price} setPrice={setPrice} />
    </div>
  );
};

export default Customers;
